import React, {useMemo, useEffect} from 'react';
import {useSelector, useDispatch} from "react-redux";
import {t} from "i18next";

import CopyIcon                         from "../shared/CopyIcon";
import localStyles                      from './userQR.module.scss'
import {copy, viewAlert}                from "../../utils/misc";
import { setModalForm, setShowMenu }    from "../../storage/global";

const UserQRCode = () => {

    const dispatch = useDispatch();
    const profile = useSelector(state => state.global.profile);
    const qrCode = useSelector(state => state.global.qrCode);

    function copyInvite(event, link) {
        event.preventDefault();
        event.stopPropagation();
        copy(link);
    }

    function openInvite() {
        dispatch(setShowMenu(false))
        const link = `${window.location.origin}/auth/signup?code_inviting_client=${profile?.usercode}`;
        dispatch(setModalForm({
            title: '',
            form: <div className={'text-bold flex-column w-100 d-flex justify-content-center mt-5 mt-xl-0'}>
                <p className={'mx-auto text-center'}>{t('Your_referral_promo_code')}</p>
                <div onClick={ (event) => copyInvite(event, link)} className={`mx-auto w-100 userQRCodeSvgMobileModal`} dangerouslySetInnerHTML={{ __html: qrCode.qr_code }}/>
                <div className={'d-flex mx-auto text-center wmc'}>
                    <p className={'fs-12px cursor-pointer'} onClick={ (event) => copyInvite(event, link)}>{link}</p>
                    <CopyIcon value={link} styles={{height: '20px'}} classNames={'ml-1 cursor-pointer'}/>
                </div>
            </div>,
            formContent: {classNames: 'modal-form-content-profile'},
            formBody: {classNames: 'd-flex mb-30'},
            styles: {background: 'rgba(0, 0, 0, 0.9)'},
        }))
    }

    return (
        <div className={localStyles.container}
             onClick={openInvite}
        >
            <div className={'w-100 h-100'} dangerouslySetInnerHTML={{ __html: qrCode.qr_code }}/>
        </div>
    );
};

export default UserQRCode;
