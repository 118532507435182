import React   from 'react';

import Image   from "../../components/shared/Image";

const App = () => {

    const list = [
        {
            page: 'New Parcels',
            actions: [
                {
                    title: {
                        name: 'Select/Unselect All',
                        desc: 'отмечает все посылки или снимает выделение всех посылок.',
                    },
                    images: [
                        {
                            path: '/assets/img/docs/select-all.png',
                            alt: 'select/unselect',
                        },
                        {
                            path: '/assets/img/docs/select-all-accept.png',
                            alt: 'select/unselect',
                        },
                    ]
                },
                {
                    title: {
                        name: 'Send to warehouse',
                        desc: `Отправка посылок на склад. Для того чтобы отправить посылки на склад их надо отметить.
                        После нажать кнопку "Отправить на склад", должна появится форма, где надо заполнить два поля и
                        выбрать склад (пока только один склад).
                        В первое поле можно вводить буквы и цифры, во второе поле только цифры. Все поля должны быть заполнены.`,
                    },
                    images: [
                        {
                            path: '/assets/img/docs/sendwarehousebutton.png',
                            alt: 'send-warehouse-button',
                        },
                        {
                            path: '/assets/img/docs/sendwarehouseform.png',
                            alt: 'send-warehouse-button-form',
                        },
                    ]
                },
            ]
        }
    ];

    return (
        <div className={'container-fluid h-100 overflow-auto'}>
            {
                list.length
                    ? list.map((value, key) =>
                        <div key={key}>
                            <div className={'row'}>
                                <div className={'col'}>
                                    <h2 className={'text-center'}>{value.page}</h2>
                                </div>
                            </div>
                            {
                                value.actions.map((action, actionKey) =>
                                    <div key={key} className={'row'}>
                                        <div className={'col-12 col-md-8'}>
                                            <p>
                                                <b>{action.title.name}</b> - {action.title.desc}
                                            </p>
                                        </div>
                                        <div className={'col-4'}>
                                            {
                                                action.images.map((image, imageKey) =>
                                                    <Image className={'w-100'} src={image.path} alt={image.alt}/>
                                                )
                                            }
                                        </div>
                                    </div>
                                )
                            }
                        </div>
                    )
                    : null
            }
            {/*<div className={'row'}>*/}
            {/*    <div className={'col'}>*/}
            {/*        <h2 className={'text-center'}>New Parcels</h2>*/}
            {/*    </div>*/}
            {/*</div>*/}
            {/*<div className={'row'}>*/}
            {/*    <div className={'col-6'}>*/}
            {/*        <p>*/}
            {/*            <b>Select/Unselect All</b> - отмечает все посылки или снимает выделение всех посылок*/}
            {/*        </p>*/}
            {/*    </div>*/}
            {/*    <div className={'col'}>*/}
            {/*        <Image src={'/assets/img/docs/select-all.png'} alt={'select/unselect'}/>*/}
            {/*        <Image src={'/assets/img/docs/select-all-accept.png'} alt={'select/unselect'}/>*/}
            {/*    </div>*/}
            {/*</div>*/}
        </div>
    );
};

export default App;
