import React        from 'react';
import { Button }   from "react-bootstrap";

const ModalButtons = ({className, buttons}) => {

    const buts = Object.entries(buttons ? buttons : {});
    const showButtons = !!buts.length;

    return (
        <div className={`modal-form-buttons-block ${className ? className : ''}`}>
            {showButtons ? <hr/> : null}
            {
                showButtons ?
                    <div className={'d-flex flex-column flex-lg-row justify-content-lg-end'}>
                        {
                            buts.map(
                                button => {
                                    const { action = () => {}, type, variant, disabled, hidden, text, className, style = {} } = button[1];

                                    return <Button
                                        style={{
                                            ...style,
                                            minWidth: '114px',
                                        }}
                                        className={`modal-form-button ${className}`}
                                        onClick={action}
                                        type={type}
                                        key={button[0]}
                                        variant={variant}
                                        disabled={disabled}
                                        hidden={hidden}
                                    >
                                        {text}
                                    </Button>
                                }
                            )
                        }
                    </div>
                    : null
            }
        </div>
    );
};

export default ModalButtons;
