import { routesNames } from "../constants";
import Login           from "../pages/auth/Login";
import Signup          from "../pages/auth/Signup";
import RestorePassword from "../pages/auth/RestorePassword";
import React           from "react";
import { Navigate }    from "react-router-dom";
import RootAuth        from "../pages/auth/RootAuth";

export default [
    {
        path: routesNames.AUTH,
        element: <RootAuth/>,
        children: [
            {
                index: true,
                element: <Navigate to={`${routesNames.LOGIN}`}/>
            },
            {
                path: routesNames.LOGIN,
                element: <Login/>
            },
            {
                path: routesNames.SIGNUP,
                element: <Signup/>
            },
            {
                path: routesNames.RESTORE,
                element: <RestorePassword/>
            },
            {
                path: '*',
                element: <Navigate to={`${routesNames.LOGIN}`}/>
            }
        ]
    },
    {
        path: '*',
        element: <Navigate to={`${routesNames.AUTH}/${routesNames.LOGIN}`}/>
    }
]
