import React, {useEffect, useState} from 'react';
import i18n from "i18next";
import {useDispatch} from "react-redux";
import {cssTransition, toast} from "react-toastify";
import {Button} from "react-bootstrap";

import localStyles from './TabNotificationSettings.module.scss'
import CheckBox from "../../components/input/CheckBox";
import Divider from "../../components/shared/Divider";
import axios from "../../lib/axios";
import {setLoadSpinner} from "../../storage/global";

const TabNotificationSettings = () => {

    const dispatch = useDispatch();

    const [debtNotice, setDebtNotice] = useState(false)
    const [parcelsNotice, setParcelsNotice] = useState(false)

    useEffect(() => {

        dispatch(setLoadSpinner(true))

        axios.get('/api/admin/getNotificationSettings')
            .then(({data}) => {
                setDebtNotice(!!data?.debtNotice)
                setParcelsNotice(!!data?.parcelsNotice)
            })
            .finally(() => {
                dispatch(setLoadSpinner(false))
            })
    }, [])

    function save() {

        const requestData = {
            debtNotice: debtNotice,
            parcelsNotice: parcelsNotice,
        }

        const zoom = cssTransition({
            enter: "animate__animated animate__zoomIn",
            exit: "animate__animated animate__zoomOut"
        })

        dispatch(setLoadSpinner(true))
        axios.put('/api/admin/updateNotificationSettings', requestData)
            .then(_ => {

                toast.info(<div className={'text-center'}>{i18n.t('success')}</div>, {
                    autoClose: 1000,
                    position: "bottom-center",
                    theme: 'colored',
                    transition: zoom,
                    hideProgressBar: true
                });
            })
            .catch(() => {
                toast.error(<div className={'text-center'}>{i18n.t('error')}</div>, {
                    autoClose: 1000,
                    position: "bottom-center",
                    theme: 'colored',
                    transition: zoom,
                    hideProgressBar: true
                });
            })
            .finally(() => {
                dispatch(setLoadSpinner(false))
            })

        console.log(requestData)
    }

    return (
        <div className={'container-page'}>
            <div className={'row'}>
                <div className={`v-card v-sheet theme--light p-3 d-flex ${localStyles.gap20} text-bold`}>
                    <div className={`col-auto d-flex align-items-center ${localStyles.gap10}`}>
                        <CheckBox label={'Customer Debt Notice'}
                                  id={'debt_notice'}
                                  value={debtNotice}
                                  handle={() => {
                                      setDebtNotice(!debtNotice)
                                  }}
                        />
                    </div>
                    <div className={`col-auto d-flex align-items-center ${localStyles.gap10}`}>
                        <CheckBox label={'Unidentified Parcels Notice'}
                                  id={'unidentified_notice'}
                                  value={parcelsNotice}
                                  handle={() => {
                                      setParcelsNotice(!parcelsNotice)
                                  }}
                        />
                    </div>
                    <div className="col d-flex justify-content-end">
                        <Button onClick={() => {
                            save();
                        }}>
                            <span>Save</span>
                        </Button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default TabNotificationSettings;
