import React   from 'react';

import Divider from "../../components/shared/Divider";

const Main = () => {
    return (
        <div className={'h-100 overflow-auto'}>
            <h2 className={'text-center'}>Китайский кабинет</h2>
            <p>Проект написан с использованием двух языков программирования(<i>Javascript, PHP</i>)</p>
            <p>Backend на PHP с использованием micro framework <strong>"Lumen"</strong>(<i>Laravel</i>)</p>
            <p>Frontend на Javascript с использованием библиотеки <strong>"React JS"</strong></p>
            <Divider/>
            <h4>Структура</h4>
            <Divider/>
            <ul>
                <li>
                    <b>conf</b>
                    <ul>
                        <li><b>apache2</b> - папка с конфигами для apache2</li>
                        <li><b>php</b> - папка с конфигами для php</li>
                    </ul>
                </li>
                <li><b>logs</b> - папка с логами веб сервера</li>
                <li>
                    <b>server</b> - папка с приложением
                    <ul>
                        <li><b>build</b> - сюда ложатся файлы после сборки фронта. Именно в эту папку смотрит веб сервер</li>
                        <li><b>app</b> - папка с приложением backend-a</li>
                        <li><b>config</b> - папка с конфигами backend-a</li>
                        <li><b>public</b> - папка с индексными файлами</li>
                        <li><b>resources</b> - папка с ресурсами(файлы с языками и с отрендеринным шаблоном который отдает веб сервер)</li>
                        <li><b>routes</b> - папка с роутами для бекенда</li>
                        <li>
                            <b>src</b> - папка с Frontend
                            <ul>
                                <li><b>components</b> - компоненты которые используются (формы, инпуты, части макета)</li>
                                <li><b>css</b> - стили</li>
                                <li><b>lib</b> - библиотека Axios</li>
                                <li><b>pages</b> - стартовые страницы</li>
                                <li><b>resources</b> - языковые ресурсы</li>
                                <li><b>routes</b> - роуты фронта</li>
                                <li><b>storage</b> - хранилище</li>
                                <li><b>utils</b> - утилиты (работа с api, cookie, валидация и другое)</li>
                                <li><b>constants.js</b> - константы используемые на Frontend</li>
                                <li><b>index.js</b> - здесь создается сами приложение React. Это корневой файл для фронтенда</li>
                                <li><b>Main.js</b> - главный файл для приложения. здесь происходит настройка локализации и другого.</li>
                            </ul>
                        </li>
                        <li><b>storage</b> - хранилище для файлов которые использует Backend</li>
                    </ul>
                </li>
                <li><b>docker-compose.yml</b> - docker-compose файл</li>
            </ul>
            <Divider/>
            <h4>Backend</h4>
            <Divider/>
            <p>На Backend(бекенд) нет базы данных. Нет других инструментов(MySQL, Redis, etc.), чистый фреймоврк с минимальным набором.
                В папке <b>Helpers</b> хранятся вспомогательные библиотеки для работы с файлами <b>PDF, Excel</b>,
                для работы с платежной системой <b>AliPay</b> и для подключения и работы с <b>API 1C</b>.</p>
            <p>Файл настроек хранится в корне и называется <b>.env</b></p>

            <Divider/>
            <h4>Frontend</h4>
            <Divider/>
            <p>Front сделан на React JS. Для роутинга используется React Router Dom. Для хранения данных используется Redux.</p>
            <p>В приложении есть два корневых компонента (RootApp, AuthApp). Первый для роутов с аутентификацией, второй без.
                Во втором корневом компоненте находяться страницы <b>Login, Restore, Register</b>.</p>
            <p>Данные приходят по API от бекенда. Для работы с API есть библиотека Axios и файл с методами для получения данных в папке <b>utils</b>.</p>
            <p>Есть админка для редактирования некой информации внутри приложения. Изменения происходит посредством отправки данных на сторону бекенда и записи их в файл.</p>

            <Divider/>
            <h4>Building</h4>
            <Divider/>
            <p>Для сборки фронт надо запустить <b>npm install && npm run build</b> в папке <b>server</b>.</p>
            <p>Для сборки бека надо запустить <b>composer install -o</b> в папке <b>server</b>.</p>
            <p>Должны быть установлены <b>NPM</b> и <b>Composer</b> или использовать докер контейнеры с этими инструментами.</p>
            <p>Приложение будет собрано и положено в папку <b>build</b>.</p>

        </div>
    );
};

export default Main;
