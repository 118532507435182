import React, { useState, useMemo }  from 'react';
import { useDispatch, useSelector } from "react-redux";
import { useTranslation }           from "react-i18next";
import ReactTooltip                 from "react-tooltip";

import ButtonSmallNoBorder          from "../ButtonSmallNoBorder";
import { viewAlert }                from "../../../utils/misc";

const PrintStickerButton = ({className, handle, checkProcessed}) => {

    const {t} = useTranslation();

    const dispatch = useDispatch();
    const list = useSelector(state => state.global.list)

    const [ref, setRef] = useState({});

    const count = useMemo(() => {
        return list.filter(parcel => parcel.check).length
    }, [list])

    const disabled = useMemo(() => {
        if(!checkProcessed) return false;
        const selectedList = list.filter(parcel => parcel.check);
        const processedList = selectedList.filter(parcel => parcel.Processed === "No");
        if(processedList.length) return true;
        return false;
    }, [list])

    function onClick(event) {
        if (count) {
            if (typeof handle === 'function' && !disabled)
                handle()
        } else {
            viewAlert(dispatch, {status: 400}, {text: t('nothingSelected'), type: 'warning'})
        }
    }

    function handleHover(event, ref, isHover = true) {
        if(!disabled) return;
        if (isHover)
            ReactTooltip.show(ref)
        else
            ReactTooltip.hide(ref)
    }

    return (
        <div onClick={onClick}
            ref={ref => setRef(ref)}
            className={`${className ? className : ''}`}
            onMouseOver={event =>  handleHover(event, ref)}
            onMouseOut={event => handleHover(event, ref, false)}
            data-tip={t('parcel_is_being_processed')}
        >
            <ButtonSmallNoBorder disabled={disabled}>
                <img className={'mr-1'} src="/assets/img/print.svg" alt="print-button"/>
                <span>{t('print_sticker')}</span>
            </ButtonSmallNoBorder>
        </div>
    );
};

export default PrintStickerButton;
