import React, { useMemo }           from 'react';
import { useDispatch, useSelector } from "react-redux";
import { useTranslation }           from "react-i18next";

import ButtonSmallNoBorder          from "../ButtonSmallNoBorder";
import { setLoadSpinner }           from "../../../storage/global";
import { viewAlert }                from "../../../utils/misc";

const ExportButton = ({className, buttClassNames, handle, text, filename}) => {

    const dispatch = useDispatch();
    const {t} = useTranslation();
    const list = useSelector(state => state.global.list)

    const count = useMemo(() => {
        return list.filter(parcel => parcel.check).length
    }, [list])

    function onClick(event) {
        if (count) {
            if (typeof handle === 'function') {
                dispatch(setLoadSpinner(true))
                handle()
                    .then(({data}) => {
                        const blob = new Blob([data], {type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'});
                        const url = window.URL.createObjectURL(blob);
                        const link = document.createElement('a');
                        link.href = url;
                        link.setAttribute('download', filename ? filename : 'file.xls'); //or any other extension
                        document.body.appendChild(link);
                        link.click();
                    })
                    .catch(({response}) => {
                        viewAlert(dispatch, response)
                    })
                    .finally(() => dispatch(setLoadSpinner(false)))
            }
        } else {
            viewAlert(dispatch, {status: 400}, {text: t('nothingSelected'), type: 'warning'})
        }
    }

    return (
        <div onClick={onClick} className={`${className ? className : ''}`}>
            <ButtonSmallNoBorder classNames={buttClassNames}>
                <img className={'mr-1'} src="/assets/img/file-xls.svg" alt="export-button"/>
                <span>{text ? text : t('Download_Selected_Parcels')}</span>
            </ButtonSmallNoBorder>
        </div>
    );
};

export default ExportButton;
