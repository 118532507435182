import React from 'react';

const CloseButtonX = ({style, className, handle}) => {
    return (
        <div
            onClick={handle}
            className={`${className}`}
            style={{...style}}
        >
            <img src="/assets/img/close.svg" alt="close"/>
        </div>
    );
};

export default CloseButtonX;
