import React, { memo, useEffect, useMemo, useState }         from 'react';
import { useSelector }                                       from "react-redux";
import ReactList                                             from 'react-list';
import { useTranslation }                                    from "react-i18next";
import ReactTooltip                                          from "react-tooltip";

import {
    NAME,
    POLL_NAME,
    POLL_DATEFROM,
    POLL_DATETO,
    POLL_DATE_FROM,
    POLL_DATE_TO,
    EDIT,
    ACTIVE,
    DELETE,
    CLEAR_CACH,
    ASC,
    DATE,
    SENT_DATE
}               from "../../../constants";
import { getDirection, getExactTime }                        from "../../../utils/misc";
import HeadList                                              from "../HeadList";
import ItemHeadList                                          from "../ItemHeadList";
import RowList                                               from "../RowList";
import ItemList                                              from "../ItemList";
import NothingPage                                           from "../NothingPage";
import CheckBox                                              from "../../input/CheckBox";
import ButtonAdmin                                           from "../pages_buttons/ButtonAdmin";


const PagePollListTemplate = ({
        classesRow,
        emptyListText,
        disableSelect,
        uniqueRowId,
        srcOrder,
        data,
        orderedHook,
        handleClick,
        handleDoubleClick,
        existPollState,
        openPollState,
        switchPoll,
        switchPollActiveState,
        deletePoll,
        clearCachPoll,
    }) => {

    const {t} = useTranslation();

    const selectedList = useSelector(state => state.global.selectedList);

    const defStorage = {
        items: [],
        page: 1,
        iop: 50,
        order: {
            name: '',
            direction: ASC
        },
    };

    const [storage, setStorage] = useState(defStorage)

    const {items, order} = storage;

    useEffect(() => {
        setStorage({
            ...storage,
            order: srcOrder ? {...srcOrder}: defStorage.order
        })
    }, [])

    useMemo(() => {
        handleOrder(order, [DATE, SENT_DATE].includes(order.name), order.name, false);
    }, [data])

    const selectedIDList = useMemo(() => selectedList.map(value => value[uniqueRowId]), [selectedList])

    function isSelected(item) {
        return !!selectedIDList.includes(item[uniqueRowId])
    }

    function handleHover(event, ref, isHover = true) {
        if (isHover)
            ReactTooltip.show(ref)
        else
            ReactTooltip.hide(ref)
    }

    function handleOrder({name, direction}, column, switchDirection = true ) {
        direction = switchDirection ? getDirection(order, column) : direction;
        setStorage({...storage, items: data, order: {name: column, direction}})
    }

    const listHead = [
        {name: POLL_NAME, col: 12, text: t('poll_name'), sortArrow: false, classNames: 'px-3'},
        {name: POLL_DATEFROM, col: '', text: t('date_from'), sortArrow: false, classNames: 'wfc minw-100'},
        {name: POLL_DATETO, col: '', text: t('date_to'), sortArrow: false, classNames: 'wfc minw-100'},
        {name: EDIT, col: '', text: t('edit'), sortArrow: false, classNames: 'wfc minw-100'},
        {name: ACTIVE, col: '', text: t('active_poll'), sortArrow: false, classNames: 'wfc minw-100'},
        {name: DELETE, col: '', text: t('delete'), sortArrow: false, classNames: 'wfc minw-100'},
        {name: CLEAR_CACH, col: '', text: t('clear_cach'), sortArrow: false, classNames: 'wfc minw-100'},
    ]
    const listItem = [
        {name: NAME, col: 12, isTrackNumber: true, classNames: 'px-3'},
        {name: POLL_DATE_FROM, col: '', classNames: 'wfc minw-100', getChildren: (poll) => {
            const date = getExactTime(poll[POLL_DATE_FROM], false);
            return date
        }},
        {name: POLL_DATE_TO, col: '', classNames: 'wfc minw-100', getChildren: (poll) => {
            const date = getExactTime(poll[POLL_DATE_TO], false);
            return date
        }},
        {name: EDIT, col: '', classNames: 'wfc minw-100', getChildren: (poll) => {
            const openThis = existPollState && existPollState.id === poll.id && openPollState;
            return <ButtonAdmin
                onClick={_ => switchPoll(poll.id)}
                text={''}
                buttonClasses={`wfc ${openThis ? 'col-bg-edit' : 'col-bg-primary'}`}
                iconClasses={openThis ? "fa-arrow-up" : "fa-arrow-down"}
                key={poll.id}/>
        }},
        {
            name: ACTIVE, col: '', classNames: 'wfc minw-100', sortArrow: false, getChildren: (poll) => {
                return <CheckBox
                    id={'all-check'}
                    className={'check-input'}
                    styles={{fontSize: '20px'}}
                    handle={ _ => switchPollActiveState(poll.id)}
                    value={poll.active}
                />
            }
        },
        {name: DELETE, col: '', classNames: 'wfc minw-100', getChildren: (poll) => {
            return <ButtonAdmin
                onClick={_ => deletePoll(poll.id)}
                text={''}
                buttonClasses={`wfc col-bg-primary`}
                iconClasses={"fa-trash"}
                key={poll.id}/>
        }},
        {name: CLEAR_CACH, col: '', classNames: 'wfc minw-100', getChildren: (poll) => {
            return <ButtonAdmin
                onClick={_ => clearCachPoll(poll.id)}
                text={''}
                buttonClasses={`wfc col-bg-primary`}
                iconClasses={"fa-broom"}
                key={poll.id}/>
        }},
    ]
    

    return (
        <>
            <HeadList className={'d-mobile-none'}>
                {
                    listHead.map((v, k) =>
                        <ItemHeadList
                            key={k}
                            text={v.text}
                            col={v.col}
                            classNames={v.classNames}
                            sortArrow={v.sortArrow}
                            name={v.name}
                            onClick={ _ => {}}
                            order={order}
                        >{v.getChildren ? v.getChildren(v, k) : null}</ItemHeadList>)
                }
            </HeadList>

            <div className={'page-body minh-100 maxh-500 '} style={{}}>
                <ReactList
                    itemRenderer={(index, key) => {
                        const item = items[index];

                        return <RowList disableSelect={disableSelect} item={item}
                                        className={classesRow}
                                        key={item[uniqueRowId] ?? key}
                                        uniqueId={uniqueRowId}
                                        isSelected={isSelected(item)}
                            >
                            {
                                listItem.map((value, k) => <ItemList
                                    key={(item[uniqueRowId] ?? key) + k}
                                    item={item}
                                    name={value.name}
                                    col={value.col}
                                    classNames={value.classNames}
                                    isTrackNumber={value.isTrackNumber}
                                    handleHover={handleHover}
                                    click={value.click}
                                    attr={''}
                                    copy={value.copy}
                                    afterLoadData={value.afterLoadData}
                                    classTableItem={value.classTableItem}
                                    _dataField={item[value.name]}
                                >{value.getChildren ? value.getChildren(item) : null}</ItemList>)
                            }
                        </RowList>
                    }}
                    pageSize={50}
                    length={items.length}
                    type='variable'
                />
                {items.length ? null : <NothingPage searchLength={null} emptyListText={emptyListText}/>}
            </div>

            <ReactTooltip className={'my-tooltip'} delayHide={100} effect={'solid'}/>

        </>
    );
};

export default memo(PagePollListTemplate);
