import React      from 'react';
import { Button } from "react-bootstrap";

const ButtonSmallNoBorder = ({classNames, children, variant, pClasses = 'p-1', disabled = false}) => {

    return (
        <Button className={`${pClasses} ${classNames ?? ''}`}
            variant={`${variant ?? 'outline-primary-no-border'}`}
            disabled={disabled}
        >
            {children}
        </Button>
    );
};

export default ButtonSmallNoBorder;
