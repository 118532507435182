import React               from 'react';
import { useTranslation }  from "react-i18next";

import ButtonSmallNoBorder from "../ButtonSmallNoBorder";

const ImportButton = ({className, handle, text, classNameBtn = ''}) => {

    const {t} = useTranslation();

    function onClick(e) {
        if (typeof handle === 'function')
            handle(e);

    }

    return (
        <div onClick={onClick} className={`${className ? className : ''}`}>
            <ButtonSmallNoBorder classNames={classNameBtn}>
                <img className={`mr-1`} src="/assets/img/file-xls.svg" alt="import-button"/>
                <span>{text ? text : t('import')}</span>
            </ButtonSmallNoBorder>
        </div>
    );
};

export default ImportButton;
