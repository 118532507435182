
import routesAuth      from './auth';
import routesApp      from './app';

const routes = [
    ...routesAuth,
    ...routesApp,
]

export default routes;
