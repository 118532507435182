import React, {useState, useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {useTranslation} from "react-i18next";

import localStyles from './styles.module.scss'
import {
    setList,
} from "../../storage/global.js";

import {
    Promo_code_number,
    VALUE,
    Validity_period_to,
    The_country_for_which_it_operates,
} from "../../constants.js";

import moment from "moment/moment";
import PageListTemplate from "../../components/shared/PageListTemplate.jsx";
import CopyIcon from "../../components/shared/CopyIcon.jsx";

import { makeGetRequest } from "../../utils/api.js";
import { viewAlert }      from "../../utils/misc";

const PromoCodes = () => {

    const date = moment();

    const {t} = useTranslation();
    const dispatch = useDispatch();

    const promoCodesList = useSelector(state => state.global.promoCodesList);

    useEffect(() => {
        dispatch(setList(promoCodesList));
        return () => {
            dispatch(setList([]));
        }
    }, [promoCodesList])

    const listHead = [
        {
            name: '',
            col: 1,
            sortArrow: false,
            text: '',
            classNames: '',
        },
        {
            name: Promo_code_number,
            col: 3,
            sortArrow: false,
            text: t('Promo_code_number'),
            classNames: 'flex-1-0 justify-content-center',
            getChildren: (el) => <div className={'text-bold fs-12px'}>{t('Promo_code_number')}</div>
        },
        {
            name: VALUE,
            col: 1,
            sortArrow: false,
            text: t('Promo_code_amount'),
            classNames: 'flex-1-0 justify-content-center',
            getChildren: (el) => <div className={'text-bold fs-12px'}>{t('Promo_code_amount')}</div>
        },
        {
            name: Validity_period_to,
            col: 1,
            sortArrow: false,
            text: t('Validity_period_to'),
            classNames: 'flex-1-0 justify-content-center',
            getChildren: (el) => <div className={'text-bold fs-12px'}>{t('Validity_period_to')}</div>
        },
        {
            name: The_country_for_which_it_operates,
            col: 3,
            sortArrow: false,
            text: t('The_country_for_which_it_operates'),
            classNames: 'flex-1-0 justify-content-center',
            getChildren: (el) => <div className={'text-bold fs-12px'}>{t('The_country_for_which_it_operates')}</div>
        },
    ]

    const listItem = [
        {
            name: '',
            col: 1,
            sortArrow: false,
            text: '',
            classNames: ''
        },
        {
            name: Promo_code_number,
            col: 3,
            isTrackNumber: false,
            copy: true,
            classNames: 'flex-1-0',
            classNamesChildren: `${localStyles.copy}`,
        },
        {
            name: VALUE,
            col: 1,
            isTrackNumber: false,
            classNames: 'flex-1-0',
            classTableItem: 'w-100 d-flex justify-content-center table-item-text',
            getChildren: (promoCode) => <div className={'w-100 d-flex justify-content-center'}>{promoCode[VALUE]}</div>
        },
        {
            col: 1,
            isTrackNumber: false,
            classNames: 'flex-1-0',
            classTableItem: 'w-100 d-flex justify-content-center table-item-text',
            getChildren: (promoCode) => <><span className={'p-1'}>{promoCode[Validity_period_to]?.slice(0,10)}</span></>,
        },
        {
            name: The_country_for_which_it_operates,
            col: 3,
            text: t('The_country_for_which_it_operates'),
            classNames: 'flex-1-0',
            classTableItem: 'w-100 d-flex justify-content-center table-item-text',
            getChildren: (promoCode) => {
                const countries = promoCode.countries?.length > 7 ? t('All_countries') : promoCode.countries?.join(', ');
                return countries;
            }
        },
    ]

    const listItemMobile = {
        firstColumn: [
            {
                name: Promo_code_number,
                col: 24,
                copy: true,
                // isTrackNumber: false,
                classNamesChildren: `${localStyles.children}`,
                getChildren: (promoCode) => <>
                    <span className={'name-column text-wrap text-bold fs-12px '}
                          style={{
                            display: 'inline-block',
                            marginBottom: '3px'
                          }}
                    >
                        {t('Promo_code_number')}
                    </span>
                    <br/>
                    <div className={`d-flex align-items-center`}>
                        <span onClick={() => {}}
                              className={'text-minimise mr-1'}
                              style={{
                                fontSize: '10px',
                                lineHeight: '14px',
                                color: '#0060AE',
                                fontWeight: 700,
                            }}
                        >
                            {promoCode[Promo_code_number]}
                        </span>
                        <CopyIcon value={promoCode[Promo_code_number]} styles={{height: '20px'}}/>
                    </div>
                </>
            },
            {
                name: VALUE,
                col: 24,
                isTrackNumber: false,
                getChildren: (promoCode) => <>
                    <span className={'name-column text-wrap text-bold fs-12px'}>{t('Promo_code_amount')}</span>
                    <br/>
                    <span className={'p-1'}>{promoCode[VALUE]}</span>
                </>
            },
        ],
        secondColumn: [
            {
                col: 24,
                isTrackNumber: false,
                getChildren: (promoCode) => <>
                    <span className={'name-column text-wrap text-bold fs-12px'}>{t('Validity_period_to')}</span>
                    <br/>
                    <span className={'p-1'}>{promoCode[Validity_period_to]?.slice(0,10)}</span>
                </>
            },
            {
                col: 24,
                isTrackNumber: false,
                getChildren: (promoCode) => {
                    const countries = promoCode.countries?.length > 7 ? t('All_countries') : promoCode.countries?.join(', ');
                    return <>
                        <span className={'name-column text-wrap text-bold fs-12px'}>{t('The_country_for_which_it_operates')}</span>
                        <br/>
                        <span className={'text-wrap'}>{countries}</span>
                    </>
                }
            },
        ]
    }

    return (
        <div className={'container-page h-100 promo-codes'}>
            <PageListTemplate
                srcOrder={{}}
                uniqueRowId={'TrackNumber'}
                classesRow={'cursor-pointer'}
                listHead={listHead}
                listItem={listItem}
                listItemMobile={listItemMobile}
                emptyListText={''}
                disableSelect
                headList2={true}
            />
        </div>
    )
}

export default PromoCodes;
