import React     from 'react';

import ArrowSort from "./ArrowSort";

const ArrowSortBlock = ({className, name, direction, currentName, size, directionList}) => {

    const [ASC, DESC] = directionList;

    return (
        <div className={`${className ? className : ''}`}>
            {
                name === currentName
                    ?
                        direction === ASC
                            ? <ArrowSort directionList={directionList} direction={DESC} size={size}/>
                            : <ArrowSort directionList={directionList} direction={ASC} size={size}/>
                    :
                    <>
                        <ArrowSort directionList={directionList} direction={ASC} size={size}/>
                        <ArrowSort directionList={directionList} direction={DESC} size={size}/>
                    </>
            }
        </div>
    );
};

export default ArrowSortBlock;
