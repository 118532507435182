import React        from 'react';

const HeadList2 = ({children, className}) => {
    return (
        <div className={`${className ? className : ''} page-header`}>
            <div className={'row g-0 w-100'}>
                {children}
            </div>
        </div>
    );
};

export default HeadList2;
