import React from 'react';

const ArrowSort = ({direction, size, className, directionList}) => {

    const DESC = directionList[1];

    return (
        <div className={`${className ? className : ''}`}>
            <img src={`/assets/img/${direction === DESC ? 'chevron-down' : 'chevron-up'}.svg`} width={size ? size : 12} alt="arrow-direction"/>
        </div>
    );
};

export default ArrowSort;
