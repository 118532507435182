import React          from 'react';

import ArrowSortBlock from "./tables_elements/ArrowSortBlock";
import { ASC, DESC }  from "../../constants";

const ItemHeadList2 = ({onClick, text, col, sortArrow, size, name, order, children, classNames}) => {
    return (
        <div
            onClick={onClick}
            className={`my-col-${col} d-flex align-items-center p-2 cursor-pointer ${classNames}`}>
            <div>
                {
                    children ? children : <span>{text}</span>
                }
            </div>
            {
                sortArrow === false
                    ?
                    null
                    :
                    <ArrowSortBlock
                        className={'ml-1 mr-1'}
                        size={size ?? 10}
                        name={name}
                        currentName={order.name}
                        direction={order.direction}
                        directionList={[ASC, DESC]}
                    />
            }
        </div>
    );
};

export default ItemHeadList2;
