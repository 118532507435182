import React, {useMemo}             from 'react';
import { useDispatch, useSelector } from "react-redux";
import { useTranslation }           from "react-i18next";

import ButtonSmallNoBorder          from "../ButtonSmallNoBorder";
import { viewAlert }                from "../../../utils/misc";

const EditButton = ({className, handle}) => {

    const {t} = useTranslation();
    const list = useSelector(state => state.global.list)

    const selectedList = useMemo(() => {
        return list.filter(parcel => parcel.check)
    }, [list])
    const dispatch = useDispatch();

    function onClick(event) {
        if (selectedList.length) {
            if (typeof handle === 'function' && selectedList.length) {
                handle(selectedList.slice(0)[0])
            }
        } else {
            viewAlert(dispatch, {status: 400}, {
                text: t('nothingSelected'),
                type: 'warning'
            })
        }
    }

    return (
        <div onClick={onClick} className={`${className ? className : ''}`}>
            <ButtonSmallNoBorder>
                <img className={'mr-1'} src="/assets/img/edit.svg" alt="edit-button"/>
                <span>{t('edit')}</span>
            </ButtonSmallNoBorder>
        </div>
    );
};

export default EditButton;
