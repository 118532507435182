import React                        from 'react';
import ReactDOM                     from "react-dom";
import { useDispatch, useSelector } from "react-redux";

import { setModalForm2 }             from "../../../storage/global";

const MyModalForm2 = ({fullScreen, isOpen, title, form}) => {

    const modalForm = useSelector(state => state.global.modalForm2)
    const dispatch = useDispatch();

    const myForm = {
        show: isOpen ?? modalForm.show,
        title: title ?? modalForm.title,
        form: form ?? modalForm.form,
        fullScreen: fullScreen ?? modalForm.fullScreen,
        hideOut: modalForm.hideOut,
        styles: modalForm.styles,
        formContent: modalForm.formContent,
        formBody: modalForm.formBody,
        hideCloseButton: !!(modalForm.hideCloseButton ?? false)
    }


    if (!myForm.show) return null;

    function hide(e) {
        dispatch(setModalForm2({
            ...myForm,
            show: false,
            formContent: null,
        }))
    }

    function hideOut(e) {
        if (e.target.id === 'modal-form' && myForm.hideOut)
            hide(e)
    }

    return ReactDOM.createPortal(
        <div
            id={'modal-form'}
            className={`modal-form d-flex justify-content-center align-items-center h-100 ${myForm.fullScreen ? 'modal-form-fullscreen' : ''}`}
            onClick={hideOut}
            style={{...myForm.styles}}
        >
            <div
                id={'modal-form-content'}
                className={`modal-form-content ${myForm.formContent ? myForm.formContent.classNames : ''}`}
            >
                {
                    myForm.title && myForm.title.length ?
                        <div className={'modal-form-header'}>

                            <div
                                className={'d-flex justify-content-between align-items-center'}
                                style={{marginBottom: '10px'}}
                            >
                                <span
                                    style={{
                                        fontWeight: 'bold'
                                    }}
                                >
                                    {myForm.title}
                                </span>
                                <img onClick={hide}
                                     className={`my-modal-close cursor-pointer ${myForm.hideCloseButton ? 'd-none' : ''}`} src="/assets/img/close.svg"
                                     alt="close"/>
                            </div>

                        </div>
                        : null
                }
                <div className={`modal-form-body ${myForm.formBody ? myForm.formBody.classNames : ''}`}>
                    {myForm.form}
                </div>
            </div>
        </div>
        , document.body);
};

export default MyModalForm2;
