import React, { useState }                          from 'react';
import { useDispatch,useSelector }                  from "react-redux";
import { Button, Form }                             from "react-bootstrap";
import { useTranslation }                           from "react-i18next";

import InputText                                    from "../input/InputText";
import { DEFAULT_ERROR_OBJECT }                     from "../../constants";
import { noEmptyValidate, validateLength }          from "../../utils/validate";
import { checkValidate, viewAlert }                 from "../../utils/misc";
import { setAlert,
    setLoadSpinner,
    setModalForm,
    SET_SENDING_FEEDBACK,
    SET_FEEDBACK_TEXT
}   from "../../storage/global";
import { makePostRequest }                          from "../../utils/api";

const FEEDBACK = 'feedback';
const FeedbackForm = () => {

    const {t} = useTranslation();
    const dispatch = useDispatch();

    const profile = useSelector(state => state.global.profile)
    const feedbackText = useSelector(state => state.global.feedbackText)
    const sendingFeedback = useSelector(state => state.global.sendingFeedback)

    const [errorFeedbackText, setErrorFeedbackText] = useState({...DEFAULT_ERROR_OBJECT});

    const validations = {}
    validations[FEEDBACK] = [
        noEmptyValidate(t('validEmpty')),
        validateLength(5, 0, t('validMinValue').replace(/(\$\{1\$\})/, 5)),
    ]

    function handleSubmit(e) {
        e.preventDefault();
        e.stopPropagation();
        const form = e.currentTarget;

        const errors = [
            checkValidate(feedbackText, validations[FEEDBACK], setErrorFeedbackText),
        ]
        const isError = errors.filter(er => er.isError).length
        if (!isError && !sendingFeedback) {
            dispatch({type: SET_SENDING_FEEDBACK, payload: true})
            dispatch(setModalForm({
                show: false,
            }))
            makePostRequest('/api/admin/sendFeedback', {
                feedback: feedbackText,
                usercode: profile.usercode,
            }, {}).then((res) => {
                if(res.status === 200) {
                    viewAlert(dispatch, res.response, {text: t('Thank_you_for_feedback')})
                    dispatch({type: SET_FEEDBACK_TEXT, payload: ''})
                } else {
                    viewAlert(dispatch, res.response)
                }
            })
            .catch(({response}) => {
                console.log('sendFeedback catch: ', response)
                viewAlert(dispatch, {"err": response})
            })
            .finally(() => {
                dispatch({type: SET_SENDING_FEEDBACK, payload: false})
            })
        }
    }

    function handleValues(value, name) {
        checkValidate(value, validations[name], setErrorFeedbackText)
        dispatch({type: SET_FEEDBACK_TEXT, payload: value})
    }

    return (
        <Form onSubmit={handleSubmit}>
            <InputText
                id={FEEDBACK}
                as={'textarea'} rows={5}
                placeholder={''}
                classes={''}
                styles={{minHeight: '250px'}}
                errorMessage={errorFeedbackText.message}
                // required={true}
                value={feedbackText}
                handleChange={handleValues}
                closeButton
                validations={validations[FEEDBACK]}
                handleBlur={() => {}}
            />

            <Button type="submit" className={'w-100 mt-4'} disabled={sendingFeedback || errorFeedbackText.isError}>
                {t('Submit_Feedback')}
            </Button>
            <Button className={'w-100 mt-4'}
                variant="outline-primary"
                onClick={event => {
                    dispatch(setModalForm({
                        show: false,
                    }))
                }}
            >
                {t('cancel')}
            </Button>

        </Form>
    );
};

export default FeedbackForm;
