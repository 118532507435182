import React, {useEffect, useState} from 'react';

import localStyles from './TabProfileSettings.module.scss'
import CheckBox from "../../components/input/CheckBox";
import Divider from "../../components/shared/Divider";
import {Button} from "react-bootstrap";
import axios from "../../lib/axios";
import {cssTransition, toast} from "react-toastify";
import i18n from "i18next";
import {setLoadSpinner} from "../../storage/global";
import {useDispatch} from "react-redux";

const COMPANY_NAME = 'companyName'
const YOUR_NAME = 'yourName'
const PHONE_NUMBER = 'phoneNumber'
const ID_CARD_NUMBER = 'idCardNumber'
const WECHAT_ID = 'wechatId'
const EMAIL = 'email'

const TabProfileSettings = () => {

    const dispatch = useDispatch();

    const [checkProfile, setCheckProfile] = useState(false)
    const [mandatoryFilling, setMandatoryFilling] = useState(false)
    const [fieldsChecked, setFieldsChecked] = useState({})

    const fields = [
        {
            id: COMPANY_NAME,
            name: 'Company name',
        },
        {
            id: YOUR_NAME,
            name: 'Your name',
        },
        {
            id: PHONE_NUMBER,
            name: 'Phone number',
        },
        {
            id: ID_CARD_NUMBER,
            name: 'ID card number',
        },
        {
            id: WECHAT_ID,
            name: 'Wechat ID',
        },
        {
            id: EMAIL,
            name: 'Email',
        },
    ]

    useEffect(() => {

        dispatch(setLoadSpinner(true))

        axios.get('/api/admin/getProfileSettings')
            .then(({data}) => {
                setFieldsChecked(data?.fieldsChecked ?? {})
                setCheckProfile(!!data?.check_profile)
                setMandatoryFilling(!!data?.mandatory_filling)
            })
            .finally(() => {
                dispatch(setLoadSpinner(false))
            })
    }, [])

    function save() {

        const fieldCheck = {};
        fieldCheck[COMPANY_NAME] = !!fieldsChecked[COMPANY_NAME];
        fieldCheck[YOUR_NAME] = !!fieldsChecked[YOUR_NAME];
        fieldCheck[PHONE_NUMBER] = !!fieldsChecked[PHONE_NUMBER];
        fieldCheck[ID_CARD_NUMBER] = !!fieldsChecked[ID_CARD_NUMBER];
        fieldCheck[WECHAT_ID] = !!fieldsChecked[WECHAT_ID];
        fieldCheck[EMAIL] = !!fieldsChecked[EMAIL];

        const requestData = {
            check_profile: checkProfile,
            mandatory_filling: mandatoryFilling,
            fieldsChecked: fieldCheck
        }

        const zoom = cssTransition({
            enter: "animate__animated animate__zoomIn",
            exit: "animate__animated animate__zoomOut"
        })

        dispatch(setLoadSpinner(true))
        axios.put('/api/admin/updateProfileSettings', requestData)
            .then(_ => {

                toast.info(<div className={'text-center'}>{i18n.t('success')}</div>, {
                    autoClose: 1000,
                    position: "bottom-center",
                    theme: 'colored',
                    transition: zoom,
                    hideProgressBar: true
                });
            })
            .catch(() => {
                toast.error(<div className={'text-center'}>{i18n.t('error')}</div>, {
                    autoClose: 1000,
                    position: "bottom-center",
                    theme: 'colored',
                    transition: zoom,
                    hideProgressBar: true
                });
            })
            .finally(() => {
                dispatch(setLoadSpinner(false))
            })

        console.log(requestData)
    }

    return (
        <div className={'container-page'}>
            <div className={'row'}>
                <div className={`v-card v-sheet theme--light p-3 d-flex ${localStyles.gap20} text-bold`}>
                    <div className={`col-auto d-flex align-items-center ${localStyles.gap10}`}>
                        <CheckBox label={'Check profile'}
                                  id={'check-profile'}
                                  value={checkProfile}
                                  handle={() => {
                                      setCheckProfile(!checkProfile)
                                  }}
                        />
                    </div>
                    <div className={`col-auto d-flex align-items-center ${localStyles.gap10}`}>
                        <CheckBox label={'Mandatory filling'}
                                  id={'mandatory-filling'}
                                  value={mandatoryFilling}
                                  handle={() => {
                                      setMandatoryFilling(!mandatoryFilling)
                                  }}
                        />
                    </div>
                    <div className="col d-flex justify-content-end">
                        <Button onClick={() => {
                            save();
                        }}>
                            <span>Save</span>
                        </Button>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className={'page-header p-2 px-3 d-flex text-bold'}>
                    <div className="col-6"><span>Field name</span></div>
                    <div className="col-6"><span>Mandatory field filling</span></div>
                </div>
            </div>
            <div className="row p-2 py-3">
                <div className="col v-card">
                    {
                        fields.map((value, index) => {
                            return <div className="row" key={index}>
                                <div className="col-6">
                                    <label htmlFor={value.id}>
                                        {value.name}
                                    </label>
                                </div>
                                <div className="col-6">
                                    <CheckBox id={value.id}
                                              value={!!fieldsChecked[value.id]}
                                              handle={check => {
                                                  const newChecked = {...fieldsChecked}
                                                  newChecked[value.id] = check;
                                                  setFieldsChecked(newChecked)
                                              }}
                                    />
                                </div>
                                <div className="col-12">
                                    <Divider/>
                                </div>
                            </div>
                        })
                    }
                </div>
            </div>
        </div>
    );
};

export default TabProfileSettings;
