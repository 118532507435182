import React, {useMemo} from 'react';
import {useSelector} from "react-redux";
import {t} from "i18next";

import localStyles from './wh.module.scss'
import {copy} from "../../utils/misc";

const Wh = () => {

    const profile = useSelector(state => state.global.profile)
    const qrCode = useSelector(state => state.global.qrCode)

    const address = useMemo(() => t('wh_address') + profile?.usercode, [profile])

    function copyAddress() {
        copy(address)
    }

    return (
        <div className={`${localStyles.container}  ${qrCode.qr_code ? localStyles.withQRCode : ''}`}
             onClick={copyAddress}
        >
            <span className={localStyles.address}
            >{address}</span>
            <button className={localStyles.copyButton}
                    type={'button'}
            >
                {t('wh_copy')}
            </button>
        </div>
    );
};

export default Wh;
