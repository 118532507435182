import React, { memo }                              from 'react';
import { useDispatch }                              from "react-redux";
import ReactList                                    from 'react-list';
import { useTranslation }                           from "react-i18next";
import ReactTooltip                                 from "react-tooltip";

import {
    NAME_METRICS,
    VALUE,
    PERCENTAGE,
}                       from "../../../constants";
import HeadList                                              from "../HeadList";
import ItemHeadList                                          from "../ItemHeadList";
import RowList                                               from "../RowList";
import ItemList                                              from "../ItemList";
import NothingPage                                           from "../NothingPage";
import CheckBox                                              from "../../input/CheckBox";
import { getExactTimeFullYear }                        from "../../../utils/misc";

const AnalyticsByPercentages = ({
    classesRow,
    emptyListText,
    uniqueRowId,
    items = [],
    withoutPercentage = false,
    name_metrics = 'Name_metrics',
    value = 'value',
    styleListContainer,
}) => {

    const dispatch = useDispatch();
    const {t} = useTranslation();

    const percentageHead = withoutPercentage ? [] : [
        {name: PERCENTAGE, col: 6, text: '%', sortArrow: false, classNames: 'wfc minw-100'},
    ]
    const percentageItem = withoutPercentage ? [] : [
        {name: PERCENTAGE, col: 6, classNames: 'wfc minw-100 py-1'},
    ]
    const listHead = [
        {name: NAME_METRICS, col: 12, text: t(name_metrics), sortArrow: false, classNames: 'px-3'},
        {name: VALUE, col: 6, text: t(value), sortArrow: false, classNames: 'wfc minw-100'},
        ...percentageHead,
    ]
    const listItem = [
        {name: NAME_METRICS, col: 12, isTrackNumber: false, classNames: 'px-3 py-1'},
        {name: VALUE, col: 6, classNames: 'wfc minw-100 py-1'},
        ...percentageItem,
    ]

    return (
        <>
            <HeadList className={'d-mobile-none'}>
                {
                    listHead.map((v, k) =>
                        <ItemHeadList
                            key={k}
                            text={v.text}
                            col={v.col}
                            classNames={v.classNames}
                            sortArrow={v.sortArrow}
                            name={v.name}
                            onClick={ _ => {}}
                        >{v.getChildren ? v.getChildren(v, k) : null}</ItemHeadList>)
                }
            </HeadList>

            <div className={`page-body minh-100 height-list-head `} style={styleListContainer}>
                <ReactList
                    itemRenderer={(index, key) => {
                        const item = items[index];

                        return <RowList disableSelect={true} item={item}
                                        className={classesRow}
                                        key={item[uniqueRowId] ?? key}
                                        uniqueId={uniqueRowId}
                                        isSelected={false}
                            >
                            {
                                listItem.map((value, k) => <ItemList
                                    key={(item[uniqueRowId] ?? key) + k}
                                    item={item}
                                    name={value.name}
                                    col={value.col}
                                    mobileClassIgnore={true}
                                    classNames={value.classNames}
                                    isTrackNumber={value.isTrackNumber}
                                    handleHover={()=>{}}
                                    click={value.click}
                                    attr={''}
                                    copy={value.copy}
                                    afterLoadData={value.afterLoadData}
                                    classTableItem={value.classTableItem}
                                    _dataField={item[value.name] || ''}
                                >{value.getChildren ? value.getChildren(item) : null}</ItemList>)
                            }
                        </RowList>
                    }}
                    pageSize={50}
                    length={items.length}
                    type='variable'
                />
                {items.length ? null : <NothingPage searchLength={null} emptyListText={emptyListText}/>}
            </div>

            <ReactTooltip className={'my-tooltip'} delayHide={100} effect={'solid'}/>
        </>
    );
};

export default memo(AnalyticsByPercentages);
