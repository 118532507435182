import React, { useEffect, useState }  from 'react';
import { t }                           from 'i18next';

import InputText                                from "../../input/InputText";
import CheckBox                                 from "../../input/CheckBox";
import Select                                   from "../../input/Select";
import { typesAnswer }                           from "../../../pages/admin/TabPollSettings";
import ButtonAdmin                              from "../pages_buttons/ButtonAdmin";
import {defMinMax}                              from "./PollEditing";


const PollQuestionEditing = ({
    que,
    index,
    delQue,
    changeQue
}) => {

    const defSelVariant = {
        en: '',
        cn: ''
    }

    const [locQue, setLocQue] = useState(que);

    useEffect(() => {
        setLocQue(que)
    }, [que])

    function changeQueDesc(lng, newVal) {
        const _que = {...locQue};
        _que.question[lng] = newVal
        changeQue(_que);
    }

    function changeRequireState(newVal) {
        const _que = {...locQue};
        _que.require = newVal
        changeQue(_que);
    }

    function changeAnswerType(newVal) {
        const _que = {...locQue};
        _que.type = Number(newVal)
        if(_que.type === 2 && !_que.minmax) _que.minmax = defMinMax
        changeQue(_que);
    }

    function changeSelectType(newVal) {
        const _que = {...locQue};
        _que.multiple_choice = newVal
        changeQue(_que);
    }
    function createSelectVariants() {
        const _que = {...locQue};
        _que.variants.push(defSelVariant)
        changeQue(_que);
    }
    function changeSelectVariants(varintIndex, lng, newVal) {
        const _que = {...locQue};
        _que.variants[varintIndex][lng] = typeof newVal === 'string' ? newVal?.trim() : newVal;
        changeQue(_que);
    }
    function deleteSelectVariants(varintIndex) {
        const _que = {...locQue};
        _que.variants.splice(varintIndex, 1);
        changeQue(_que);
    }

    function changeRatingScale(id, newVal) {
        const _que = {...locQue};
        _que.minmax[id] = +newVal
        changeQue(_que);
    }

    function changeStarCount(newVal) {
        const _que = {...locQue};
        _que.starCount = +newVal
        changeQue(_que);
    }

    return (
        <div className="que">
            <div className="newQuestion d-flex justify-content-end pr-35">
                <ButtonAdmin onClick={delQue} text={"Видалити запитання"} buttonClasses={"my-2 col-bg-danger"} iconClasses={"mr-1 fa-trash-alt"}/>
            </div>
            <InputText
                closeButton
                classes={'mt-2'}
                id={'que.description.en'}
                as={'textarea'} rows={3}
                placeholder={'Запитання англійською'}
                value={que.question.en}
                handleChange={ (val) => changeQueDesc("en", val)}
                styles={{height: 'unset'}}
                groupClssses={"pr-35"}
                errorMessage={que.question.en ? '' : t('validEmpty')}
                smapClasses={'position-relative'}
                hideSpamWithoutError
            />
            <InputText
                closeButton
                classes={'mt-2'}
                id={'que.description.cn'}
                as={'textarea'} rows={3}
                placeholder={'Запитання китайською'}
                value={que.question.cn}
                handleChange={ (val) => changeQueDesc("cn", val)}
                styles={{height: 'unset'}}
                groupClssses={"pr-35"}
                errorMessage={que.question.cn ? '' : t('validEmpty')}
                smapClasses={'position-relative'}
                hideSpamWithoutError
            />

            <div className="row align-items-center my-2">
                <div className={'col-auto'}>
                    <CheckBox
                        className={'check-input m-1'}
                        styles={{fontSize: '20px'}}
                        handle={(value, id) => changeRequireState(value)}
                        value={que.require}
                        label={"Відповідь обов'язкова?"}
                    />
                </div>
                <div className={'col-auto'}>
                    <Select
                        id={'new_que_que.id_' + index}
                        required={que.require}
                        options={typesAnswer}
                        selectValue={typesAnswer.find( el => Number(el.value) === que.type)}
                        nameValue={'name'}
                        keyName={'value'}
                        handleChange={ (el, id) => changeAnswerType(el.value) }
                        errorMessage={''}
                        validations={{}}
                        loaded={false}
                    />
                </div>
                {locQue.type === 1 && <div className="col-12 ">
                    <CheckBox
                        className={'check-input m-1'}
                        styles={{fontSize: '20px'}}
                        handle={(value, id) => changeSelectType(value)}
                        value={que.multiple_choice}
                        label={"Множинний вибір"}
                    />
                </div>}
            </div>
            {locQue.type === 1 && locQue.variants && locQue.variants.map( (variant, i, variants_) =>
                <div className="mx-5 row justify-content-between" key={i}>
                    <div className="col-6">
                        <InputText
                            closeButton
                            classes={'mt-2'}
                            id={'que.variant.en_'}
                            placeholder={'Варіант англійською'}
                            value={variant.en ? variant.en : null}
                            handleChange={ (val) => changeSelectVariants(i, "en", val)}
                            errorMessage={variant.en ? '' : t('validEmpty')}
                            smapClasses={'position-relative'}
                            hideSpamWithoutError
                        />
                        <InputText
                            closeButton
                            classes={'mt-2'}
                            id={'que.variant.ch_'}
                            placeholder={'Варіант китайською'}
                            value={variant.cn || undefined}
                            handleChange={ (val) => changeSelectVariants(i, "cn", val)}
                            errorMessage={variant.cn ? '' : t('validEmpty')}
                            smapClasses={'position-relative'}
                            hideSpamWithoutError
                        />
                    </div>
                    <div className="col-2 ">
                        <CheckBox
                            className={'check-input m-1'}
                            styles={{fontSize: '20px'}}
                            handle={(value, id) => changeSelectVariants(i, "withInput", value)}
                            value={variant.withInput}
                            label={"З полем вводу"}
                        />
                    </div>
                    <div className="col-4 d-flex flex-column justify-content-between align-items-end">
                        {i === 0 && variants_.length === 1 ?
                            <>
                                <div></div>
                                <ButtonAdmin onClick={ _ => createSelectVariants()} text={"Додати варіант"} buttonClasses={"my-2 col-bg-add"} iconClasses={"mr-1 fa-plus"}/>
                            </>
                            : variants_.length - 1 === i ? 
                            <>
                                <ButtonAdmin onClick={ _ => deleteSelectVariants(i)} text={"Видалити варіант"} buttonClasses={"my-2 col-bg-edit"} iconClasses={"mr-1 fa-trash-alt"}/>
                                <ButtonAdmin onClick={ _ => createSelectVariants()} text={"Додати варіант"} buttonClasses={"my-2 col-bg-add"} iconClasses={"mr-1 fa-plus"}/>
                            </>
                            :
                            <>
                                <ButtonAdmin onClick={ _ => deleteSelectVariants(i)} text={"Видалити варіант"} buttonClasses={"my-2 col-bg-edit"} iconClasses={"mr-1 fa-plus"}/>
                            </>
                        }
                    </div>
                </div>
            )}
            {locQue.type === 2 && locQue.minmax &&
                <div className="mx-5 row justify-content-between">
                    <div className="col-6">
                        <InputText
                            closeButton
                            classes={'mt-2'}
                            id={'que.minmax.min_'}
                            placeholder={'Найнижчий бал'}
                            value={locQue.minmax.min || 0}
                            handleChange={ (val) => {
                                changeRatingScale("min", val)
                            }}
                            errorMessage={locQue.minmax.min != null ? '' : t('validEmpty')}
                            smapClasses={'position-relative'}
                            hideSpamWithoutError
                            disable={true}
                            type={'number'}
                        />
                        <InputText
                            closeButton
                            classes={'mt-2'}
                            id={'que.minmax.ch_'}
                            placeholder={'Найбільший бал'}
                            value={locQue.minmax.max || undefined}
                            handleChange={ (val) => changeRatingScale("max", val)}
                            errorMessage={locQue.minmax.max > 0 ? '' : t('validEmpty')}
                            smapClasses={'position-relative'}
                            hideSpamWithoutError
                            type={'number'}
                            required={true}
                        />
                    </div>
                </div>
            }
            {locQue.type === 3 &&
                <div className="mx-5 row justify-content-between">
                    <div className="col-6">
                        <InputText
                            closeButton
                            classes={'mt-2'}
                            id={'que.starCount'}
                            placeholder={'Найбільший бал'}
                            value={locQue.starCount || undefined}
                            handleChange={ (val) => changeStarCount(val)}
                            errorMessage={locQue.starCount > 0 ? '' : t('validEmpty')}
                            smapClasses={'position-relative'}
                            hideSpamWithoutError
                            type={'number'}
                            // required={true}
                        />
                    </div>
                </div>
            }
            <hr/>
        </div>
    );
};

export default PollQuestionEditing;

