import React from 'react';
import { useSelector } from "react-redux";
// import ReactPlayer from 'react-player'

const Spinner = () => {
    const spinnerRaccoon = useSelector(state => state.global.spinnerRaccoon);

    return (spinnerRaccoon ? <div
            style={{
                width: '190px',
                height: '190px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                overflow: 'hidden',
                borderRadius: '50%'
            }}
        >
            <video className={"w-100"} autoPlay muted src="/assets/files/coon.mp4" />
        {/* <ReactPlayer
          url={'/assets/files/raccoon.mov'}
          playing={true}
          volume={0}
          muted={true}
        /> */}
        </div>
        : <div className="lds-dual-ring"/>
    );
};

export default Spinner;
