import React              from 'react';
import { useDispatch }    from "react-redux";
import { useNavigate }    from "react-router-dom";
import { useTranslation } from "react-i18next";

import { setLoadSpinner } from "../../storage/global";
import { logout }         from "../../utils/api";
import { routesNames }    from "../../constants";

const LogoutButton = ({className}) => {

    const {t} = useTranslation();
    const dispatch = useDispatch();
    const navigate = useNavigate();

    function logoutCustom() {
        dispatch(setLoadSpinner(true))
        logout().then(r => navigate(`/${routesNames.AUTH}/${routesNames.LOGIN}`))
            .finally(() => dispatch(setLoadSpinner(false)))
    }

    return (
        <div className={`cursor-pointer ${className}`}>
            <div onClick={logoutCustom} className={'d-flex align-items-center'}>
                <img src="/assets/img/sign_out.svg" alt="sign-out"/>
                <span className={'my-text-danger sign-out-text'}>{t('sign_out')}</span>
            </div>
        </div>
    );
};

export default LogoutButton;
