export default [
    {
        code: 707,
        postal_code: 707,
        name_en: "Arrived at return division",
        name_cn: "包裹已到达退件中心",
        name_ru: "Прибыл в отдел возврата",
        name_ua: "Прибув до відділу повернення",
        name_uz: "Qaytarish bo'limiga keldi",
        name_pl: "Dotarł do działu zwrotów"
    }, {
        code: 1630,
        postal_code: 1630,
        name_en: "Parcel Disposed",
        name_cn: "包裹已销毁",
        name_ru: "Посылка утилизирована",
        name_ua: "Посилка утилізована",
        name_uz: "Jo'natma yoq qilindi",
        name_pl: "Przesyłka zutylizowana"
    }, {
        code: 1629,
        postal_code: 1629,
        name_en: "Parcel lost",
        name_cn: "包裹已丢失",
        name_ru: "Посылка потеряна",
        name_ua: "Посилка втрачена",
        name_uz: "Jo'natma yoqolgan",
        name_pl: "Przesyłka zaginiona"
    }, {
        code: 1601,
        postal_code: 1601,
        name_en: "Unsuccessful delivery attempt",
        name_cn: "包裹投递尝试失败",
        name_ru: "Неудачная попытка доставки",
        name_ua: "Невдала спроба доставки",
        name_uz: "Yetkazib berish urinishi muvaffaqiyatsiz bo'ldi",
        name_pl: "Nieudana próba doręczenia"
    }, {
        code: 1315,
        postal_code: 1315,
        name_en: "Left courier facility",
        name_cn: "包裹已离开快递公司",
        name_ru: "Выдано курьеру",
        name_ua: "Видано кур'єру",
        name_uz: "Kuryerga berilgan",
        name_pl: "Wydane kurierowi"
    }, {
        code: 1620,
        postal_code: 1620,
        name_en: "Delivery refused",
        name_cn: "包裹已被拒收",
        name_ru: "Отказ от получения",
        name_ua: "Відмова від отримання",
        name_uz: "Qabul qilish rad etildi",
        name_pl: "Odmowa przyjęcia towaru"
    }, {
        code: 2210,
        postal_code: 2210,
        name_en: "Departure from customs office",
        name_cn: "包裹已离开海关办公室",
        name_ru: "Отправка с таможенного офиса",
        name_ua: "Відправка з митного офісу",
        name_uz: "Bojxona idorasidan yuborish",
        name_pl: "Wysyłka z urzędu celnego"
    }, {
        code: 808,
        postal_code: 808,
        name_en: "Out of Customs/Departure from inward office of exc",
        name_cn: "包裹已离开海关/ 离开处理中心",
        name_ru: "Отправка из международного сортировочного центра в",
        name_ua: "Відправка з міжнародного сортувального центру у на",
        name_uz: "Xalqaro saralash markazidan yuborish",
        name_pl: "Wysyłka z międzynarodowego centrum sortowania udo"
    }, {
        code: 8081,
        postal_code: 8081,
        name_en: "Departure from sender’s office",
        name_cn: "包裹已从派件公司发出",
        name_ru: "Выезд из офиса отправителя",
        name_ua: "Виїзд з офісу відправника",
        name_uz: "Yuboruvchining ofisidan jo'nab ketish",
        name_pl: "Wyjazd z biura nadawcy"
    }, {
        code: 304,
        postal_code: 304,
        name_en: "Departure from outward office of exchange",
        name_cn: "包裹离已开外汇办公室",
        name_ru: "Отправка из сортировочного центра страны отправителя",
        name_ua: "Відправка з сортувального центру країни відправника",
        name_uz: "Yuboruvchi mamlakatning saralash markazidan jo'nab ketish",
        name_pl: "Wysyłka z centrum sortowania w kraju nadawcy"
    }, {
        code: 303,
        postal_code: 303,
        name_en: "Departure from outward office of exchange",
        name_cn: "包裹已离开外汇办公室",
        name_ru: "Отправка из сортировочного центра страны отправителя",
        name_ua: "Відправка з сортувального центру країни відправника",
        name_uz: "Yuboruvchi mamlakatning saralash markazidan jo'nab ketish",
        name_pl: "Wysyłka z centrum sortowania w kraju nadawcy"
    }, {
        code: 405,
        postal_code: 405,
        name_en: "Departure from international sorting center",
        name_cn: "包裹已离开国际分拣处理中心",
        name_ru: "Отправлено из  междунарогдного сортировочного центра",
        name_ua: "Відправлено з міжнародного сортувального центру",
        name_uz: "Xalqaro saralash markazidan jo'natilgan",
        name_pl: "Wysłane z międzynarodowego centrum sortowania"
    }, {
        code: 1622,
        postal_code: 1622,
        name_en: "Delivered",
        name_cn: "包裹已妥投",
        name_ru: "Доставлено",
        name_ua: "Вручено",
        name_uz: "Доставлено",
        name_pl: "Dostarczono"
    }, {
        code: 2331,
        postal_code: 2331,
        name_en: "Delivery suspended",
        name_cn: "包裹暂停交付",
        name_ru: "Доставка приостановлена",
        name_ua: "Доставка призупинена",
        name_uz: "Yetkazib berish to'xtatildi",
        name_pl: "Dostawa wstrzymana"
    }, {
        code: 2200,
        postal_code: 2200,
        name_en: "Export customs clearance",
        name_cn: "包裹已完成清关",
        name_ru: "Экспортная таможенная очистка",
        name_ua: "Експортне митне оформлення",
        name_uz: "Eksport bojxona rasmiylashtiruvi",
        name_pl: "Odprawa celna eksportu"
    }, {
        code: 2215,
        postal_code: 2215,
        name_en: "Export customs inspection",
        name_cn: "包裹已完成海关检验",
        name_ru: "Экспортный таможенный досмотр",
        name_ua: "Експортний митний огляд",
        name_uz: "Eksport bojxona tekshiruvi",
        name_pl: "Eksportowa inspekcja celna"
    }, {
        code: 1521,
        postal_code: 1521,
        name_en: "Route changed",
        name_cn: "派送路线已调整",
        name_ru: "Изменено направление",
        name_ua: "Змінено напрямок",
        name_uz: "Yo'nalish o'zgartirildi",
        name_pl: "Kierunek został zmieniony"
    }, {
        code: 2432,
        postal_code: 2432,
        name_en: "Restriction removed",
        name_cn: "限制已解除",
        name_ru: "Cнято ограничение в доставке",
        name_ua: "Знято обмеження в доставці",
        name_uz: "Yetkazib berish cheklovi olib tashlandi",
        name_pl: "Ograniczenia w dostawie zostały zniesione"
    }, {
        code: 2230,
        postal_code: 2230,
        name_en: "Held by Customs",
        name_cn: "海关已扣件",
        name_ru: "Таможенное оформление",
        name_ua: "Митне оформлення",
        name_uz: "Bojxona rasmiylashtiruvi",
        name_pl: "Odprawa celna"
    }, {
        code: 606,
        postal_code: 606,
        name_en: "Arrival at sender's office",
        name_cn: "包裹到达派件公司",
        name_ru: "Поступление в подразделение отправителя",
        name_ua: "Надходження до підрозділу відправника",
        name_uz: "Yuboruvchining bo'linmasiga qabul qilinishi",
        name_pl: "Nadchodzi do jednostki nadawcy"
    }, {
        code: 1214,
        postal_code: 1214,
        name_en: "Arrival at delivery office",
        name_cn: "包裹已到达派件办公室",
        name_ru: "Поступление в подразделение получателя",
        name_ua: "Надходження до підрозділу отримувача",
        name_uz: "Qabul qiluvchining bo'linmasiga qabul qilinishi",
        name_pl: "Nadchodzi do jednostki odbiorcy"
    }, {
        code: 202,
        postal_code: 202,
        name_en: "Arrival at outward office of exchange",
        name_cn: "包裹已到达外汇中心",
        name_ru: "Поступление в сортировочный центр страны отправителя",
        name_ua: "Надходження до сортувального центру країни відправника",
        name_uz: "Yuboruvchi mamlakatning saralash markaziga qabul qilish",
        name_pl: "Przybycie do centrum sortowania w kraju nadawcy"
    }, {
        code: 505,
        postal_code: 505,
        name_en: "Arrival at inward office of exchange",
        name_cn: "包裹已到达处理中心",
        name_ru: "Поступление в сортировочный центр страны получателя",
        name_ua: "Надходження до сортувального центру країни отримувача",
        name_uz: "Qabul qiluvchi mamlakatning saralash markaziga qabul qilish",
        name_pl: "Przybycie do centrum sortowania w kraju odbiorcy"
    }, {
        code: 404,
        postal_code: 404,
        name_en: "Arrival at transit office of exchange",
        name_cn: "包裹已到达转运中心",
        name_ru: "Поступление в транзитный сортировочный центр",
        name_ua: "Надходження до транзитного сортувального центру",
        name_uz: "Tranzit saralash markaziga kirish",
        name_pl: "Przybycie do centrum sortowania tranzytowego"
    }, {
        code: 101,
        postal_code: 101,
        name_en: "Posting/collection",
        name_cn: "数据已收录",
        name_ru: "Оформлено для отправки",
        name_ua: "Оформлено для відправки",
        name_uz: "Jo'natish uchun rasmiylashtirilgan",
        name_pl: "Przygotowane do wysyłki"
    }, {
        code: 302,
        postal_code: 302,
        name_en: "Waiting for departure from international sorting center",
        name_cn: "包裹即将从国际分拣处理中心发出",
        name_ru: "Ожидает отправки из международного сортировочного центра",
        name_ua: "Очікує відправлення з міжнародного сортувального центру",
        name_uz: "Xalqaro saralash markazidan jo'natilishini kutmoqda",
        name_pl: "Oczekuje na wysyłkę z międzynarodowego centrum sortowania"
    }, {
        code: 1825,
        postal_code: 1825,
        name_en: "Returned to the sender",
        name_cn: "包裹已退回给发件人",
        name_ru: "Возвращено отправителю",
        name_ua: "Повернуто відправнику",
        name_uz: "Yuboruvchiga qaytarildi",
        name_pl: "Zwrócone do nadawcy"
    }, {
        code: 5051,
        postal_code: 5051,
        name_en: "Parcel not arrived",
        name_cn: "包裹未到达",
        name_ru: "Посылка не прибыла",
        name_ua: "Посилка не прибула",
        name_uz: "Jo'natma qabul qilinmadi",
        name_pl: "Przesyłka nie dotarła"
    }, {
        code: 2533,
        postal_code: 2533,
        name_en: "Recipient verified",
        name_cn: "收件人信息已验证",
        name_ru: "Реквизиты получателя подтверждены",
        name_ua: "Реквізити отримувача підтверджені",
        name_uz: "Qabul qiluvchining ma'lumotlari tasdiklandi",
        name_pl: "Dane odbiorcy są potwierdzone"
    }, {
        code: 203,
        postal_code: 203,
        name_en: "REGISTER COLLECTED",
        name_cn: "包裹数据已登记",
        name_ru: "УКОМПЛЕКТОВАНО В РЕЕСТР",
        name_ua: "СКОМЛЕКТОВАНО ДО РЕЄСТРУ",
        name_uz: "RO‘YXATDA TO‘LDIRILGAN",
        name_pl: "SKOMPLETOWANE DO REJESTRU"
    }, {
        code: 1627,
        postal_code: 1627,
        name_en: "NOT DELIVERED",
        name_cn: "包裹未妥投",
        name_ru: "Попытка доставки: КУРЬЕР НЕ УСПЕЛ ДОСТАВИТЬ",
        name_ua: "Спроба доручення: КУР`ЄР НЕ ВСТИГ ДОСТАВИТИ",
        name_uz: "Yetkazib berishga urinish: KURYER YETKAZIB BERISHGA ULGURMADI",
        name_pl: "Próba doręczenia: KURIER NIE ZDĄŻYŁ DOSTARCZYĆ"
    }, {
        code: 1624,
        postal_code: 1624,
        name_en: "Unable to verify delivery information",
        name_cn: "无法验证派件信息",
        name_ru: "Попытка доставки: Невозможно проверить данные доставки",
        name_ua: "Спроба доручення: Неможливо перевірити дані доставки",
        name_uz: "Yetkazib berishga urinish: Yetkazib berish ma'lumotlarini tekshirib bo‘lmadi",
        name_pl: "Próba doręczenia: Nie można sprawdzić szczegółów dostawy"
    }, {
        code: 1628,
        postal_code: 1628,
        name_en: "Unable to verify recipient",
        name_cn: "无法验证收件人信息",
        name_ru: "Попытка доставки: Невозможно подтвердить реквизиты",
        name_ua: "Спроба доручення: Неможливо підтвердити реквізити",
        name_uz: "Yetkazib berish urinishi: Ma'lumotlarni tasdiqlab bo‘lmadi",
        name_pl: "Próba doręczenia: Brak możliwości potwierdzenia danych"
    }, {
        code: 1625,
        postal_code: 1625,
        name_en: "Recepient not avaliable",
        name_cn: "无法联系收件人",
        name_ru: "Попытка доставки: Получатель недоступен",
        name_ua: "Спроба доручення: Отримувач недоступний",
        name_uz: "Yetkazib berish urinishi: Qabul qiluvchi bilan bog‘lanib bo‘lmadi",
        name_pl: "Próba doręczenia: Odbiorca jest niedostępny"
    }, {
        code: 1626,
        postal_code: 1626,
        name_en: "Recipient changed delivery date",
        name_cn: "收件人已更改派件时间",
        name_ru: "Попытка доставки:Получатель изменил дату доставки",
        name_ua: "Спроба доручення: Отримувач переніс дату доставки",
        name_uz: "Yetkazib berishga urinish: Qabul qiluvchi yetkazib berish sanasini o'zgartirdi",
        name_pl: "Próba doręczenia: Odbiorca przełożył termin dostawy"
    }, {
        code: 1621,
        postal_code: 1621,
        name_en: "Delivery redirected",
        name_cn: "派送地址已更改",
        name_ru: "Попытка доставки: Переадресовано на другой адрес",
        name_ua: "Спроба доручення: Переадресовано на іншу адресу",
        name_uz: "Yetkazib berishga urinish: boshqa manzilga yoʻnaltirildi",
        name_pl: "Próba doręczenia: Przekazano na inny adres"
    }, {
        code: 1623,
        postal_code: 1623,
        name_en: "Returned to the warehouse",
        name_cn: "包裹已退回仓库",
        name_ru: "Попытка доставки: Возвращено на склад",
        name_ua: "Спроба доручення: Повернуто на склад",
        name_uz: "Yetkazib berishga urinish: Omborga qaytarilgan",
        name_pl: "Próba doręczenia: Zwrócone do magazynu"
    }, {
        code: 100,
        postal_code: 100,
        name_en: "Parcel registered",
        name_cn: "包裹已登记",
        name_ru: "Посылка зарегистрирована",
        name_ua: "Посилка зареєстрована",
        name_uz: "Jo'natma ro'yxatga olingan",
        name_pl: "Przesyłka jest zarejestrowana"
    }, {
        code: 2220,
        postal_code: 2220,
        name_en: "Customs clearance completed",
        name_cn: "包裹清关程序已完成",
        name_ru: "Таможенное оформление завершено",
        name_ua: "Митне оформлення завершено",
        name_uz: "Bojxona rasmiylashtiruvi yakunlandi",
        name_pl: "Odprawa celna została zakończona"
    }, {
        code: 2000,
        postal_code: 2000,
        name_en: "Pending customs clearance",
        name_cn: "包裹等待清关",
        name_ru: "В ожидании таможенного оформления",
        name_ua: "Очікує розмитнення",
        name_uz: "Bojxona rasmiylashtiruvi kutilmoqda",
        name_pl: "Oczekuje na odprawę celną"
    }
]
