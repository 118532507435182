import React from 'react';
import { Player, BigPlayButton } from 'video-react';
import { useSelector } from "react-redux";

import "../../../node_modules/video-react/dist/video-react.css"; // import css

const VideoInstr = () => {

    const globalLocale = useSelector(state => state.global.locale)

    return (
        <div className={'mb-4'}>
            <Player
                playsInline
                poster={globalLocale === 'en' ? '/assets/img/poster-video-en.png' : '/assets/img/poster-video-cn.png' }
                src={globalLocale === 'en' ? '/assets/files/videoinstr-en.mp4' : '/assets/files/videoinstr-cn.mp4'}
            >
                <BigPlayButton position={'center'}/>
            </Player>
        </div>
    );
};

export default VideoInstr;
