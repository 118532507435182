import React         from 'react';
import { FormCheck } from "react-bootstrap";

const CheckBox = ({id, label, title, type, as, disable, isInvalid, isValid, handle, className, styles, value, defaultValue}) => {

    const ref = React.createRef();

    function onChange(event) {
        if (typeof handle === 'function') {
            handle(event.currentTarget.checked, id, ref)
        }
    }

    return (
        <FormCheck
            className={`${className ? className : ''}`}
            style={{
                ...styles
            }}
            ref={ref}
            onChange={onChange}
            id={id}
            label={label}
            title={title}
            type={type}
            as={as}
            disabled={disable}
            isInvalid={isInvalid}
            isValid={isValid}
            checked={!!value}
            defaultValue={defaultValue}
        />
    );
};

export default CheckBox;
