import React, { useEffect, useState, useMemo }          from 'react';
import { useDispatch }                                  from "react-redux";
import { useTranslation }                               from "react-i18next";
import { Button }                                       from "react-bootstrap";
import moment                                           from "moment/moment";
import qs                                               from 'query-string';

import {
    NAME_METRICS,
    VALUE,
    PERCENTAGE,
}                       from "../../constants";
import axios                                            from "../../lib/axios";
import { setLoadSpinner }                      from "../../storage/global";
import { makeGetRequest, makePostRequest}  from "../../utils/api";
import { viewAlert }                                    from "../../utils/misc";
import MyDatePicker                                     from "../../components/shared/datepicker/MyDatePicker";
import Select                                           from "../../components/input/Select";
import AnalyticsByPercentages                           from "../../components/shared/RequestSettings/AnalyticsByPercentages";

const TabABCohorts = () => {

    const dispatch = useDispatch();
    const {t} = useTranslation();

    const nothingSelected = t('nothingSelected');

    const [dateForAB, setForAB] = useState({ date_from: Date.now(), date_to: Date.now()});
    const [analyticsA, setAnalyticsA] = useState([]);
    const [analyticsB, setAnalyticsB] = useState([]);

    useEffect(() => {
    }, [])

    function loadData() {
        dispatch(setLoadSpinner(true))
        makeGetRequest('/api/admin/getAnaliticsAB', {
                date_from: dateForAB.date_from,
                date_to: dateForAB.date_to
            },
            {}
        ).then(({data}) => {
            const analytics = data.uris;
            const a_r_f_opened = [];
            const a_r_f_started_filling_uped = [];
            const a_r_f_successed = [];
            const b_r_f_opened = [];
            const b_r_f_started_filling_uped = [];
            const b_r_f_successed = [];
            analytics.forEach( ({ req_uri, uri_full }) => {
                const indexOfParams = uri_full.indexOf('?');
                const strParams = uri_full.substring(indexOfParams);
                const {action, a_data} = qs.parse(strParams);
                const {ab_id_reg, user_code} = JSON.parse(a_data);
                if(+ab_id_reg % 2 == 1) {
                    if(action === 'registration_form_open') a_r_f_opened.push({action, a_data})
                    else if(action === 'registration_form_started_filling_up') a_r_f_started_filling_uped.push({action, a_data})
                    else if(action === 'registration_form_signup') a_r_f_successed.push({action, a_data: JSON.parse(a_data)})
                } else if(+ab_id_reg % 2 == 0) {
                    if(action === 'registration_form_open') b_r_f_opened.push({action, a_data})
                    else if(action === 'registration_form_started_filling_up') b_r_f_started_filling_uped.push({action, a_data})
                    else if(action === 'registration_form_signup') b_r_f_successed.push({action, a_data: JSON.parse(a_data)})
                }
            });
            const itemsRegistrationA = [
                {
                    [NAME_METRICS]: 'Registration form with checking number',
                    [VALUE]: a_r_f_opened.length,
                    [PERCENTAGE]: '100%',
                },
                {
                    [NAME_METRICS]: 'Open registration form and fill field with checking number',
                    [VALUE]: a_r_f_started_filling_uped.length,
                    [PERCENTAGE]: a_r_f_started_filling_uped.length ? ((a_r_f_started_filling_uped.length/a_r_f_opened.length)*100).toFixed(2) + '%' : '0%',
                },
                {
                    [NAME_METRICS]: 'Registration form success with checking number',
                    [VALUE]: a_r_f_successed.length,
                    [PERCENTAGE]: a_r_f_successed.length ? ((a_r_f_successed.length/a_r_f_opened.length)*100).toFixed(2) + '%' : '0%',
                },
            ]
            const itemsRegistrationB = [
                {
                    [NAME_METRICS]: 'Registration form without checking number',
                    [VALUE]: b_r_f_opened.length,
                    [PERCENTAGE]: '100%',
                },
                {
                    [NAME_METRICS]: 'Open registration form and fill field without checking number',
                    [VALUE]: b_r_f_started_filling_uped.length,
                    [PERCENTAGE]: b_r_f_started_filling_uped.length ? ((b_r_f_started_filling_uped.length/b_r_f_opened.length)*100).toFixed(2) + '%' : '0%',
                },
                {
                    [NAME_METRICS]: 'Registration form success without checking number',
                    [VALUE]: b_r_f_successed.length,
                    [PERCENTAGE]: b_r_f_successed.length ? ((b_r_f_successed.length/b_r_f_opened.length)*100).toFixed(2) + '%' : '0%',
                },
            ]
            setAnalyticsA(itemsRegistrationA);
            setAnalyticsB(itemsRegistrationB);
        })
        .catch(({response}) => {
            viewAlert(dispatch, response)
        })
        .finally(() => dispatch(setLoadSpinner(false)))
    }

    function loadExcel() {
        dispatch(setLoadSpinner(true))
        makePostRequest('/api/admin/printFormsAnalytics', {
                analyticsA: analyticsA,
                analyticsB: analyticsB,
            },
            {responseType: 'blob'}
        ).then(({data}) => {
            const date = moment();
            const filename = `ab_analytics_${date.format('YYYY-MM-DD_HH_mm')}.xlsx`;
            const blob = new Blob([data], {type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'});
            const url = window.URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', filename ? filename : 'file.xls'); //or any other extension
            document.body.appendChild(link);
            link.click();
        })
        .catch(({response}) => {
            viewAlert(dispatch, response)
        })
        .finally(() => dispatch(setLoadSpinner(false)))
    }

    function handleDatePicker(dateObject, setDate) {
        const date = {
            date_from: dateObject.firstDate.format('YYYY.MM.DD'),
            date_to: dateObject.secondDate.format('YYYY.MM.DD')
        }
        setDate(date)
    }

    return (
        <div className={'container-page max-height-sub-tabs mt-3'}>
            <div className="px-3 pt-10 mb-3">
                Статистика когорт реєстрацій
            </div>
            <div className="row w-100 m-0">
                <MyDatePicker
                    handle={(dateObj) => handleDatePicker(dateObj, setForAB)}
                    startEnd
                    firstDate={new Date(dateForAB.date_from ?? Date.now()-1000*60*60)}
                    secondDate={new Date(dateForAB.date_to ?? Date.now())}
                    className={'d-flex col-6 px-0'}
                    stylesFirstDate={{marginRight: '15px'}}
                    classNameFirstDate={'mr-3'}
                    textFirst={"З дати"}
                    textSecond={"До дати"}
                    colFirst={['auto', 'auto']}
                    colSecond={['auto', 'auto']}
                    TitleCClassNames={"pl-15 "}
                    TitleClassNames={"lh-0"}
                    DPCClassNames={"mtb-9 minw-100"}
                />
                <div className="col-3 align-self-center">
                    <Button onClick={loadData} className={'hmc mr-1 w-100'}>Показати</Button>
                </div>
                {(analyticsA.length) ? <div className="col-3 align-self-center">
                    <Button onClick={loadExcel} className={'hmc w-100'}>Скачати</Button>
                </div> : null}
            </div>
            <div className="row">
                <div className="col-6">
                    <AnalyticsByPercentages items={analyticsA}/>
                </div>
                <div className="col-6">
                    <AnalyticsByPercentages items={analyticsB}/>
                </div>
            </div>
        </div>
    );
};


export default TabABCohorts;
