import React, {useEffect, useState} from 'react';
import { useDispatch } from "react-redux";
import { Button }                   from "react-bootstrap";

import TabABCohorts        from "./TabABCohorts";
import { setLoadSpinner }   from "../../storage/global";
import Divider              from "../../components/shared/Divider";
import CheckBox             from "../../components/input/CheckBox";
import MyDatePicker         from "../../components/shared/datepicker/MyDatePicker";
import { viewAlert, getExactTime }        from "../../utils/misc";
import { makePostRequest, makeGetRequest }  from "../../utils/api";

const EMAIL_FEEDBACK = 'email-feedback'

const TabABTest = () => {
    const dispatch = useDispatch();

    const testsDef = [
        {
            id: 'ab_registration_form',
            name: 'А/В тестування форми реєстрації',
            active: false,
            date_from: new Date(Date.now()),
            date_to: new Date(Date.now() + 1000*60*60*24),
        },
    ]
    const [tests, setTests] = useState(testsDef);

    useEffect(() => {

        dispatch(setLoadSpinner(true))

        makeGetRequest('/api/misc/getABTests', {}).then((res) => {
            if(res.data && res.data.length) {
                const testForShow = res.data.map( t => ({
                    id: t.id,
                    name: t.name,
                    active: t.active,
                    date_from: new Date(t.date_from),
                    date_to: new Date(t.date_to),
                }))
                setTests(testForShow)
            }
        })
        .catch((err) => {
            console.log("🚀 getABTests err:", err);
            viewAlert(dispatch, err)
        })
        .finally(() => dispatch(setLoadSpinner(false)))
    }, [])

    async function saveData() {
        dispatch(setLoadSpinner(true))
        try {
            const testForSave = tests.map( t => ({
                id: t.id,
                name: t.name,
                active: t.active,
                date_from: (new Date(t.date_from)).toISOString(),
                date_to: (new Date(t.date_to)).toISOString(),
            }))
            makePostRequest('/api/admin/saveABTests', { ab_tests: testForSave }, {}).then((res) => {
                viewAlert(dispatch, res)
            })
            .catch(({response}) => {
                viewAlert(dispatch, response)
            })
            .finally(() => dispatch(setLoadSpinner(false)))
        } catch(err) {
            console.log("🚀 saveData e:", err);
            viewAlert(dispatch, {"err": err})
            dispatch(setLoadSpinner(false))
        }
    }

    function handleDatePicker(dateObject, index) {
        const newArr = [...tests];
        newArr[index] = {
            ...tests[index],
            date_from: new Date(dateObject.firstDate.format('YYYY.MM.DD')).getTime(),
            date_to: new Date(dateObject.secondDate.format('YYYY.MM.DD')).getTime()
        }
        setTests(newArr)
    }

    return (
        <div className={'container-page'} style={{
            minHeight: 'calc(100% - 90px)'
        }}>
            <div className="row">
                <div className={'page-header p-2 px-3 d-flex text-bold'}>
                    <div className="col-4"><span>Назва тусту</span></div>
                    <div className="col-2"><span>Активний</span></div>
                    <div className="col-2 mr-2"><span>З дати</span></div>
                    <div className="col-2"><span>До дати</span></div>
                </div>
            </div>
            <div className="row p-2 py-3 mx-0">
                <div className="col v-card">
                    {tests.map((value, index) => {
                        return <div className="row" key={index}>
                            <div className="col-4">
                                <label htmlFor={value.id}>
                                    {value.name}
                                </label>
                            </div>
                            <div className="col-2">
                                <CheckBox id={value.id}
                                          value={value.active}
                                          handle={check => {
                                            const newArr = [...tests];
                                            newArr[index] = {
                                                ...tests[index],
                                                active: !tests[index].active
                                            }
                                            setTests(newArr)
                                          }}
                                />
                            </div>
                            <MyDatePicker
                                handle={(dO) => handleDatePicker(dO, index)}
                                startEnd
                                className={'d-flex col-4 px-0'}
                                stylesFirstDate={{marginRight: '15px'}}
                                classNameFirstDate={'mr-3'}
                                textFirst={"З дати"}
                                textSecond={"До дати"}
                                colFirst={['auto', 'auto']}
                                colSecond={['auto', 'auto']}
                                TitleCClassNames={"pl-15 "}
                                TitleClassNames={"lh-0"}
                                DPCClassNames={"mt-9 minw-100"}
                                firstDate={value.date_from}
                                secondDate={value.date_to}
                            />
                            <div className="col-12">
                                <Divider/>
                            </div>
                        </div>
                    })}
                </div>
            </div>
            <div className={'row mx-0'}>
                <div className={'col-3'}>
                    <Button onClick={saveData} className={'w-100'} variant={'primary'}>Зберегти</Button>
                </div>
            </div>
            <TabABCohorts/>
        </div>
    );
};

export default TabABTest;
