import React, { memo }         from 'react';
import { useTranslation }   from "react-i18next";

import MenuItemGroup from "./MenuItemGroup";
import Logo          from "../shared/Logo";
import CopyrightMenu from "../shared/CopyrightMenu";
import { goToSite }  from "../../utils/misc";
import Faq from "./Faq";
import Wh from "./WH";
import UserQRCode from "./UserQRCode";
import ContactUs from "./ContactUs";

const MenuLarge = (props) => {
    const {i18n} = useTranslation();
    const lang = i18n.language || 'en';

    return (
        <aside
            className={'background-primary aside-large'}
        >
            <Logo goTo={() => goToSite(lang)} id={'logo-large'} type={'white'} className={'d-flex justify-content-center align-items-center'}/>
            <MenuItemGroup navList={props.navList} theme={'light'} style={{
                overflowY: 'auto',
                maxHeight: props.qr_code ? 'calc(100% - 534px)' : 'calc(100% - 390px)'
            }}/>
            <Wh/>
            <UserQRCode/>
            <Faq/>
            <ContactUs/>
            <CopyrightMenu/>
        </aside>
    );
};

export default memo(MenuLarge);
