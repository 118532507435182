import React, { useState }                          from 'react';
import { useDispatch }                              from "react-redux";
import { useNavigate }                              from "react-router-dom";
import { Button, Form }                             from "react-bootstrap";
import { useTranslation }                           from "react-i18next";

import PhoneVerify                                  from "./PhoneVerify";
import InputText                                    from "../input/InputText";
import { DEFAULT_ERROR_OBJECT, EMAIL, PHONE, PHONE_VERIFY, routesNames }            from "../../constants";
import { emailValidate, noEmptyValidate, validateLength, phoneValidate, isPhone }   from "../../utils/validate";
import { checkValidate, serializeFormToObject, viewAlert }                          from "../../utils/misc";
import { setAlert, setLoadSpinner, setRestorePasswordData }                         from "../../storage/global";

const CONTACT_FIELD = 'CONTACT_FIELD';
const RestoreForm = () => {

    const {t} = useTranslation();
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [emailPhone, setEmailPhone] = useState('');
    const [contactIsPhone, setContactIsPhone] = useState(false);
    const [errorEmailPhone, setErrorEmailPhone] = useState({...DEFAULT_ERROR_OBJECT});
    const [phoneIsVerify, setPhoneIsVerify] = useState(false);
    const [connectionId, setConnectionId] = useState('');
    const [verifyCode, setVerifyCode] = useState('')

    const validations = {}
    validations[EMAIL] = [
        noEmptyValidate(t('validEmpty')),
        validateLength(5, 0, t('validMinValue').replace(/(\$\{1\$\})/, 5)),
        emailValidate(t('IncorrectEmail'))
    ]
    validations[PHONE] = [
        noEmptyValidate(t('validEmpty')),
        validateLength(6, 0, t('validMinValue').replace(/(\$\{1\$\})/, 6)),
        phoneValidate(t('IncorrectPhoneNumber'))
    ]

    function handleSubmit(e) {
        e.preventDefault();
        e.stopPropagation();
        const form = e.currentTarget;

        const errors = [
            checkValidate(emailPhone, validations[contactIsPhone ? PHONE : EMAIL], setErrorEmailPhone),
        ]
        const isError = errors.filter(er => er.isError).length
        if (!isError && phoneIsVerify) {
            dispatch(setRestorePasswordData({
                contactIsPhone,
                connection_id: connectionId,
                code: verifyCode
            }));
        }
    }

    function handleValues(value, name) {
        if (name === CONTACT_FIELD) {
            setEmailPhone(value)
            const contactIsPhone_ = isPhone(value);
            setContactIsPhone(contactIsPhone_);
            checkValidate(value, validations[contactIsPhone_ ? PHONE : EMAIL], setErrorEmailPhone)
            setPhoneIsVerify(false)
            return 'byValue'
        }
    }

    function codeCheckingResult(isVerify, connectionId, verifyCode) {
        setPhoneIsVerify(isVerify);
        setConnectionId(connectionId);
        setVerifyCode(verifyCode);
    }

    return (
        <Form onSubmit={handleSubmit}>
            <InputText
                id={CONTACT_FIELD}
                type={contactIsPhone ? 'text' : 'email'}
                placeholder={t(EMAIL) + ' / ' + t('phone_number')}
                classes={''}
                errorMessage={errorEmailPhone.message}
                required={false}
                value={emailPhone}
                handleChange={handleValues}
                closeButton
                validations={validations[EMAIL]}
                handleBlur={() => {}}
            />

            {!errorEmailPhone.isError ?
                <PhoneVerify
                    key={'1'}
                    id={PHONE_VERIFY}
                    classes={'mt-4'}
                    placeholder={t('verification_code')}
                    errorMessage={errorEmailPhone.message}
                    handleChange={codeCheckingResult}
                    handleBlur={null}
                    validations={[]}
                    closeButton={true}
                    value={emailPhone}
                    customMask={null}
                    required={true}
                    isRestorePassword={true}
                    contactIsPhone={contactIsPhone}
                />
                : null
            }

            <Button type="submit" className={'w-100 mt-4'}>
                {t('Continue')}
            </Button>

            <Button onClick={event => navigate(`/${routesNames.AUTH}/${routesNames.LOGIN}`)} variant="outline-primary"
                    className={'w-100 mt-3'}>
                {t('cancel')}
            </Button>

        </Form>
    );
};

export default RestoreForm;
