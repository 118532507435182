import React, {useEffect, useState} from 'react';
import { useDispatch, useSelector } from "react-redux";
import { Button }                   from "react-bootstrap";

import { setLoad, setLoadSpinner }  from "../../storage/global";
import InputText            from "../../components/input/InputText";
import uniqueArray          from "../../utils/uniqueArray";
import { Close }            from "./TabSystemSettings";
import { viewAlert }        from "../../utils/misc";
import { makePostRequest, makeGetRequest }  from "../../utils/api";

const EMAIL_FEEDBACK = 'email-feedback'

const TabRequestFeedback = () => {
    const dispatch = useDispatch();

    const [email, setEmail] = useState('');
    const [emails, setEmails] = useState({
        emails: [],
        rootEmails: []
    })

    useEffect(() => {

        dispatch(setLoadSpinner(true))

        makeGetRequest('/api/admin/getFeedbackEmails', {}).then((res) => {
            if(res.data) {
                setEmails({emails: [...res.data], rootEmails: [...res.data]})
            } else {
                viewAlert(dispatch, res.response)
            }
        })
        .catch((err) => {
            console.log("🚀 getFeedbackEmails err:", err);
            viewAlert(dispatch, err)
        })
        .finally(() => dispatch(setLoadSpinner(false)))
    }, [])

    function handleValues(value, name, inputRef) {
        switch (name) {
            case EMAIL_FEEDBACK:
                setEmail(value)
                break;
            default:
                break;
        }
    }

    function addEmail(event) {
        const input = document.getElementById(EMAIL_FEEDBACK)
        setEmails({
            ...emails,
            emails: uniqueArray([...emails.emails, input.value.toString().toUpperCase()])
        })
        setEmail('')
    }

    function removeEmail(value) {
        setEmails({...emails, emails: emails.emails.filter(v => v !== value)})
    }

    function reset() {
        setEmails({...emails, emails: [...emails.rootEmails]})
    }

    async function saveData() {
        dispatch(setLoadSpinner(true))
        try {
            makePostRequest('/api/admin/saveFeedbackEmails', {
                emails: emails.emails,
            }, {}).then((res) => {
                viewAlert(dispatch, res.response)
            })
            .catch(({response}) => {
                console.log('saveFeedbackEmails catch: ', response)
                viewAlert(dispatch, {"err": response})
            })
        } catch(err) {
            console.log("🚀 saveData e:", err);
            viewAlert(dispatch, {"err": err})
        }
        dispatch(setLoadSpinner(false))
    }

    return (
        <div className={''}>
            <div className={'row mt-3'}>
                <div className={'col-12 col-lg-6'}>
                    <div className={'row'}>
                        <div className={'col'}>
                            <InputText handleChange={handleValues} value={email} id={EMAIL_FEEDBACK}
                                       placeholder={'Пошта для фідбеків'} closeButton/>
                        </div>
                        <div className={'col-auto'}>
                            <Button onClick={addEmail}>Додати</Button>
                        </div>
                    </div>
                </div>
                <div className={'col-12 col-lg-6 mt-3 mt-lg-0'}>
                    <div className={'p-3'} style={{
                        border: '1px solid red',
                        borderRadius: '15px',
                        height: 'auto',
                        maxHeight: '20rem',
                        overflow: "auto",
                        backgroundColor: '#FFF'
                    }}>
                        {
                            emails.emails.map((v, k) => {
                                return <div key={k} className={'position-relative'} style={{
                                    border: '1px solid blue',
                                    borderRadius: '10px',
                                    padding: '5px',
                                    margin: '5px',
                                    display: 'inline-block'
                                }}>
                                    <strong>{v}</strong>
                                    <Close onClick={event => removeEmail(v)}/>
                                </div>
                            })
                        }
                    </div>
                </div>
            </div>
            <div className={'row mt-3'}>
                <div className={'col-6'}>
                    <Button onClick={reset} className={'w-100'} variant={'outline-primary'}>Скинути</Button>
                </div>
                <div className={'col-6'}>
                    <Button onClick={saveData} className={'w-100'} variant={'primary'}>Зберегти</Button>
                </div>
            </div>
        </div>
    );
};

export default TabRequestFeedback;
