import React from 'react';
import {useSelector} from "react-redux";
import {useTranslation} from "react-i18next";

import localStyles from './faq.module.scss'

const Faq = () => {
    const {t, i18n} = useTranslation();

    const isMobile = useSelector(state => state.global.isMobile);
    const lightTheme = !isMobile;

    return (
        <a href={i18n.language === 'cn' ? 'https://meestcn.cn/destinations/wukelan/#changjianwenti' : 'https://meestcn.cn/en/destinations/ukraine/#faq'}
           target={'_blank'}
           className={`${localStyles.container} cursor-pointer nav-item  ${lightTheme ? 'my-text-white' : 'my-text-primary'}`}
           style={{
               position: isMobile ? 'unset' : 'fixed',
               bottom: 82,
               width: 220
           }}
        >
            <img className={'mr-1'} src={`/assets/img/info-small-${lightTheme ? 'light' : 'dark'}.svg`} alt=""/>
            <span>
                    {t('faq')}
            </span>
        </a>
    );
};

export default Faq;
