import React, { useEffect, useState, useMemo }          from 'react';
import { useDispatch, useSelector }                     from "react-redux";
import { Button }                                       from "react-bootstrap";
import { useTranslation }                               from "react-i18next";

import axios                                            from "../../lib/axios";
import { getResponsibleEmails }                         from "../../utils/api";
import { viewAlert }                                    from "../../utils/misc";
import { setLoad, setLoadSpinner, setResponsibleEmails }    from "../../storage/global";
import ButtonSmallNoBorder          from "../../components/shared/ButtonSmallNoBorder";
import SelectAllButton       from "../../components/shared/pages_buttons/SelectAllButton";
import AddNewButton          from "../../components/shared/pages_buttons/AddNewButton";
import ListResposibleEmails  from '../../components/shared/ListResposibleEmails';


const TabResponsibleEmails = () => {

    const dispatch = useDispatch();
    const {t} = useTranslation();

    const loadSpinner = useSelector(state => state.global.spinner)
    const responsibleEmails = useSelector(state => state.global.responsibleEmails);

    const defResponsibleEmail = {
        email: '',
        newValue: '',
        isEdit: false
    }

    const [openExistPoll, setOpenExistPoll] = useState(false);

    useEffect(() => {
        getResEmails();
    }, [])

    function getResEmails() {
        dispatch(setLoadSpinner(true))
        !loadSpinner && getResponsibleEmails().then(response => {
                const newListEmails = [];
                response.data.forEach( (email, i) => {
                    const newStateEmails = {
                        ...defResponsibleEmail,
                        email,
                        newValue: email
                    };
                    newListEmails.push(newStateEmails);
                })
                dispatch(setResponsibleEmails(newListEmails))
            })
            .catch(({response}) => {viewAlert(dispatch, response)})
            .finally(() => dispatch(setLoadSpinner(false)))
    }

    function addNewResEmail() {
        const newStateEmails = [{...defResponsibleEmail, isEdit: true}, ...responsibleEmails];
        dispatch(setResponsibleEmails(newStateEmails));
    }

    return (
        <div className={'container-page'}>
            <div className={'v-card v-sheet theme--light p-3 d-flex align-items-start flex-column'}>
                <div className={'d-flex justify-content-start align-items-center'}>
                    <AddNewButton handle={addNewResEmail} className={''}/>
                </div>
            </div>
            <div>
                <div className='list-of-poll mb-4 d-flex flex-column'>
                    <ListResposibleEmails
                        emptyListText={'No resposible emails'}
                    />
                </div>
            </div>
        </div>
    );
};

export default TabResponsibleEmails;
