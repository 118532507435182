import React from 'react';

const CopyrightMenu = () => {
    return (
        <p
            className={'position-absolute text-center w-100'}
            style={{
                fontWeight: 400,
                fontSize: '10px',
                lineHeight: '14px',
                /* identical to box height, or 140% */


                color: 'rgba(255, 255, 255, 0.5)',

                position: 'fixed',
                bottom: 0
            }}
        >
            Meest China © All rights reserved</p>
    );
};

export default CopyrightMenu;
