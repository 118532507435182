import React, { useState } from 'react';
import ReactTooltip        from "react-tooltip";

const InfoQuestion = ({className, text, children, handleClick}) => {

    const [ref, setRef] = useState({});

    function handleHover(event, ref, isHover = true) {
        if (isHover)
            ReactTooltip.show(ref)
        else
            ReactTooltip.hide(ref)
    }

    return (
        <span
            ref={ref => setRef(ref)}
            className={`${className ? className : ''}`}
            style={{
                position: 'relative',
                fontSize: '14px',
            }}
            onMouseOver={event =>  handleHover(event, ref)}
            onMouseOut={event => handleHover(event, ref, false)}
            onClick={ (event) => handleClick && handleClick(event, ref, false)}
            data-tip={text}
            data-html="true"
        >
            {
                children ? children : <span className={'my-text-primary'}>?</span>
            }
        </span>
    );
};

export default InfoQuestion;
