import React, { memo }                              from 'react';
import ReactList                                    from 'react-list';
import ReactTooltip                                 from "react-tooltip";

import {
    REQ_URI,
    AVERAGE_REQUEST_TIME,
    WARNING_NOTIF_MAX_VALUE,
}                       from "../../../constants";
import HeadList                                              from "../HeadList";
import ItemHeadList                                          from "../ItemHeadList";
import RowList                                               from "../RowList";
import ItemList                                              from "../ItemList";
import NothingPage                                           from "../NothingPage";
import CheckBox                                              from "../../input/CheckBox";

const RequestStatistic = ({
    classesRow,
    emptyListText,
    disableSelect,
    uniqueRowId,
    items,
    listHead,
    listItem,
}) => {

    return (
        <>
            <HeadList className={'d-mobile-none p-2'}>
                {
                    listHead.map((v, k) =>
                        <ItemHeadList
                            key={k}
                            text={v.text}
                            col={v.col}
                            classNames={v.classNames}
                            sortArrow={v.sortArrow}
                            name={v.name || ''}
                            onClick={ _ => {}}
                        >{v.getChildren ? v.getChildren(v, k) : null}</ItemHeadList>)
                }
            </HeadList>

            <div className={'page-body minh-100 height-list-head '} style={{}}>
                {items?.length && <ReactList
                    itemRenderer={(index, key) => {
                        const item = items[index];

                        return <RowList disableSelect={disableSelect} item={item}
                                        className={`p-1 ${classesRow}`}
                                        key={item[uniqueRowId] ?? key}
                                        uniqueId={uniqueRowId}
                                        isSelected={false}
                            >
                            {
                                listItem.map((value, k) => <ItemList
                                    key={(item[uniqueRowId] ?? key) + k}
                                    item={item}
                                    name={value.name || ''}
                                    col={value.col}
                                    mobileClassIgnore={true}
                                    classNames={value.classNames}
                                    isTrackNumber={value.isTrackNumber}
                                    handleHover={()=>{}}
                                    click={value.click}
                                    attr={''}
                                    copy={value.copy}
                                    afterLoadData={value.afterLoadData}
                                    classTableItem={value.classTableItem}
                                    _dataField={item[value.name] || ''}
                                >{value.getChildren ? value.getChildren(index, key) : null}</ItemList>)
                            }
                        </RowList>
                    }}
                    pageSize={50}
                    length={items.length}
                    type='variable'
                />}
                {items.length ? null : <NothingPage searchLength={null} emptyListText={emptyListText}/>}
            </div>

            <ReactTooltip className={'my-tooltip'} delayHide={100} effect={'solid'}/>
        </>
    );
};

export default memo(RequestStatistic);
