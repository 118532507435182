
import React, { useState }        from 'react';
import { useTranslation }         from "react-i18next";
import { useDispatch }            from "react-redux";

import ModalButtons                         from "./modal/ModalButtons";
import InputText                            from "../input/InputText";
import CheckboxLine                         from "./CheckboxLine";
import { hideModalForm }                    from "../../utils/misc";
import { setGroupSearchesCheckboxes }       from "../../storage/global";

const CheckboxList = ({list, setList}) => {

    const dispatch = useDispatch();
    const {t} = useTranslation();

    const [inputText, setInputText] = useState('');
    const [oldListWithState, setOldListWithState] = useState({...list});
    const [NewListWithState, setNewListWithState] = useState({});
    const [listBarcodes, setListBarcodes] = useState({...list});

    function pars(text) {
        return text.split(/\t|\n| /).reduce( (beforeRes, item, index, array) => item ? {...beforeRes, [item]: true} : beforeRes, {});
    }

    function editText(text) {
        setInputText(text);
        const newList = pars(text)
        setNewListWithState(newList)
        setListBarcodes({...oldListWithState, ...newList})
    }

    function chengeState(id, value) {
        if (id in oldListWithState) {
          const newVal = {...oldListWithState, [id]: value}
          setOldListWithState(newVal)
          setListBarcodes({...NewListWithState, ...newVal})
        } else {
          const newVal = {...NewListWithState, [id]: value}
          setNewListWithState(newVal)
          setListBarcodes({...oldListWithState, ...newVal})
        }
    }

    function removeCheck(id) {
        if (id in oldListWithState) {
            const newVal = {...oldListWithState}
            delete newVal[id]
            setOldListWithState(newVal)
            setListBarcodes({...NewListWithState, ...newVal})
        } else {
            const newVal = {...NewListWithState}
            delete newVal[id]
            setNewListWithState(newVal)
            setListBarcodes({...oldListWithState, ...newVal})
        }
    }

    function hideModal() {
        hideModalForm(dispatch)()
    }

    function saveAndFilter() {
        setList(listBarcodes)
        hideModalForm(dispatch)()
        dispatch(setGroupSearchesCheckboxes({
            page: 'withGroupFilter',
            val: true
        }))
    }

    function saveWithoutFilter() {
        setList(listBarcodes)
        hideModalForm(dispatch)()
        dispatch(setGroupSearchesCheckboxes({
            page: 'withGroupFilter',
            val: false
        }))
    }

    const listBarcodesIds = Object.keys(listBarcodes);

    return (
        <div className='m-4'>
            <InputText
                closeButton
                classes={''}
                id={'fieldForParsBarcodes'}
                as={'textarea'} rows={5}
                placeholder={t('ListBarcodes')}
                value={inputText}
                handleChange={ (val) => editText(val)}
                styles={{height: 'unset'}}
                groupClssses={"pr-35"}
            />
            <div className="firtersList">
                {listBarcodesIds.length ? <hr/> : null}
                <div className='overflow-auto h-100'>
                    {listBarcodesIds.map( (id, num) => {
                      return <CheckboxLine
                          key={num}
                          checkState={listBarcodes[id]}
                          checkTitle={id}
                          checkId={id}
                          chengeState={chengeState}
                          removeCheck={removeCheck}
                      />
                    })}
                </div>
            </div>
            <ModalButtons buttons={{
                undo: {
                    text: t('SaveWithoutFilter'),
                    variant: 'outline-primary',
                    type: 'button',
                    action: saveWithoutFilter,
                },
                cancel: {
                    text: t('cancel'),
                    variant: 'outline-primary',
                    type: 'button',
                    action: hideModal,
                },
                save: {
                    text: t('SaveAndFilter'),
                    variant: 'primary',
                    type: 'submit',
                    action: saveAndFilter
                }
            }}/>
        </div>
    );
};

export default CheckboxList;