import React from 'react';

const Components = () => {

    const list = [
        {
            title: 'Root Ap',
            description: 'Корневой компонент. Работает для всех роутов которые должны пройти аутентификацию',
            props: [],
            methods: [],
        },
        {
            title: 'Auth App',
            description: 'Корневой компонент. Работает для роутов которые не должны проходить аутентификацию (login|signup|restore)',
            props: [],
            methods: [],
        },
        {
            title: 'Accounting',
            description: 'Компонент страницы транзакций',
            props: [],
            methods: [],
        },
        {
            title: 'New Parcels',
            description: 'Компонент страницы новых посылок',
            props: [],
            methods: [],
        },
        {
            title: 'Sent Parcels',
            description: 'Компонент страницы Отправленных посылок',
            props: [],
            methods: [],
        },
        {
            title: 'Waiting Arrival',
            description: 'Компонент страницы посылок в ожидании прибытия',
            props: [],
            methods: [],
        },
        {
            title: 'Charge Form',
            description: 'Форма оплаты',
            props: [],
            methods: [],
        },
        {
            title: 'Delete parcel form',
            description: 'Форма удаления посылки',
            props: [
                {name: 'text', type: 'string', description: 'Текст подтверждения'},
                {name: 'buttons', type: 'object', description: 'Обьект с кнопками'},
            ],
            methods: [
                {name: 'deleteMethod', type: 'function (Promise)', description: 'Метод для удаления посылки'}
            ],
        },
        {
            title: 'Import form',
            description: 'Форма импорта посылок с файлов Excel (xlsx, xls)',
            props: [],
            methods: [],
        },
        {
            title: 'Login Form',
            description: 'Форма логина',
            props: [],
            methods: [],
        },
        {
            title: 'Register Form',
            description: 'Форма регистрации',
            props: [],
            methods: [],
        },
        {
            title: 'Restore Form',
            description: 'Форма восстановления пароля',
            props: [],
            methods: [],
        },
        {
            title: 'New Parcel Form',
            description: 'Форма создания и редактирования посылок',
            props: [
                {name: 'data', type: 'object', description: 'Данные о посылке если происходит редактирование'},
                {name: 'isEdit', type: 'boolean', description: 'Флаг для понимания какой режим выбирать для формы'},
            ],
            methods: [
                {
                    name: 'getListParcels',
                    type: 'function (return Promise)',
                    description: 'Метод для обновления списка посылок'
                },
            ],
        },
        {
            title: 'Print Settings Form',
            description: 'Форма печати посылок в лейблы',
            props: [],
            methods: [],
        },
        {
            title: 'Profile Form',
            description: 'Форма редактирования информации профиля',
            props: [],
            methods: [],
        },
        {
            title: 'Warehouse Form',
            description: 'Форма отправки новых посылок на склад',
            props: [
                {name: 'count', type: 'number', description: 'Количество выбранных посылок на странице'},
            ],
            methods: [],
        },
        {
            title: '',
            description: '',
            props: [],
            methods: [],
        },
    ];

    return (
        <div className={'container h-100 overflow-auto'}>
            {
                list.map((value, key) =>
                    <div key={key}>
                        <div className={'row'}>
                            <div className={'col-12'}>
                                <div className="v-card v-sheet theme--light">
                                    <div className="v-card__title ">{value.title}</div>
                                    <div className="v-card__subtitle ">
                                        <strong className="">{value.description}</strong>
                                    </div>
                                    <div className="v-card__text ">
                                        <div className="v-card__subtitle "><span
                                            className="v-chip theme--light v-size--default secondary"><span
                                            className="v-chip__content">Props</span></span>
                                            {
                                                value.props.length
                                                    ?
                                                    <>
                                                        <br/>
                                                        <hr role="separator" aria-orientation="horizontal"
                                                            className="v-divider theme--light"/>
                                                        <div className="row">
                                                            <div className="col col-4"><strong>Name</strong></div>
                                                            <div className="col col-4"><strong>Type</strong></div>
                                                            <div className="col col-4"><strong>Description</strong></div>
                                                        </div>
                                                        <hr role="separator" aria-orientation="horizontal" className="v-divider theme--light"/>
                                                        <br/>
                                                        {
                                                            value.props.map((v, k) =>
                                                                <div key={k} className="row pt-3 pb-3">
                                                                    <div className="col col-4 cursor-hover">
                                                                        <span>{v.name}</span></div>
                                                                    <div className="col col-4">
                                                                        <span>{v.type}</span>
                                                                    </div>
                                                                    <div className="col col-4"><span>{v.description}</span>
                                                                    </div>
                                                                </div>
                                                            )
                                                        }
                                                    </>
                                                    : <p className="">---Empty---</p>
                                            }
                                        </div>
                                        <hr role="separator" aria-orientation="horizontal" className="v-divider theme--light"/>
                                        <div className="v-card__subtitle "><span
                                            className="v-chip theme--light v-size--default info"><span
                                            className="v-chip__content ">Methods</span></span>
                                            {
                                                value.methods.length
                                                    ?
                                                    <>
                                                        <br/>
                                                        <hr role="separator" aria-orientation="horizontal"
                                                            className="v-divider theme--light"/>
                                                        <div className="row">
                                                            <div className="col col-4"><strong>Name</strong></div>
                                                            <div className="col col-4"><strong>Type</strong></div>
                                                            <div className="col col-4"><strong>Description</strong></div>
                                                        </div>
                                                        <hr role="separator" aria-orientation="horizontal" className="v-divider theme--light"/>
                                                        <br/>
                                                        {
                                                            value.methods.map((v, k) =>
                                                                <div key={k} className="row pt-3 pb-3">
                                                                    <div className="col col-4 cursor-hover">
                                                                        <span>{v.name}</span></div>
                                                                    <div className="col col-4">
                                                                        <span>{v.type}</span>
                                                                    </div>
                                                                    <div className="col col-4"><span>{v.description}</span>
                                                                    </div>
                                                                </div>
                                                            )
                                                        }
                                                    </>
                                                    : <p className="">---Empty---</p>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <br/>
                    </div>
                )
            }
        </div>
    );
};

export default Components;
