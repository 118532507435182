
import { useDispatch }      from "react-redux";
import { useRoutes }        from "react-router-dom";
import i18n                 from "i18next";
import { initReactI18next } from "react-i18next";

import 'bootstrap/dist/css/bootstrap.min.css';

import routes               from "./routes";
import lang                 from "./resources/lang"
import { getCookie }        from "./utils/cookie";
import { setLocale }        from "./storage/global";

import './_styles/css/index.css'
import './_styles/scss/index.scss'
import './_styles/fontawesome-free-5.15.4-web/css/all.min.css'

const currentLocale = getCookie('language') ?? 'en'

i18n
    .use(initReactI18next)
    .init({
        resources: {
            en: {
                translation: lang.en
            },
            cn: {
                translation: lang.cn
            }
        },
        lng: currentLocale,
        fallbackLng: "en",
        interpolation: {
            escapeValue: false // react already safes from xss =>
                               // https://www.i18next.com/translation-function/interpolation#unescape
        }
    })

const Main = () => {

    const dispatch = useDispatch();
    dispatch(setLocale(currentLocale))

    return useRoutes(routes);
}

export default Main;
