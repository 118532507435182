import React, { useState, useCallback } from "react";
import {useDropzone} from 'react-dropzone'

import sprite from "../../../icons/sprite.svg";
import { IconTrash } from "../../../icons/IconTrash";

import "./style.scss";

export const Upload = ({
  text = "Supported Formats: JPG, PNG",
  value = {},
  saveToRedux,
  removeImg,
}: any) => {

  const onDrop = useCallback(acceptedFiles => {
    // Do something with the files
    fileReader(acceptedFiles);
  }, [])

  function fileReader(acceptedFiles) {
    const reader = new FileReader();
    const file = acceptedFiles[0];
    reader.readAsDataURL(file);
    reader.onerror = error => console.log(error);

    // compression by canvas element in browser
    reader.onload = (event) => {
        const img = new Image();
        img.src = event.target?.result;
        if(img.complete){
          load();
        } else {
          img.onload = () => {
            load();
          }
        }
        async function load () {
            const elem = document.createElement('canvas');
            elem.width = img.width;
            elem.height = img.height;
            const ctx = elem.getContext('2d');
            ctx?.drawImage(img, 0, 0, img.width, img.height);

            let k = 1
            while (k > 0.25) {
                const newFile: any = await makeSmaller(ctx, file, k);
                if (newFile?.size < 3700000) {
                  reader.readAsDataURL(newFile);
                  reader.onload = (event) => {
                      event && saveToRedux && saveToRedux(event?.target?.result, newFile);
                    }
                    k = 0;
                } else {
                    k -= 0.1; // make more compression
                }
            }
        }
    };
  }

  function makeSmaller(ctx, file, quality) {
      return new Promise((resolve) => {
          ctx.canvas.toBlob((blob) => {
              resolve(new File([blob], file.name, {
                  type: file.type,
                  lastModified: Date.now()
              }));
          }, file.type, quality);
      });
  }

  const {getRootProps, getInputProps, isDragActive} = useDropzone({onDrop})

  return (
    <div className="upload">
      <div className="">
        {/* блок додавання файла */}
        <div className="area" {...getRootProps()} >
          <input
            {...getInputProps()} 
            className={'upload-input'}
            type="file"
            accept="image/png, image/jpeg, image/gif"
            style={{ display: "none" }}
          />
          <svg width="32" height="32" className="icon-upload">
            <use href={sprite + "#icon-upload"}></use>
          </svg>
          <div className="frame-help">
            <div className="frame-help-text">
              Click or Drag file here to Upload
            </div>
            <div className="supported-formats">{text}</div>
          </div>
        </div>
        {value.image_data && (
          <div className="file">
            <div className="text">{value.image_name}</div>
            <div
            className={''}
            onClick={() => removeImg && removeImg()}
            >
                <div className="container">
                    <div className="">
                    {<IconTrash className="button" color="#3749E5" />}
                    </div>
                </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};