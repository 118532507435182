import React, { useState, useEffect }                                      from 'react';
import { useSelector }                                          from "react-redux";
import { useNavigate, Outlet }                                  from "react-router-dom";
import { useTranslation }                                       from "react-i18next";

import { routesNames } from "../../constants";

const AdminRequestPanel = () => {

    const navigate = useNavigate();
    const {t} = useTranslation();

    const currentPath = document.location.pathname
    const tabs = [
        {
            name: 'SpeedOfRequestList',
            path: routesNames.REQUEST_SETTINGS_LIST
        },
        {
            name: 'SpeedOfRequestStatistic',
            path: routesNames.REQUEST_SETTINGS_STATISTIC
        },
        {
            name: 'EmailsForWarnings',
            path: routesNames.EMAILS_FOR_WARNINGS
        },
        {
            name: 'QueryByDate',
            path: routesNames.QUERY_BY_DATE
        },
        {
            name: 'UserMetricks',
            path: routesNames.USER_METRICKS
        },
    ];

    const [activeTab, setActiveTab] = useState(tabs.find(tab => currentPath.includes(tab.path)) ?? tabs[0]);

    function goTo(nameTab) {
        setActiveTab(nameTab)
        navigate(`/${nameTab.path}`)
    }

    useEffect(() => {
        if (currentPath === '/' + routesNames.REQUEST_SETTINGS)
            navigate('/' + routesNames.REQUEST_SETTINGS_LIST)
    }, [])

    return (
        <div className={'container-fluid overflow-auto height-sub-tabs'}>
            <div className='tabs'>
                {tabs.map((nameTab: string) => (
                    <div
                        key={nameTab.name}
                        className={`tab ${activeTab.name === nameTab.name ? 'select' : ''}`}
                        onClick={_ => goTo(nameTab)}
                    >
                        {t(nameTab.name)}
                    </div>
                ))}
            </div>
            <Outlet/>
        </div>
    );
};

export default AdminRequestPanel;
