import React, {useMemo} from 'react';
import {useSelector, useDispatch} from "react-redux";
import {t} from "i18next";

import localStyles from './cu.module.scss'
import {copy, downloadWeChatQR} from "../../utils/misc";
import { setModalForm, setShowMenu } from "../../storage/global";

import { makeGetRequest }    from "../../utils/api";

const ContactUs = ({isSmall}) => {
    const dispatch = useDispatch();

    function openQR() {
        if(isSmall) {
            // document.location.href = 'http://weixin.qq.com/r/ahMJERvEwEa-rV9y90Z4';
            downloadWeChatQR();
        } else {
            makeGetRequest("/api/misc/showContactUsQR");
            dispatch(setShowMenu(false))
    
            dispatch(setModalForm({
                title: t('scan_QR'),
                form: <img className={'mx-auto'} src={`/assets/img/WeChatQR.jpg`} alt=""/>,
                formContent: {classNames: 'modal-form-content-profile'},
                formBody: {classNames: 'd-flex mb-30'},
                styles: {background: 'rgba(0, 0, 0, 0.9)'},
            }))
        }
    }

    return (
        <div className={localStyles.container}
             onClick={openQR}
        >
            <button className={localStyles.contactButton}
                    type={'button'}
            >
                {t('contact_us')}
                <img className={'ml-1'} src={`/assets/img/support.png`} alt="" width="16px"/>
            </button>
        </div>
    );
};

export default ContactUs;
