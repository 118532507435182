
import React, { }        from 'react';

import CheckBox       from "../input/CheckBox";

const CheckboxLine = ({checkState, checkTitle, checkId, chengeState, removeCheck}) => {
    return (
        <div className="px-2 ml-5">
            <CheckBox
                className={'check-input d-inline m-1'}
                styles={{fontSize: '20px'}}
                handle={(value, id) => chengeState(id, value)}
                id={checkId}
                value={checkState}
                label={checkTitle}
            />
            <i className="fa fa-times m-1" onClick={ _ => removeCheck(checkId)}></i>
        </div>
    );
};

export default CheckboxLine;