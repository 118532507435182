
import React    from 'react';

const ButtonAdmin = ({onClick, text, buttonClasses, iconClasses, disabled, style = {}}) => {

    function _onClick() {
        if (typeof onClick === 'function') {
            onClick();
        }
    }

    return (
        <button
            className={`btn btn-simple ${buttonClasses}`}
            onClick={_onClick}
            disabled={disabled ?? false}
            style={style}
        >
            <i className={`fas ${iconClasses}`} />
            {text}
        </button>
    );
};

export default ButtonAdmin;
