
import React, { memo }          from 'react';
import { useTranslation }       from "react-i18next";
import { useSelector }          from "react-redux";

import СreateNewParcel              from "./СreateNewParcel";
import RefuseButton       from "./RefuseButton";
import InfoWithIcon       from "./InfoWithIcon";

const ActionBlock = (props) => {

    const {t} = useTranslation();

    return (
        <>
            {
                <div className={'page-actions justify-content-between'}>
                    <div className={'d-flex justify-content-start align-items-center mr-3 ws-nowrap'}>
                        <СreateNewParcel handle={props.handleCreate} className={'ml-5'} buttClassNames={""}/>
                        <RefuseButton handle={props.handleRefuse} className={'ml-5'}/>
                        <InfoWithIcon className={'ml-5'} text={t('how_is_this_section_working')} info={t('how_is_this_section_working_info')}/>
                    </div>
                </div>
            }
        </>
    )
};

export default memo(ActionBlock);
