import React         from 'react';
import { useDispatch, useSelector }  from "react-redux";
import { useTranslation }           from "react-i18next";

import HelpVideo     from "./HelpVideo";
import ExcelTemplateAndImport from "./ExcelTemplateAndImport";
import BalanceButton from "./BalanceButton";
import NotifBtn      from "./NotifBtn";
import LangBlock     from "../shared/LangBlock";
import ProfileButton from "./ProfileButton";
import LogoutButton  from "./LogoutButton";
import WriteReport   from "./WriteReport";
import GoToSite      from "./GoToSite";
import ButtonSmallNoBorder   from "../shared/ButtonSmallNoBorder";
import { setModalForm, setShowMenu } from "../../storage/global";

const HeaderLarge = ({className}) => {
    const {t, i18n} = useTranslation();
    const dispatch = useDispatch();
    return (
        <div className={`row no-gutters ${className}`}>
            <div className={'d-flex align-items-center justify-content-start col-auto'}>
                <HelpVideo className={'mr-2'}/>
                <ExcelTemplateAndImport className={'excel-template-import'}/>
            </div>
            <div className={"d-flex align-items-center justify-content-end col"}>
                <GoToSite classNameContainer={'mr-08vw'}/>
                <WriteReport classNameContainer={'d-flex align-items-center mr-08vw'} underText={t('Feedback')}/>
                <BalanceButton isLargeHeader={true}/>
                <NotifBtn classNameContainer={'ml-08vw'}/>
                <LangBlock className={'fs-13px ml-08vw'}/>
                <ProfileButton className={'cursor-pointer h28 ml-08vw'} classNameCenter={'mt--10px'}/>
                <LogoutButton className={'ml-2'}/>
            </div>
        </div>
    );
};

export default HeaderLarge;
