import React          from 'react';
import { Fade, Form } from "react-bootstrap";
import PropTypes      from "prop-types";

const InputSelectErrorBlock = ({show, errorMessage, styles, smapClasses, title}) => {
    return (
        <Fade
            in={!!show}
        >
            <Form.Text
                style={{...styles}}
                className={`error-input ${show ? 'visibility-visible' : 'visibility-visible'} ${smapClasses}`}
                title={title}
            >
                {errorMessage}
            </Form.Text>
        </Fade>
    );
};

InputSelectErrorBlock.prototype = {
    isError: PropTypes.bool,
    errorMessage: PropTypes.string,
}

export default InputSelectErrorBlock;
