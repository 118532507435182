
import React          from "react";
import { Navigate }   from "react-router-dom";

import { routesNames } from "../constants";
import RootApp        from "../pages/RootApp";
import NewParcels     from "../pages/new_parcels/NewParcels";
import WaitingArrival from "../pages/waiting_arrival/WaitingArrival";
import SentParcels    from "../pages/sent_parcels/SentParcels";
import UnidentifiedParcels    from "../pages/unidentified_parcels/UnidentifiedParcels";
import PromoCodes     from "../pages/promo_codes/PromoCodes";
import Accounting     from "../pages/accounting/Accounting";
import Docs           from "../pages/docs/Docs";
import Api            from "../pages/docs/Api";
import App            from "../pages/docs/App";
import Components     from "../pages/docs/Components";
import Main           from "../pages/docs/Main";
import admin        from "./admin";

export default [
    {
        path: routesNames.HOME,
        element: <RootApp/>,
        children: [
            {
                index: true,
                element: <Navigate to={routesNames.NEW_PARCELS}/>
            },
            {
                path: routesNames.NEW_PARCELS,
                element: <NewParcels/>
            },
            {
                path: routesNames.WAITING_ARRIVAL,
                element: <WaitingArrival/>
            },
            {
                path: routesNames.SENT_PARCELS,
                element: <SentParcels/>
            },
            {
                path: routesNames.UNIDENTIFIED_PARCELS,
                element: <UnidentifiedParcels/>
            },
            {
                path: routesNames.PROMO_CODES,
                element: <PromoCodes/>
            },
            {
                path: routesNames.ACCOUNTING,
                element: <Accounting/>
            },
            ...admin
        ]
    },
    {
        path: routesNames.HOME + routesNames.DOCS,
        element: <Docs/>,
        children: [
            {
                index: true,
                element: <Main/>
            },
            {
                path: routesNames.API,
                element: <Api/>
            },
            {
                path: routesNames.APP,
                element: <App/>
            },
            {
                path: routesNames.COMPONENTS,
                element: <Components/>
            },
        ]
    },
    {
        path: '*',
        element: <Navigate to={routesNames.HOME}/>
    },
]
