import React, { useMemo } from 'react';
import {useDispatch, useSelector} from "react-redux";
import {useTranslation} from "react-i18next";
import ReactTooltip from "react-tooltip";
import { Button }   from "react-bootstrap";

import ModalButtons from "../shared/modal/ModalButtons";
import {hideModalForm} from "../../utils/misc";
import {
    SET_KEY,
} from "../../storage/global";

const PopUpForTry = () => {

    const {t, i18n} = useTranslation();
    const dispatch = useDispatch();

    const modalForm = useSelector(state => state.global.modalForm)
    const headNotificationBarSettings = useSelector(state => state.global.headNotificationBarSettings)
    const fakeDoorsModalBG = useSelector(state => state.global.fakeDoorsModalBG)

    const {
        popUpText,
        cn_popUpText,
    } = headNotificationBarSettings;
    const textPopUp = i18n.language === 'en' ? popUpText : cn_popUpText;

    const POP_UP_ID = 'POP_UP_FOR_TRY'

    function hide(e) {
        hideModalForm(dispatch)();
        dispatch({type: SET_KEY, payload: {
            key: 'showFakeDoor',
            value: false,
        }})
    }

    const imgSrc = useMemo(() => {
        return fakeDoorsModalBG?.image_data ?
        `data:${fakeDoorsModalBG?.image_type};base64, ` + fakeDoorsModalBG?.image_data
        : '/assets/img/poll-bg-def.png'
    }, [fakeDoorsModalBG])

    return (
        <div className={'h-100 overflow-auto p-relative '}>
            <img
                onClick={hide}
                style={{
                    position: 'absolute',
                    top: 9,
                    right: 9,
                    zIndex: 10,
                }}
                className={'my-modal-close cursor-pointer'}
                src="/assets/img/close-white.svg"
                alt="close"
            />
            <div id={POP_UP_ID}
                className={'row mx-0 pop-up-for-try'}
                width={'100%'}
                style={{
                    background: 'linear-gradient(360deg, #0061AF 0%, rgba(10, 36, 50, 0.2) 100%)',
                }}
            >
                {<img
                    style={{
                        width: '50%',
                        height: '100%',
                        objectFit: 'cover',
                    }}
                    className={'p-0 col-6'}
                    src={imgSrc}
                    alt=""
                />}
                <div className="col-12 col-md-6 d-flex flex-column justify-content-center"
                    style={{
                        padding: '0 40px'
                    }}
                >
                    <div className="mb-4"
                        style={{
                            fontSize: '24px',
                            fontWeight: 400,
                            lineHeight: '30px',
                            textAlign: 'center',
                            color: 'white'
                        }}
                    >
                        {textPopUp}
                    </div>
                    {/* <Button
                        style={{
                            minWidth: '114px',
                            color: 'white',
                            height: '52px',
                        }}
                        className={`rounded-0 p-0 bgcol-figma-accent bordercol-figma-accent`}
                        onClick={hide}
                        variant={'primary'}
                    >
                        {t('Proceed_to_Meest')}
                    </Button> */}
                </div>
            </div>
            <ReactTooltip effect={'solid'} className={'my-tooltip'} delayHide={100}/>
        </div>
    );
};

export default PopUpForTry;
