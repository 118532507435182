import React               from 'react';
import { useTranslation }  from "react-i18next";
import { Button }          from "react-bootstrap";

import ButtonSmallNoBorder from "../ButtonSmallNoBorder";

const AddNewButton = ({className, handle, isBorder}) => {

    const {t} = useTranslation();

    function onClick() {
        if (typeof handle === 'function')
            handle()
    }

    return (
        <div onClick={onClick} className={`${className ? className : ''}`}>
            {
                isBorder
                    ?
                    <Button variant={'outline-primary'}>
                        <img className={'mr-1'} src="/assets/img/plus-circle.svg" alt="new"/>
                        <span>{t('add_new')}</span>
                    </Button>
                    :
                    <ButtonSmallNoBorder>
                        <img className={'mr-1'} src="/assets/img/plus-circle.svg" alt="new"/>
                        <span>{t('add_new')}</span>
                    </ButtonSmallNoBorder>
            }
        </div>
    );
};

export default AddNewButton;
