import React, { useEffect, useState }               from 'react';
import { useDispatch }                              from "react-redux";
import { useTranslation }                           from "react-i18next";
import { Button }                                   from "react-bootstrap";
import moment                                       from "moment/moment";

import { setLoadSpinner }                           from "../../../storage/global";
import { makePostRequest}                           from "../../../utils/api";
import { viewAlert }                                from "../../../utils/misc";
import HeadNotificationBarSettings                  from "./HeadNotificationBarSettings";

const TabRequestByDate = () => {

    const dispatch = useDispatch();
    const {t} = useTranslation();

    function loadExcel() {
        makePostRequest('/api/admin/loadQueryDateStatistic', {
                query: "/api/misc/showContactUsQR"
            },
            {responseType: 'blob'}
        ).then(({data}) => {
            const date = moment();
            const filename = `requests_by_date_${date.format('YYYY-MM-DD_HH_mm')}.xlsx`;
            const blob = new Blob([data], {type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'});
            const url = window.URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', filename ? filename : 'file.xls'); //or any other extension
            document.body.appendChild(link);
            link.click();
        })
        .catch(({response}) => {
            viewAlert(dispatch, response)
        })
        .finally(() => dispatch(setLoadSpinner(false)))
    }

    return (
        <div className={'container-page height-sub-tabs'} style={{overflowY: 'auto'}}>
            <div className={'v-card v-sheet theme--light p-3 d-flex align-items-end flex-column'}>
                <div className="row w-100 m-0 pt-10">
                    <div className="my-col-6 align-self-center">
                        <Button onClick={loadExcel} className={'hmc w-100'}>Скачати статистику Contact Us QR</Button>
                    </div>
                </div>
            </div>
            {/* <div className='height-list-filter-list'>
                <div className='list-of-poll mb-4 d-flex flex-column h-100'>
                    <RequestListByDate
                        uniqueRowId={'uid'}
                        emptyListText={'Пусто'}
                        disableSelect
                        items={items}
                    />
                </div>
            </div> */}
            <HeadNotificationBarSettings/>
        </div>
    );
};


export default TabRequestByDate;
