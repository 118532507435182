import React            from 'react';
import { useDispatch }  from "react-redux";

import { setModalForm } from "../../storage/global";
import VideoInstr       from "../misc/VideoInstr";

const HelpVideo = ({className}) => {

    const dispatch = useDispatch();

    function openVideo() {
        dispatch(setModalForm({
            title: 'Help video',
            form: <VideoInstr/>,
            fullScreen: true
        }))
    }

    return (
        <div>
            <img onClick={openVideo} className={`${className} cursor-pointer`} src="/assets/img/help_video.svg" alt="help-video" height="24px"/>
        </div>
    );
};

export default HelpVideo;
