import React, {useEffect, useMemo, useState} from 'react';
import { Form }           from "react-bootstrap";
import { useTranslation } from "react-i18next";

import {
    ADDRESS,
    CITY, COMMENT,
    COMMODITY,
    CONSIGNEE,
    DATE, EMAIL, HS_CODE, IOSS_NUMBER,
    PARCEL_NUMBER, PASSPORT, PASSPORT_NUMBER, PRICE,
    QTY, REGION,
    SEND_BY, SENSITIVE_GOODS,
    SERVICE_TYPE,
    SKU, TAX_ID_NUMBER, TELEPHONE,
    TO_COUNTRY,
    TRACK_NUMBER,
    TRACKER, URL, WAREHOUSE, WEIGHT, ZIP,
    ASSOCIATED_BARCODES,
}                         from "../../constants";
import Switch             from "../input/Switch";
import InputText          from "../input/InputText";
import { parsePassport }  from "../../utils/misc";
import {getCountryList} from "../../utils/api";

const FORM_ID = 'waiting-arrival-form';

const WaitingArrivalDetailForm = ({data}) => {

    let savedIOSS = false;

    const {t} = useTranslation();
    const formRef = React.createRef();

    const {
        tracknum: trackNumber,
        barcode: parcelNumber,
        sentdate: date,
        reciver: consignee,
        tracker,
        to,
        sensitive_goods,
        ship,
        quantity: qty,
        weight,
        service_type,
        value: price,
        hs_code,
        sku,
        Commodity: commodity,
        url,
        associated_barcodes,
        useMeestIOSS,
        IOSS_Number,
        zip,
        region,
        city,
        address,
        email,
        passport_num,
        recipientPhone: telephone,
        WH: wh,
        coment,
        TaxIdNumber,
    } = data;

    const [listCountriesForTypeDelivery, setListCountriesForTypeDelivery] = useState([])

    useEffect(() => {

        getCountryList().then(({data}) => {
            setListCountriesForTypeDelivery([...(data.filter( el => +el.isEurope) ? data.filter( el => +el.isEurope).map(el=>el.country_id) : []), 'UA'])
        });

    }, [])

    savedIOSS = useMemo(() => {
        return useMeestIOSS === 'true';
    }, [useMeestIOSS])

    const countryWithServiceType = useMemo(() => {
        const present = listCountriesForTypeDelivery.includes(to.country_id);
        return true;
    }, [to])

    const serviceType = useMemo(() => {
        if(service_type === 0) return t('toDoor')
        if(service_type === 1) return t('PUDO')
        return t('error');
    }, [service_type])

    function handleSubmit(e) {
        e.preventDefault();
        e.stopPropagation();
    }

    return (
        <Form ref={formRef} noValidate onSubmit={handleSubmit} className={''}>
            <table id={FORM_ID} width={'100%'}>
                <tbody>

                <tr>
                    <td>
                        <div className={'p-2 add-new-td add-new-td-left-top add-new-td-border-radius-top'}>
                            <p className={'m-0'}>
                                <small>{t('Domestic_express_tracking')}</small>
                                {/*<InfoQuestion className={'p-2 cursor-pointer'} text={t('tooltip_qty')}/>*/}
                            </p>
                            <InputText
                                id={TRACK_NUMBER}
                                value={trackNumber}
                                disabled
                            />
                        </div>
                    </td>
                    <td>
                        <div className={'p-2 add-new-td add-new-td-top'}>
                            <p className={'m-0'}>
                                <small>{t('track_number')}</small>
                                {/*<InfoQuestion className={'p-2 cursor-pointer'} text={t('tooltip_qty')}/>*/}
                            </p>
                            <InputText
                                id={PARCEL_NUMBER}
                                value={parcelNumber}
                                disabled
                            />
                        </div>
                    </td>
                    <td>
                        <div className={'p-2 add-new-td add-new-td-top'}>
                            <p className={'m-0'}>
                                <small>{t('dispatch_date')}</small>
                                {/*<InfoQuestion className={'p-2 cursor-pointer'} text={t('tooltip_qty')}/>*/}
                            </p>
                            <InputText
                                id={DATE}
                                value={date}
                                disabled
                            />
                        </div>
                    </td>
                    <td>
                        <div className={'p-2 add-new-td add-new-td-top'}>
                            <p className={'m-0'}>
                                <small>{t('tax_id_number')}</small>
                            </p>
                            <InputText
                                id={TAX_ID_NUMBER}
                                value={Array.isArray(TaxIdNumber) ? '' : TaxIdNumber}
                                disabled
                            />
                        </div>
                    </td>
                    <td>
                        <div className={'p-2 add-new-td add-new-td-right-top add-new-td-border-radius-right'}>
                            <p className={'m-0'}>
                                <small>{t('commodity')}</small>
                                {/*<InfoQuestion className={'p-2 cursor-pointer'} text={t('tooltip_qty')}/>*/}
                            </p>
                            <InputText
                                id={COMMODITY}
                                value={commodity}
                                disabled
                            />
                        </div>
                    </td>
                </tr>
                <tr>
                    <td>
                        <div className={'p-2 add-new-td add-new-td-left'}>
                            <p className={'m-0'}>
                                <small>{t('dest_country')}</small>
                                {/*<InfoQuestion className={'p-2 cursor-pointer'} text={t('tooltip_qty')}/>*/}
                            </p>
                            <InputText
                                id={TO_COUNTRY}
                                value={to}
                                disabled
                            />
                        </div>
                    </td>
                    <td>
                        <div className={'p-2 add-new-td'}>
                            <p className={'m-0'}>
                                <small>{t('receiver_name')}</small>
                            </p>
                            <InputText
                                id={CONSIGNEE}
                                value={consignee}
                                disabled
                            />
                        </div>
                    </td>
                    <td>
                        <div className={'p-2 add-new-td'}>
                            <p className={'m-0'}>
                                <small>{t('ship')}</small>
                                {/*<InfoQuestion className={'p-2 cursor-pointer'} text={t('tooltip_qty')}/>*/}
                            </p>
                            <InputText
                                id={SEND_BY}
                                value={ship}
                                disabled
                            />
                        </div>
                    </td>
                    {
                        countryWithServiceType ?
                            <td colSpan={1}>
                                <div className={'p-2 add-new-td'}>
                                    <p className={'m-0'}>
                                        <small>{t('TypeDelivery')}</small>
                                        <InputText
                                            id={SERVICE_TYPE}
                                            value={serviceType}
                                            disabled
                                        />
                                    </p>
                                </div>
                            </td>
                            : null
                    }
                    <td colSpan={countryWithServiceType ? 1 : 2}>
                        <div className={'p-2 add-new-td add-new-td-right'}>
                            <p className={'m-0'}>
                                <small>{t('tracking')}</small>
                                {/*<InfoQuestion className={'p-2 cursor-pointer'} text={t('tooltip_qty')}/>*/}
                            </p>
                            <InputText
                                id={TRACKER}
                                value={tracker}
                                disabled
                            />
                        </div>
                    </td>
                </tr>
                <tr>
                    <td>
                        <div className={'p-2 add-new-td add-new-td-left'}>
                            <p className={'m-0'}>
                                <small>{t('goods_type')}</small>
                                {/*<InfoQuestion className={'p-2 cursor-pointer'} text={t('tooltip_qty')}/>*/}
                            </p>
                            <InputText
                                id={SENSITIVE_GOODS}
                                value={sensitive_goods}
                                disabled
                            />
                        </div>
                    </td>
                    <td>
                        <div className={'p-2 add-new-td'}>
                            <p className={'m-0'}>
                                <small>{t('hs_code')}</small>
                            </p>
                            <InputText
                                id={HS_CODE}
                                value={hs_code}
                                disabled
                            />
                        </div>
                    </td>
                    <td>
                        <div className={'p-2 add-new-td'}>
                            <p className={'m-0'}>
                                <small>{t('sku_code')}</small>
                                {/*<InfoQuestion className={'p-2 cursor-pointer'} text={t('tooltip_qty')}/>*/}
                            </p>
                            <InputText
                                id={SKU}
                                value={sku}
                                disabled
                            />
                        </div>
                    </td>
                    <td colSpan={2}>
                        <div className={'p-2 add-new-td add-new-td-right'}>
                            <p className={'m-0'}>
                                <small>{t('receiver_email')}</small>
                                {/*<InfoQuestion className={'p-2 cursor-pointer'} text={t('tooltip_qty')}/>*/}
                            </p>
                            <InputText
                                id={EMAIL}
                                value={email}
                                disabled
                            />
                        </div>
                    </td>
                </tr>
                <tr>
                    <td>
                        <div className={'p-2 add-new-td add-new-td-left'}>
                            <p className={'m-0'}>
                                <small>{t('zip')}</small>
                                {/*<InfoQuestion className={'p-2 cursor-pointer'} text={t('tooltip_qty')}/>*/}
                            </p>
                            <InputText
                                id={ZIP}
                                value={zip}
                                disabled
                            />
                        </div>
                    </td>
                    <td>
                        <div className={'p-2 add-new-td'}>
                            <p className={'m-0'}>
                                <small>{t('region')}</small>
                            </p>
                            <InputText
                                id={REGION}
                                value={region}
                                disabled
                            />
                        </div>
                    </td>
                    <td>
                        <div className={'p-2 add-new-td'}>
                            <p className={'m-0'}>
                                <small>{t('city')}</small>
                                {/*<InfoQuestion className={'p-2 cursor-pointer'} text={t('tooltip_qty')}/>*/}
                            </p>
                            <InputText
                                id={CITY}
                                value={city}
                                disabled
                            />
                        </div>
                    </td>
                    {
                        associated_barcodes
                            ?
                            <td colSpan={1}>
                                <div className={'p-2 add-new-td'}>
                                    <p className={'m-0'}>
                                        <small>{t('last_mile_track_number')}</small>
                                    </p>
                                    <InputText
                                        id={ASSOCIATED_BARCODES}
                                        value={associated_barcodes.join(', ')}
                                        disabled
                                    />
                                </div>
                            </td>
                            : null
                    }
                    <td colSpan={associated_barcodes ? 1 : 2}>
                        <div className={'p-2 add-new-td'}>
                            <p className={'m-0'}>
                                <small>{t('web_link')}</small>
                                {/*<InfoQuestion className={'p-2 cursor-pointer'} text={t('tooltip_qty')}/>*/}
                            </p>
                            <InputText
                                id={URL}
                                value={url}
                                disabled
                            />
                        </div>
                    </td>
                </tr>
                <tr>
                    <td>
                        {
                            savedIOSS
                                ?
                                <div
                                    className={'p-2 add-new-td add-new-td-left d-flex align-items-center'}
                                    style={{height: '82px'}}
                                >
                                    <Switch
                                        id={'ioss_switch'}
                                        defaultValue={savedIOSS}
                                        labelText={t('infoIOSS')}
                                        disabled
                                    />
                                </div>
                                :
                                <div className={'p-2 add-new-td add-new-td-left'}>
                                    <p className={'m-0'}>
                                        <small>{t('ioss_number')}</small>
                                        {/*<InfoQuestion className={'p-2 cursor-pointer'} text={t('tooltip_qty')}/>*/}
                                    </p>
                                    <InputText
                                        id={IOSS_NUMBER}
                                        value={IOSS_Number}
                                        disabled
                                    />
                                </div>
                        }
                    </td>
                    <td>
                        <div className={'p-2 add-new-td'}>
                            <p className={'m-0'}>
                                <small>{t('passport_series')}</small>
                                {/*<InfoQuestion className={'p-2 cursor-pointer'} text={t('tooltip_qty')}/>*/}
                            </p>
                            <InputText
                                id={PASSPORT}
                                value={parsePassport(passport_num).series}
                                disabled
                            />
                        </div>
                    </td>
                    <td>
                        <div className={'p-2 add-new-td'}>
                            <p className={'m-0'}>
                                <small>{t('passport_number')}</small>
                            </p>
                            <InputText
                                id={PASSPORT_NUMBER}
                                value={parsePassport(passport_num).number}
                                disabled
                            />
                        </div>
                    </td>
                    <td>
                        <div className={'p-2 add-new-td'}>
                            <p className={'m-0'}>
                                <small>{t('street')}</small>
                                {/*<InfoQuestion className={'p-2 cursor-pointer'} text={t('tooltip_qty')}/>*/}
                            </p>
                            <InputText
                                id={ADDRESS}
                                value={address}
                                disabled
                            />
                        </div>
                    </td>
                    <td>
                        <div className={'p-2 add-new-td add-new-td-right'}>
                            <p className={'m-0'}>
                                <small>{t('comments')}</small>
                                {/*<InfoQuestion className={'p-2 cursor-pointer'} text={t('tooltip_qty')}/>*/}
                            </p>
                            <InputText
                                id={COMMENT}
                                value={coment}
                                disabled
                            />
                        </div>
                    </td>
                </tr>
                <tr>
                    <td>
                        <div
                            className={'p-2 pb-4 add-new-td add-new-td-left add-new-td-bottom add-new-td-border-radius-left'}>
                            <p className={'m-0'}>
                                <small>{t('receiver_phone')}</small>
                            </p>
                            <InputText
                                id={TELEPHONE}
                                value={telephone}
                                disabled
                            />
                        </div>
                    </td>
                    <td>
                        <div className={'p-2 pb-4 add-new-td add-new-td-bottom'}>
                            <p className={'m-0'}>
                                <small>{t('wh_short')}</small>
                            </p>
                            <InputText
                                id={WAREHOUSE}
                                value={wh}
                                disabled
                            />
                        </div>
                    </td>
                    <td>
                        <div className={'p-2 pb-4 add-new-td add-new-td-bottom'}>
                            <p className={'m-0'}>
                                <small>{t('qty_2')}</small>
                            </p>
                            <InputText
                                id={QTY}
                                value={qty}
                                disabled
                            />
                        </div>
                    </td>
                    <td>
                        <div className={'p-2 pb-4 add-new-td add-new-td-bottom'}>
                            <p className={'m-0'}>
                                <small>{t('weight')}</small>
                            </p>
                            <InputText
                                id={WEIGHT}
                                value={weight}
                                disabled
                            />
                        </div>
                    </td>
                    <td>
                        <div className={'p-2 pb-4 add-new-td add-new-td-right-bottom add-new-td-border-radius-bottom'}>
                            <p className={'m-0'}>
                                <small>{t('value')}</small>
                            </p>
                            <InputText
                                id={PRICE}
                                value={price}
                                disabled
                            />
                        </div>
                    </td>
                </tr>
                </tbody>
            </table>
        </Form>
    );
};

export default WaitingArrivalDetailForm;
