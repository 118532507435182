import React        from 'react';

const HeadList = ({children, className}) => {
    return (
        <div className={`${className ? className : ''} page-header`}>
            <div className={'container-fluid p-0'}>
                <div className={'row g-0'}>
                    <div className={'my-col-24 d-flex align-items-center'}>
                        <div className={'container-fluid p-0'}>
                            <div className={'row g-0'}>
                                {children}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default HeadList;
