import { createStore, combineReducers, applyMiddleware } from 'redux'
import { composeWithDevTools }                           from "redux-devtools-extension";
import globalReducer                                     from "./global";
import thunk                                             from "redux-thunk";

/**
 * Logs all actions and states after they are dispatched.
 */
 const logger = store => next => action => {
    // console.group(action.type)
    // console.info('dispatching', action)
    // console.log('next state', store.getState())
    // console.groupEnd(action.type)
    return next(action)
}

const rootReducer = combineReducers({
    global: globalReducer,
})

const middleware = process.env.NODE_ENV === 'production' ? applyMiddleware(thunk) : applyMiddleware(thunk, logger);

export const store = createStore(rootReducer, composeWithDevTools(middleware))
