import React, { useEffect, useState, useMemo }          from 'react';
import { useDispatch }                                  from "react-redux";
import { Button }                                       from "react-bootstrap";
import { useTranslation }                               from "react-i18next";

import axios                                            from "../../lib/axios";
import { setLoad, setLoadSpinner }                      from "../../storage/global";
import { getListPoll, deletePoll, clearCach }           from "../../utils/api";
import { viewAlert }                                    from "../../utils/misc";
import PollEditing                                      from "../../components/shared/Polls/PollEditing";
import PagePollListTemplate                             from "../../components/shared/Polls/PagePollListTemplate";
import Select                                           from "../../components/input/Select";
import MyDatePicker                                     from "../../components/shared/datepicker/MyDatePicker";
import ButtonAdmin                                      from "../../components/shared/pages_buttons/ButtonAdmin";


const TabPollSettings = () => {

    const dispatch = useDispatch();
    const {t} = useTranslation();

    let PollsNames = [];
    const defPollTemplates = {
        id: '',
        date_from: null,
        date_to: null,
        active: false,
        require: false,
        name: '',
        questions: [], // defQuestion
        start_screen_en: '',
        start_screen_cn: '',
        end_screen_en: '',
        end_screen_cn: '',
    }

    const [polls, setPolls] = useState({});
    const [existPollState, setExistPollState] = useState(defPollTemplates);
    const [openExistPoll, setOpenExistPoll] = useState(false);
    const [newPollState, setNewPollState] = useState(defPollTemplates);
    const [openNewPoll, setOpenNewPoll] = useState(false);
    const [pollForGetResult, setPollForGetResult] = useState(null);
    const [dateForGetResult, setDateForGetResult] = useState({ date_from: Date.now() - 1000*60*60, date_to: Date.now()});

    useEffect(() => {
        dispatch(setLoad(false))
        getPolls()
    }, [])

    PollsNames = useMemo(() => {
        return Object.keys(polls).map( id => ({name: polls[id].name, id: id}));
    }, [polls])

    function getPolls(withOk) {
        dispatch(setLoadSpinner(true))
        getListPoll().then(response => {
                const newPolls = {};
                let _pollForGetResult = null
                response.data.forEach( (poll, i) => {
                    if (i === 0) _pollForGetResult = poll.id;
                    const {
                        id,
                        date_from,
                        date_to,
                        active,
                        require,
                        name,
                        questions,
                        image,
                        start_screen_en,
                        start_screen_cn,
                        end_screen_en,
                        end_screen_cn,
                    } = poll;
                    const newPoll = {
                        id,
                        date_from,
                        date_to,
                        active,
                        require,
                        name,
                        questions: questions.map( (que) => ({
                            active: que.active,
                            require: que.require,
                            multiple_choice: que.multiple_choice,
                            question: {
                                en: que.en,
                                cn: que.cn
                            },
                            type: que.type,
                            variants: que.variants,
                            minmax: que.minmax,
                            starCount: que.starCount,
                        })),
                        image,
                        start_screen_en,
                        start_screen_cn,
                        end_screen_en,
                        end_screen_cn,
                    }
                    newPolls[poll.id] = newPoll
                })
                setPolls(newPolls)
                setPollForGetResult(_pollForGetResult)
                withOk && viewAlert(dispatch, response)
            })
            .catch(({response}) => {viewAlert(dispatch, response)})
            .finally(() => dispatch(setLoadSpinner(false)))
    }

    function savePoll(poll) {
        dispatch(setLoadSpinner(true))
        const new_poll = poll ?? newPollState;
        const newPoll = {...new_poll, questions: new_poll.questions.map((el) => {
            const { require, multiple_choice, question, type, variants, minmax, starCount } = el;
            return {
                require,
                multiple_choice,
                en: question.en,
                cn: question.cn,
                type: type,
                variants: variants,
                minmax: minmax,
                starCount: starCount,
            }
        })}
        const url = `/api/admin/${poll ? 'updateQuestionTemplate' : 'addQuestionTemplate'}`
        axios[poll ? 'put' : 'post'](url, {template: newPoll})
            .then(response => {
                if(!poll) {
                    setOpenNewPoll(false);
                    setNewPollState(defPollTemplates)
                }
                getPolls(true)
            })
            .catch((err) => {
                viewAlert(dispatch, {status: 500}, {text: err.toString(), type: 'warning'})
            })
            .finally(() => {
                dispatch(setLoadSpinner(false))
            })
    }

    function editPoll(poll) {
        savePoll(existPollState)
    }

    function switchPollActiveState(id) {
        const new_poll = JSON.parse(JSON.stringify(polls[id]));
        new_poll.active = !new_poll.active
        savePoll(new_poll)
    }

    function getAnswersExcel() {
        if (pollForGetResult) {
            const params = !dateForGetResult ? { id_question: pollForGetResult }
                : {
                    id_question: pollForGetResult,
                    date_from: dateForGetResult.date_from,
                    date_to: dateForGetResult.date_to
                }
            axios.get('/api/admin/getAnswersExcel', { params, responseType: 'blob'})
                .then( ({data, status}) => {
                if (status === 200) {
                    const blob = new Blob([data], {type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'});
                    const url = window.URL.createObjectURL(blob);
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', 'file.xlsx'); //or any other extension
                    document.body.appendChild(link);
                    link.click();
                }
            })
            .catch( e => {
                viewAlert(dispatch, e.response, !e.response || e.response.status !== 404 ? null : {
                    text: 'Нема результатів опитування за параметрами запиту (ідентифікатор опитування, період звіту)',
                    type: 'warning'
                })
            });
        }
    }

    const setExistPollStateDeepCopy = (poll) => {
        let new_poll = JSON.parse(JSON.stringify(poll));
        setExistPollState(new_poll);
    };

    const switchPoll = (poll_id: number) => {
        setOpenExistPoll(existPollState && existPollState.id === poll_id ? !openExistPoll : true);
        setExistPollStateDeepCopy({...polls[poll_id]});
        setOpenNewPoll(false);
    };

    const changeExistPoll = (poll) => {
        setExistPollStateDeepCopy({...poll});
    };
    function resetExistPoll(id) {
        setExistPollStateDeepCopy({...polls[id]})
    }
    function deleteExistPoll(id) {
        deletePoll({id}).then(response => {
            getPolls(true)
        })
        .catch(({response}) => viewAlert(dispatch, response))
        .finally(() => {})
    }
    function clearCachPoll(id) {
        clearCach({id}).then(response => {
            viewAlert(dispatch, response)
        })
        .catch(({response}) => viewAlert(dispatch, response))
        .finally(() => {})
    }

    const newPollShow = () => {
        setOpenExistPoll(false);
        setOpenNewPoll(!openNewPoll);
    };
    const setNewPollStateDeepCopy = (poll) => {
        let new_poll = JSON.parse(JSON.stringify(poll));
        setNewPollState(new_poll);
    };
    const changeNewPoll = (updPoll) => {
        setNewPollStateDeepCopy({...updPoll});
    };
    function resetNewPoll() {
        setNewPollStateDeepCopy(defPollTemplates)
    }

    const changePollForGetResult = (id) => {
        setPollForGetResult(id);
    };

    function handleDatePicker(dateObject) {
        const date = {
            date_from: Date.now(dateObject.firstDate.format('YYYY.MM.DD')),
            date_to: Date.now(dateObject.secondDate.format('YYYY.MM.DD'))
        }
        setDateForGetResult(date)
    }

    return (
        <div className={'container-page'}>
            <div className={'v-card v-sheet theme--light p-3 d-flex align-items-end flex-column'}>
                <div className="row w-100 m-0 pt-10">
                    <MyDatePicker
                        handle={handleDatePicker}
                        startEnd
                        className={'d-flex col-6 px-0'}
                        stylesFirstDate={{marginRight: '15px'}}
                        classNameFirstDate={'mr-3'}
                        textFirst={"З дати"}
                        textSecond={"До дати"}
                        colFirst={['auto', 'auto']}
                        colSecond={['auto', 'auto']}
                        TitleCClassNames={"pl-15 "}
                        TitleClassNames={"lh-0"}
                        DPCClassNames={"mtb-9 minw-100"}
                    />
                    <Select
                        id={'Select_Poll'}
                        classNameContainer={"col-3 align-self-center"}
                        required={true}
                        options={Object.keys(polls).map( id => ({value: id.toString(), name: polls[id].name}))}
                        selectValue={pollForGetResult && polls[pollForGetResult] ? {value: pollForGetResult.toString(), name: polls[pollForGetResult].name} : {}}
                        nameValue={'name'}
                        keyName={'value'}
                        handleChange={ (el, id) => changePollForGetResult(el.value) }
                        errorMessage={''}
                        validations={{}}
                        loaded={false}
                    />
                    <Button disabled={!pollForGetResult} onClick={getAnswersExcel} className={'col-3 hmc align-self-center'}>Результати опитування</Button>
                </div>
            </div>
            <div>
                <div className='list-of-poll mb-4 d-flex flex-column'>
                    <PagePollListTemplate
                        uniqueRowId={'uid'}
                        emptyListText={t('noQuestionTemplates')}
                        disableSelect
                        data={Object.values(polls)}
                        existPollState={existPollState}
                        openPollState={openExistPoll}
                        switchPoll={switchPoll}
                        switchPollActiveState={switchPollActiveState}
                        deletePoll={deleteExistPoll}
                        clearCachPoll={clearCachPoll}
                    />
                    { !existPollState || !openExistPoll ? null :
                        <PollEditing poll={existPollState} pollsNames={PollsNames} changePoll={changeExistPoll} savePoll={ _ => editPoll()} resetPoll={ _ => resetExistPoll(existPollState.id)}/>
                    }

                    <ButtonAdmin
                        onClick={newPollShow}
                        text={openNewPoll ? "Приховати" : "Новий опитувальник"}
                        buttonClasses={`my-2 mx-3 wfc col-bg-add`}
                        iconClasses={openNewPoll ? "mr-1 fa-arrow-up" : "mr-1 fa-plus"}
                        disabled={false}
                        key={"createNewPollBTN"}/>
                    {!openNewPoll ? null :
                        <PollEditing poll={newPollState} pollsNames={PollsNames} changePoll={changeNewPoll} savePoll={ _ => savePoll()} resetPoll={resetNewPoll}/>
                    }
                </div>
            </div>
        </div>
    );
};

export default TabPollSettings;

export const typesAnswer = [
    {
        value: "0",
        name: 'Строка тексту'
    },
    {
        value: "1",
        name: 'Випадаючий список'
    },
    {
        value: "2",
        name: 'Шкала балів'
    },
    {
        value: "3",
        name: 'Оцінка зірками'
    },
]
