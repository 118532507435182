import React, {useEffect, useState} from 'react';
import {useDispatch} from "react-redux";
import i18n from "i18next";
import {Button} from "react-bootstrap";

import axios from "../../lib/axios";
import {setLoadSpinner} from "../../storage/global";
import { Close } from "./TabRequestSettings/TabRequestStatisticNotif";
import InputText from "../../components/input/InputText";
import uniqueArray from "../../utils/uniqueArray";
import { viewAlert } from "../../utils/misc";
import {
    getNotificationEmailsMemoryLimit,
    saveNotificationEmailsMemoryLimit,
    saveMemoryLimits,
    getMemoryLimits
} from "../../utils/api";

const NOTIFICATION_EMAIL = 'notification-memory-limit'
const NOTIFICATION_CLEAN = 'memory-clean'
const NOTIFICATION_NOTIF = 'memory-notif'

const TabListLogs = () => {

    const dispatch = useDispatch();

    const [list, setList] = useState([]);

    const [emailsMemoryLimits, setEmailsMemoryLimits] = useState({
        memoryLimitEmails: [],
        rootMemoryLimitEmails: []
    })
    const [memoryLimits, setMemoryLimits] = useState({
        cleanLimit: [],
        notifLimit: [],
        rootCleanLimit: [],
        rootNotifLimit: [],
    })
    const [world, setWorld] = useState('');

    useEffect(() => {
        (async () => {
            dispatch(setLoadSpinner(true))
    
            await getListLogs();
            getMemoryLimitEmails();
        })();
    }, [])

    async function getListLogs() {
        return axios.get('/api/admin/list_logs')
            .then(({data}) => {
                // console.log("🚀 list_logs:", data);
                setList(data)
            })
            .finally(() => {
                dispatch(setLoadSpinner(false))
            })
    }

    async function getMemoryLimitEmails() {
        return getNotificationEmailsMemoryLimit()
            .then(response => {
                setEmailsMemoryLimits({memoryLimitEmails: [...response.data], rootMemoryLimitEmails: [...response.data]});
                return getMemoryLimits();
            })
            .then(responseLimits => {
                const resObj = JSON.parse(responseLimits.data);
                setMemoryLimits({...resObj, rootCleanLimit: resObj.cleanLimit, rootNotifLimit: resObj.notifLimit});
            })
            .catch(({response}) => {viewAlert(dispatch, response)})
            .finally(() => dispatch(setLoadSpinner(false)))
    }

    function handleValues(value, name, inputRef) {
        switch (name) {
            case NOTIFICATION_EMAIL:
                setWorld(value)
                break;
            case NOTIFICATION_CLEAN:
                setMemoryLimits({
                    ...memoryLimits,
                    cleanLimit: value
                })
                break;
            case NOTIFICATION_NOTIF:
                setMemoryLimits({
                    ...memoryLimits,
                    notifLimit: value
                })
                break;
            default:
                break;
        }
    }

    function reset() {
        setEmailsMemoryLimits({
            rootMemoryLimitEmails: emailsMemoryLimits.rootMemoryLimitEmails,
            memoryLimitEmails: [...emailsMemoryLimits.rootMemoryLimitEmails]
        });
        setMemoryLimits({
            ...memoryLimits,
            cleanLimit: memoryLimits.rootCleanLimit,
            notifLimit: memoryLimits.notifLimit,
        });
    }

    async function saveData() {
        dispatch(setLoadSpinner(true))
        try {
            const responseEmailsMemory = await saveNotificationEmailsMemoryLimit(emailsMemoryLimits);
            viewAlert(dispatch, {responseDangerWorlds: responseEmailsMemory})
            const responseMemoryLimits = await saveMemoryLimits(memoryLimits);
            viewAlert(dispatch, {responseDangerWorlds: responseMemoryLimits})
        } catch(e) {
            viewAlert(dispatch, {"err": e})
        }
        dispatch(setLoadSpinner(false))
    }

    function addNotificationEmails(event) {
        const input = document.getElementById(NOTIFICATION_EMAIL)
        if(input.value.toString()) {
            setEmailsMemoryLimits({
                ...emailsMemoryLimits,
                memoryLimitEmails: uniqueArray([...emailsMemoryLimits.memoryLimitEmails, input.value.toString().toUpperCase()])
            })
            setWorld('')
        }
    }

    function removeNotificationEmails(value) {
        setEmailsMemoryLimits({rootMemoryLimitEmails: emailsMemoryLimits.rootMemoryLimitEmails, memoryLimitEmails: emailsMemoryLimits.memoryLimitEmails.filter(v => v !== value)})
    }

    return (
        <>
            <div className={''}>
                <div className={'mt-3'}>
                    <div className={'mt-3 mt-lg-0 row'}>
                        <div className={'p-3 col-6'} style={{
                            border: '1px solid red',
                            borderRadius: '15px',
                            height: 'auto',
                            maxHeight: '20rem',
                            overflow: "auto",
                            backgroundColor: '#FFF'
                        }}>
                            {
                                emailsMemoryLimits.memoryLimitEmails.map((v, k) => {
                                    return <div key={k} className={'position-relative'} style={{
                                        border: '1px solid blue',
                                        borderRadius: '10px',
                                        padding: '5px',
                                        margin: '5px',
                                        display: 'inline-block'
                                    }}>
                                        <strong>{v}</strong>
                                        <Close onClick={event => removeNotificationEmails(v)}/>
                                    </div>
                                })
                            }
                        </div>
                        <div className="cleanLimit col-3">
                            <InputText handleChange={handleValues} value={memoryLimits.cleanLimit} id={NOTIFICATION_CLEAN}
                                       placeholder={'Ліміт очистки'} closeButton/>
                        </div>
                        <div className="notifLimit col-3">
                            <InputText handleChange={handleValues} value={memoryLimits.notifLimit} id={NOTIFICATION_NOTIF}
                                       placeholder={'Ліміт увідомлень'} closeButton/>
                        </div>
                    </div>
                    <div className={'mt-3'}>
                        <div className={'row'}>
                            <div className={'col'}>
                                <InputText handleChange={handleValues} value={world} id={NOTIFICATION_EMAIL}
                                           placeholder={'Введіть пошту'} closeButton/>
                            </div>
                            <div className={'col-auto'}>
                                <Button onClick={addNotificationEmails}>Добавить</Button>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={'row mt-3'}>
                    <div className={'col-6'}>
                        <Button onClick={reset} className={'w-100'} variant={'outline-primary'}>Скинути</Button>
                    </div>
                    <div className={'col-6'}>
                        <Button onClick={saveData} className={'w-100'} variant={'primary'}>Зберегти</Button>
                    </div>
                </div>
            </div>
            <div className={'container-page mt-3'} style={{
                height: 'calc(100% - 274px)',
                overflowY: 'auto'
            }}>
                <div className={'row'}>
                    <div className={`v-sheet theme--light p-3 pl-25i`}>
                        {list.map((name: string, k) => {
                            return <div key={k}>
                                {name}<br/>
                            </div>
                        })}
                    </div>
                </div>
            </div>
        </>
    );
};

export default TabListLogs;
