

import React, {  }          from 'react';
import { useTranslation }   from "react-i18next";

import { goToSite }     from "../../utils/misc";

const GoToSite = ({isMobile, classNameContainer}) => {

    const {t, i18n} = useTranslation();
    const lang = i18n.language || 'en';

    return (
        <div onClick={() => goToSite(lang)}
             className={`d-flex align-items-center cursor-pointer ${classNameContainer}`}
             style={{
                height: '24px',
             }}
        >
            <div className={''}
                 style={{
                 }}
            >
                <img className={''} src={`/assets/img/globe.png`} alt="new" height={isMobile ? '25px' : '24px'}/>
            </div>
            <span className={'my-text-primary ml-1 fs-13px'}>{t('New_website')}</span>
        </div>
    );
};

export default GoToSite;
