import React            from 'react';
import { useNavigate }  from "react-router-dom";
import PropTypes        from "prop-types";

const LinkOnLoginPage = ({path, text, className}) => {

    const navigate = useNavigate();

    return (
        <a href='#' className={`cursor-pointer`} onClick={() => navigate(path)}>
            <p className={`${className ? className : ''} text-center text-login-page`}>{text}</p>
        </a>
    );
};

LinkOnLoginPage.propTypes = {
    path: PropTypes.string,
    text: PropTypes.string,
}

export default LinkOnLoginPage;
