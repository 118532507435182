import React from 'react';

const ProgressBar = ({completed = 0}) => {

    const containerStyles = {
        height: 20,
        width: '100%',
        backgroundColor: "#e0e0de",
        borderRadius: 50,
      }
    
      const fillerStyles = {
        height: '100%',
        width: `${completed}%`,
        borderRadius: 'inherit',
      }

    return (
        <div style={containerStyles} className="my-1">
            <div style={fillerStyles} className="linear-gradient">
            </div>
        </div>
    );
};

export default ProgressBar;
