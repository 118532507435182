import React, {useRef, useState, useEffect} from 'react';
import {useSelector} from "react-redux";

import localStyles from './styles.module.scss'

const Card = ({
    id,
    children,
    styles,
    className,
    handleOutside,
    boxShadow,
    dataShow,
    onKey: handleKey,
    ...props
}) => {

    const isMobile = useSelector(state => state.global.isMobile);
    const ref = useRef();

    function onKey(event: any) {
        handleKey && handleKey(event)
    }

    const [posArrow, setPosArrow] = useState({top: 0, right: 0,});

    useEffect(() => {
        const parentPos = ref.current.parentElement.getBoundingClientRect();
        const childPos = ref.current.getBoundingClientRect();
        setPosArrow({
            top: childPos.top - parentPos.top,
            right: parentPos.right - childPos.right,
        });
    }, [dataShow, ref]);

    return (
        <div className={`p-relative`}>
            <div id={id} ref={ref}
                 data-show={!!dataShow}
                 className={`${localStyles.container} ${className}`}
                 style={{
                     ...styles,
                 }}
                 data-box-shadow={boxShadow || boxShadow === undefined}
                 {...props}
                 onKeyDownCapture={onKey}
            >
                {children}
            </div>
            <span data-show={!!dataShow && !isMobile} className={localStyles.arrow}></span>
            <span data-show={!!dataShow && !isMobile} className={localStyles.arrow} style={{
                zIndex: 1,
                boxShadow: 'none',
                background: 'linear-gradient(-45deg, transparent, transparent 49%, #fff 50%)',
                borderBottomRightRadius: '20px'
            }}></span>
        </div>
    );
};

export default Card;
