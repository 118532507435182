import React from 'react';

const BurgerMenu = ({className, style, handle}) => {

    return (
        <div onClick={handle} style={{...style}} className={`${className}`}>
            <img src="/assets/img/burger.svg" alt="menu"/>
        </div>
    );
};

export default BurgerMenu;
