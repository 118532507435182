import React                        from 'react';
import { useDispatch, useSelector } from "react-redux";
import { useTranslation }           from "react-i18next";

import ButtonSmallNoBorder from "../ButtonSmallNoBorder";
import { setAlert, setModalForm }   from "../../../storage/global";

const DeleteButton = ({className, handle, deleteFormData, children}) => {

    const {t} = useTranslation();
    const dispatch = useDispatch();

    const list = useSelector(state => state.global.list);

    function onClick(event) {
        const selectedList = list.filter(parcel => parcel.check)

        if (typeof handle === 'function')
            handle(selectedList)

        if (selectedList.length) {
            dispatch(setModalForm(deleteFormData))
        } else {
            dispatch(setAlert({
                text: t('nothingSelected'),
                type: 'warning'
            }))
        }

    }

    return (
        <div onClick={onClick} className={`${className ? className : ''}`}>
            <ButtonSmallNoBorder>
                <img className={'mr-1'} src="/assets/img/trash.svg" alt="delete-button"/>
                {
                    children
                        ? children
                        : <span>{t('delete')}</span>
                }
            </ButtonSmallNoBorder>
        </div>
    );
};

export default DeleteButton;
