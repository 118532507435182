import React                        from 'react';
import { useDispatch, useSelector }  from "react-redux";
import { useTranslation }           from "react-i18next";

import { setModalForm, setShowMenu } from "../../storage/global";
import ProfileForm                   from "../forms/ProfileForm";

const ProfileButton = ({className, classNameCenter}) => {

    const {t} = useTranslation();

    const profile = useSelector(state => state.global.profile)
    const dispatch = useDispatch();

    function openForm() {
        dispatch(setShowMenu(false))

        dispatch(setModalForm({
            title: t('profile'),
            form: <ProfileForm/>,
            formContent: {classNames: 'modal-form-content-profile'},
        }))
    }

    return (
        <div className={className}>
            <div className={`d-flex align-items-center ${classNameCenter}`} onClick={openForm}>
                <img src="/assets/img/profile.svg" alt="profile" title='profile' height="24px"/>
                <div className={'my-text-primary ml-1 fs-13px text-center'}>
                    <span>
                        {profile.username} 
                        <br/>
                        ({profile.usercode})
                    </span>
                </div>
            </div>
        </div>
    );
};

export default ProfileButton;
