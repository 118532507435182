import React from 'react';

const ShowHidePwd = ({onClick, showIcon, passwordTYpe}) => {
    return (
        <div
            onClick={onClick}
            className={`btn-show-hide-pwd cursor-pointer ${showIcon ? 'btn-show-hide-pwd-show' : ''}`}
        >
            <i className={`fa ${passwordTYpe ? 'fa-eye-slash' : 'fa-eye'}`} onClick={null}></i>
        </div>
    );
};

export default ShowHidePwd;
