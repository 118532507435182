import React           from 'react';
import { useDispatch } from "react-redux";
import { useTranslation }           from "react-i18next";

import HelpVideo       from "./HelpVideo";
import Logo            from "../shared/Logo";
import BurgerMenu      from "../shared/BurgerMenu";
import { setShowMenu, setModalForm } from "../../storage/global";
import ButtonAdmin      from "../shared/pages_buttons/ButtonAdmin";
import FeedbackForm     from "../forms/FeedbackForm";
import { goToSite }     from "../../utils/misc";
import WriteReport   from "./WriteReport";

const HeaderSmall = ({className}) => {
    const {t, i18n} = useTranslation();
    const lang = i18n.language || 'en';

    const dispatch = useDispatch();

    function showMenu(e) {
        dispatch(setShowMenu(true))
    }

    return (
        <div className={`d-flex ${className}`}>
            <div className={'col d-flex justify-content-start'}>
                <BurgerMenu className={'cursor-pointer'} handle={showMenu}/>
                <WriteReport classNameContainer={'ml-2 mt-1 d-flex align-items-center'} underText={t('Feedback')} isMobile={true}/>
            </div>
            <Logo className={'col d-flex justify-content-center align-items-center'}
                  goTo={() => goToSite(lang)}
                  styleLogo={{
                      width: '72px'
                  }}
            />
            <div className={'col d-flex justify-content-end'}>
                <HelpVideo className={''}/>
            </div>
        </div>
    );
};

export default HeaderSmall;
