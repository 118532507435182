
import React, { useEffect, useMemo, useState }      from 'react';
import { useTranslation }                           from "react-i18next";
import { useDispatch, useSelector }                 from "react-redux";
import {
    COMMENT,
    DATE,
    DESC, OPERATION, PARCEL_NUMBER,
    SUM, SUM_MINUS,
    SELECTED
}                           from "../../constants";
import {
    setAlertShow,
    setList,
    setLoadSpinner,
    setModalForm,
    setSearch,
    setSelectedList,
    setGroupSearchesCheckboxes
}                           from "../../storage/global";

import ActionBlock          from "./ActionBlock";
import { viewAlert }        from "../../utils/misc";
import { getAccounting, exportAccounting }    from "../../utils/api";
import PageMenu             from "../../components/shared/PageMenu";
import PageListTemplate     from "../../components/shared/PageListTemplate";
import CheckboxList         from "../../components/shared/CheckboxList";
import ButtonStyleAction    from "../../components/shared/pages_buttons/ButtonStyleAction";
import ExportButton         from "../../components/shared/pages_buttons/ExportButton";
import CheckBox             from "../../components/input/CheckBox";

const Accounting = () => {

    const order = {
        direction: DESC,
        name: DATE
    };

    const {t} = useTranslation();

    const list = useSelector(state => state.global.list);
    const groupSearchesCheckboxes = useSelector(state => state.global.groupSearchesCheckboxes.accounting);
    const withGroupFilter = useSelector(state => state.global.groupSearchesCheckboxes.withGroupFilter);

    const [listAfterFilter, setListAfterFilter] = useState([]);
    const [checkedAll, setCheckedAll] = useState(false);

    const dispatch = useDispatch();

    // //Clear before destroy component
    useEffect(() => () => {
        dispatch(setModalForm({show: false}))
        dispatch(setAlertShow(false))
        dispatch(setSearch(''))
        dispatch(setList([]))
        dispatch(setSelectedList([]))
    }, [])

    const groupSearchesCheckboxesCodes = useMemo(() => Object.keys(groupSearchesCheckboxes).filter( code => groupSearchesCheckboxes[code]), [groupSearchesCheckboxes])

    function handleDatePicker(dateObject) {
        dispatch(setLoadSpinner(true))

        const date = {
            start: dateObject.firstDate.format('YYYY.MM.DD'),
            end: dateObject.secondDate.format('YYYY.MM.DD'),
        }

        getAccounting(date)
            .then((response) => {
                const data = response.data.map(a => {
                    function getTranslateOperationName(operation: string): string {
                        switch (operation) {
                            case 'Refund': return t('refund');
                            case 'Write-off': return t('write_off');
                            case 'Claims': return t('other_expenses');
                            case 'Compensation': return t('compensation');
                            default: return operation;
                        }
                    }

                    return {...a, operation: getTranslateOperationName(a.operation)}
                })
                dispatch(setList(data, t('noStatus')))
            })
            .catch(({response}) => viewAlert(dispatch, response))
            .finally(() => dispatch(setLoadSpinner(false)))
    }

    function handleSearch() {
        dispatch(setSelectedList([]))
    }

    function openGroupSearch() {
      dispatch(setModalForm({
          title: t('GroupSearchMenu'),
          form: <CheckboxList list={groupSearchesCheckboxes} setList={saveAndApplyGroupSearch}/>
      }))
    }

    function saveAndApplyGroupSearch(list) {
      dispatch(setGroupSearchesCheckboxes({
          page: 'accounting',
          val: list
      }))
    }

    function handleExport() {
        return exportAccounting(list)
    }

    function handleAllCheck(value) {
        const newList = [...list];
        (listAfterFilter && listAfterFilter.length ? listAfterFilter : list).forEach(account => {
            const numEl = list.findIndex( el => el.id === account.id)
            if (numEl >= 0) newList[numEl].check = value;
        });
        dispatch(setList(newList));
        setCheckedAll(value)
    }

    function handleCheck(value, item, ref) {
        dispatch(setList(list.map(account => {
            if (item.id === account.id) {
                account.check = !account.check;

            }
            return account;
        })));
    }

    function setDataAfterFilter(data) {
        setListAfterFilter(data)
    }

    const listHead = [
        {
            name: SELECTED, col: 1, text: '+', sortArrow: false, getChildren: (v, k) => {
                return <CheckBox
                    id={'all-check'}
                    className={'check-input'}
                    styles={{fontSize: '20px'}}
                    handle={handleAllCheck}
                    value={checkedAll}
                />
            }
        },
        {name: DATE, col: 3, text: t('date')},
        {name: OPERATION, col: 5, text: t('operation')},
        {name: PARCEL_NUMBER, col: 5, text: t('parcel_number')},
        {name: SUM, col: 2, text: t('sum')},
        {name: SUM_MINUS, col: 2, text: '(-) ' + t('sum')},
        {name: COMMENT, col: 6, text: t('comment')},
    ]

    const listItem = [
        {
            name: SELECTED, col: 1, isTrackNumber: false,
            getChildren: (account) => {
                return <CheckBox
                    className={'check-input'}
                    styles={{fontSize: '20px'}}
                    handle={(value, id, ref) => handleCheck(value, account, ref)}
                    value={!!account.check}
                />
            }
        },
        {name: DATE, col: 3, isTrackNumber: false},
        {name: OPERATION, col: 5, isTrackNumber: false},
        {name: PARCEL_NUMBER, col: 5, isTrackNumber: true},
        {name: SUM, col: 2, isTrackNumber: false},
        {name: SUM_MINUS, col: 2, isTrackNumber: false},
        {name: COMMENT, col: 6, isTrackNumber: false},
    ]

    const listItemMobile = {
        firstColumn: [
            {name: PARCEL_NUMBER, nameColumn: t('parcel_number'), col: 24, isTrackNumber: true},
            {name: OPERATION, nameColumn: t('operation'), col: 24, isTrackNumber: false},
            {name: COMMENT, nameColumn: t('comment'), col: 24, isTrackNumber: false},
        ],
        secondColumn: [
            {
                name: SELECTED, col: 24, isTrackNumber: false, classTableItem: 'w-100',
                getChildren: (account) => {
                    return <div className={'d-flex justify-content-between'}>
                    <div className={''}></div>
                    <CheckBox
                        className={'check-input'}
                        styles={{fontSize: '20px'}}
                        handle={(value, id, ref) => handleCheck(value, account, ref)}
                        value={!!account.check}
                    />
                </div>
                }
            },
            {name: DATE, nameColumn: t('date'), col: 24, isTrackNumber: false},
            {name: SUM, nameColumn: t('sum'), col: 12, isTrackNumber: false},
            {name: SUM_MINUS, nameColumn: '(-) ' + t('sum'), col: 12, isTrackNumber: false},
        ]
    }

    return (
        <div className={'container-page h-100'}>

            <ActionBlock
                handleSearch={handleSearch}
                handleDatePicker={handleDatePicker}
                openGroupSearch={openGroupSearch}
                handleExport={handleExport}
            />

            <PageListTemplate
                uniqueRowId={'id'}
                srcOrder={order}
                listHead={listHead}
                listItem={listItem}
                listItemMobile={listItemMobile}
                emptyListText={t('noParcelsWaitingArrival')}
                disableSelect
                listForFilter={withGroupFilter ? groupSearchesCheckboxesCodes : null}
                orderedHook={ data => setDataAfterFilter(data)}
            />

            <PageMenu stylesMenuList={{width: '13rem'}}>
                <ButtonStyleAction handle={openGroupSearch} text={'GroupSearch'} iconClasses={'fa-search mr-1'}/>
                <ExportButton
                    handle={handleExport}
                    text={t('Detailed_form')}
                />
            </PageMenu>
        </div>
    );
};

export default Accounting;
