import React, {useEffect, useState, useMemo} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {Form} from "react-bootstrap";
import {useTranslation} from "react-i18next";
import DatePicker from "react-datepicker";
import moment from "moment";

import InputName from "../input/InputName";
import InputPhone from "../input/InputPhone";
import InputIdCardNumber from "../input/InputIDCardNumber";
import ModalButtons from "../shared/modal/ModalButtons";
import {checkValidate, hideModalForm, serializeFormToObject, validate, viewAlert, debounce} from "../../utils/misc";
import {getProfileSettings, getUserInfo, updateUserInfo, checkPhoneNumExistKK} from "../../utils/api";
import {
    emailValidate,
    emailSpecialCharactersValidate,
    letterNumberSpaceHyphenValidate,
    letterSpaceHyphenValidate2,
    noEmptyValidate,
    numberValidate,
    letterNumberValidate,
    withLastLetter,
    validateLength
} from "../../utils/validate";
import {setLoadSpinner, setProfile} from "../../storage/global";
import {
    COMPANY_NAME,
    CONTACT_NAME,
    DEFAULT_ERROR_OBJECT,
    EMAIL,
    IC_CRD_NUM,
    PHONE,
    PHONE_VERIFY,
    WECHAT_ID,
    BIRTHDAY,
} from "../../constants";
import InputText from "../input/InputText";
import InputEmail from "../input/InputEmail";
import PhoneVerify from "./PhoneVerify";

const ProfileForm = () => {

    const profileInfo = useSelector(state => state.global.profile)
    const dispatch = useDispatch();
    const {t} = useTranslation();

    const formatDate = 'yyyy.MM.dd';

    const [companyName: string, setCompanyName] = useState(profileInfo?.company_name ?? '')
    const [name: string, setName] = useState(profileInfo?.contact_name ?? '')
    const [phone: string, setPhone] = useState(profileInfo?.phone ?? '')
    const [idCardNum: string, setIdCardNum] = useState(profileInfo?.ic_crd_num ?? '')
    const [weChat: string, setWeChat] = useState(profileInfo?.wechat ?? '')
    const [email: string, setEmail] = useState(profileInfo?.username ?? '')
    const [birthday, setBirthday] = useState(profileInfo?.birthday ? new Date(profileInfo?.birthday) : '');
    const [fieldsChecked, setFieldsChecked] = useState({});
    const [checkProfile, setCheckProfile] = useState(false);
    const [mandatoryFilling, setMandatoryFilling] = useState(false);

    const [validations, setValidations] = useState({})
    const [phoneIsVerify, setPhoneIsVerify] = useState(false);

    const [errorCompanyName, setErrorCompanyName] = useState({...DEFAULT_ERROR_OBJECT});
    const [errorName, setErrorName] = useState({...DEFAULT_ERROR_OBJECT});
    const [errorPhone, setErrorPhone] = useState({...DEFAULT_ERROR_OBJECT});
    const [errorIDCardNumber, setErrorIDCardNumber] = useState({...DEFAULT_ERROR_OBJECT});
    const [errorWeChat, setErrorWeChat] = useState({...DEFAULT_ERROR_OBJECT});
    const [errorEmail, setErrorEmail] = useState({...DEFAULT_ERROR_OBJECT});
    const [errorBirthday, setErrorBirthday] = useState({...DEFAULT_ERROR_OBJECT});

    const buttonsForm = {
        cancel: {
            text: t('cancel'),
            variant: 'outline-primary',
            type: 'button',
            action: hideModalForm(dispatch)
        },
        save: {
            text: t('save'),
            variant: 'primary',
            type: 'submit'
        }
    }

    if (mandatoryFilling) {
        delete buttonsForm.cancel
    }

    useEffect(() => {

        const validationsSet = getValidationsSet(t);

        setValidations(validationsSet);

        //profile settings
        getProfileSettings()
            .then(({data}) => {
                const { fieldsChecked, check_profile, mandatory_filling } = data;

                const {
                    companyName,
                    email,
                    idCardNumber,
                    phoneNumber,
                    wechatId,
                    yourName,
                } = fieldsChecked

                if (companyName) {
                    validationsSet[COMPANY_NAME].unshift(noEmptyValidate(t('validEmpty')));
                    checkValidate(profileInfo.company_name, validationsSet[COMPANY_NAME], setErrorCompanyName);
                }
                if (email) {
                    validationsSet[EMAIL].unshift(noEmptyValidate(t('validEmpty')));
                    checkValidate(profileInfo.username, validationsSet[EMAIL], setErrorEmail);
                }
                if (idCardNumber) {
                    // validationsSet[IC_CRD_NUM].unshift(noEmptyValidate(t('validEmpty')));
                    // checkValidate(profileInfo.ic_crd_num, validationsSet[IC_CRD_NUM], setErrorIDCardNumber);
                }
                if (phoneNumber) {
                    validationsSet[PHONE].unshift(noEmptyValidate(t('validEmpty')));
                    checkValidate(profileInfo.phone, validationsSet[PHONE], setErrorPhone);
                }
                if (wechatId) {
                    // validationsSet[WECHAT_ID].unshift(noEmptyValidate(t('IncorrectWeChatId')));
                    // checkValidate(profileInfo.wechat, validationsSet[WECHAT_ID], setErrorWeChat);
                }
                if (yourName) {
                    validationsSet[CONTACT_NAME].unshift(noEmptyValidate(t('validEmpty')));
                    checkValidate(profileInfo.contact_name, validationsSet[CONTACT_NAME], setErrorName);
                }

                setCheckProfile(!!check_profile)
                setMandatoryFilling(!!mandatory_filling);
                setFieldsChecked(fieldsChecked);
                setValidations({...validationsSet})
            })
            .catch()
    }, [])

    const birthdayMin = useMemo(() => {
        const today = new Date();
        const yyyy = today.getFullYear();
        const birthdayMin_ = new Date(yyyy - 15, today.getMonth(), today.getDate())
        return birthdayMin_;
    }, [])

    const checkPhoneExistKK = useMemo(() => {
        const [debouncedFunc] = debounce(checkPhoneNumExistKK, 700);
        return debouncedFunc;
    }, [])

    function handleSubmit(e) {
        e.preventDefault();
        e.stopPropagation();

        const form = e.currentTarget;
        const dataForm = serializeFormToObject(form);
        let birthday_ = '';
        if(birthday) {
            const d = new Date(birthday);
            const m = moment(d);
            birthday_ = m.format();
        }
        const requestData = {
            company_name: dataForm.company_name,
            username: dataForm.email,
            contact_name: dataForm.contact_name,
            phone: dataForm.phone,
            ic_crd_num: dataForm.ic_crd_num,
            wechat: dataForm.wechat_id,
            birthday: birthday_,
        }

        const errors = [
            checkValidate(requestData[COMPANY_NAME], validations[COMPANY_NAME], setErrorCompanyName),
            checkValidate(requestData[CONTACT_NAME], validations[CONTACT_NAME], setErrorName),
            checkValidate(requestData[PHONE], validations[PHONE], setErrorPhone),
            checkValidate(requestData[IC_CRD_NUM], validations[IC_CRD_NUM], setErrorIDCardNumber),
            checkValidate(requestData.wechat, validations[WECHAT_ID], setErrorWeChat),
            checkValidate(requestData.username, validations[EMAIL], setErrorEmail),
            checkValidate(requestData[BIRTHDAY], validations[BIRTHDAY], setErrorBirthday),
        ]

        const isError = errors.find(value => value.isError)

        if (!isError && (phoneIsVerify || phone === profileInfo?.phone)) {
            dispatch(setLoadSpinner(true))
            updateUserInfo(requestData)
                .then((response) => {
                    hideModalForm(dispatch)()
                    viewAlert(dispatch, response, {
                        title: t('updateProfile'),
                        text: t('success'),
                        type: 'success'
                    })
                    return getUserInfo()
                        .then(response => {
                            dispatch(setProfile(response.data))
                        })
                })
                .catch(({response}) => {
                    viewAlert(dispatch, response, {
                        title: t('updateProfile'),
                        type: 'danger'
                    })
                })
                .finally(() => dispatch(setLoadSpinner(false)))
        }
    }

    const withWarning = useMemo(() => {
        let warning = false;
        if(errorCompanyName.isError && fieldsChecked.companyName) warning = true;
        if(errorName.isError && fieldsChecked.yourName) warning = true;
        if(errorPhone.isError && fieldsChecked.phoneNumber) warning = true;
        if(errorIDCardNumber.isError && fieldsChecked.idCardNumber) warning = true;
        if(errorWeChat.isError && fieldsChecked.wechatId) warning = true;
        if(errorEmail.isError && fieldsChecked.email ) warning = true;
        return checkProfile && warning;
    }, [checkProfile, fieldsChecked, errorCompanyName, errorName, errorPhone, errorIDCardNumber, errorWeChat, errorEmail])

    function handleValues(value, name) {
        const v = {...profileInfo}
        v[name] = value;
        switch (name) {
            case COMPANY_NAME:
                setCompanyName(value)
                checkValidate(value, validations[COMPANY_NAME], setErrorCompanyName)
                break;
            case CONTACT_NAME:
                setName(value)
                checkValidate(value, validations[CONTACT_NAME], setErrorName)
                break;
            case PHONE: {
                setPhone(value)
                const validError = checkValidate(value, validations[PHONE], setErrorPhone)
                setPhoneIsVerify(!value)
                if(value && !validError.isError && profileInfo.useruid) {
                    setErrorPhone({
                        isError: true,
                        message: t('phone_checking')
                    })
                    checkPhoneExistKK({phone: value, user_id: profileInfo.useruid}).then((response) => {
                        if(response.exist) {
                            setErrorPhone({
                                isError: true,
                                message: t('phone_already_registered')
                            })
                        } else if(response.status) {
                            setErrorPhone({
                                isError: true,
                                message: 'Error, status: ' + response.status
                            })
                        } else if(!response.exist) {
                            setErrorPhone({
                                isError: false,
                                message: ''
                            })
                        }
                    }).catch((err) => {
                        console.log("🚀  checkPhoneExistKK  err:", err);
                        setErrorPhone({
                            isError: true,
                            message: err.status ? 'Error, status: ' + err.status : err.toString()
                        })
                    })
                }
                break;
            }
            case IC_CRD_NUM:
                setIdCardNum(value)
                checkValidate(value, validations[IC_CRD_NUM], setErrorIDCardNumber)
                break;
            case WECHAT_ID:
                setWeChat(value)
                checkValidate(value, validations[WECHAT_ID], setErrorWeChat)
                break;
            case EMAIL:
                setEmail(value)
                checkValidate(value, validations[EMAIL], setErrorEmail)
                break;
            case BIRTHDAY:
                setBirthday(value)
                checkValidate(value, validations[BIRTHDAY], setErrorBirthday)
                break;
            default: break;
        }
    }

    function codeCheckingResult(isVerify, connectionId, verifyCode) {
        setPhoneIsVerify(isVerify);
    }

    return (<>
        {withWarning && <div className={'warn-title'}>{t('Please_complete_the_verification')}</div>}
        <Form noValidate onSubmit={handleSubmit} className={''}>
            <table width={'100%'}>
                <tbody>
                <tr>
                    <td className={'d-flex align-items-start'}>{t('your_company')}:</td>
                    <td className={''} width={''}>
                        <InputText
                            id={COMPANY_NAME}
                            required={!!fieldsChecked?.companyName}
                            classes={''}
                            errorMessage={errorCompanyName.message}
                            value={companyName}
                            handleChange={handleValues}
                            validations={validations[COMPANY_NAME]}
                            closeButton
                            placeholder={t('your_company')}
                        />
                    </td>
                </tr>
                <tr>
                    <td className={'d-flex align-items-start mt-4'}>{t('your_name')}:</td>
                    <td className={''} width={''}>
                        <InputName
                            id={CONTACT_NAME}
                            required={!!fieldsChecked?.yourName}
                            classes={'mt-4'}
                            errorMessage={errorName.message}
                            value={name}
                            handleChange={handleValues}
                            validations={validations[CONTACT_NAME]}
                            closeButton
                        />
                    </td>
                </tr>
                <tr>
                    <td className={'d-flex align-items-start mt-4'}>{t('phone_number')}:</td>
                    <td className={''} width={''}>
                        <InputPhone
                            id={PHONE}
                            required={!!fieldsChecked?.phoneNumber}
                            customMask={''}
                            classes={'mt-4'}
                            errorMessage={errorPhone.message}
                            value={phone}
                            handleChange={handleValues}
                            validations={validations[PHONE]}
                            closeButton
                        />
                    </td>
                </tr>
                {!errorPhone.isError && phone && phone !== profileInfo?.phone ?
                    <tr>
                        <td className={''} width={''} colspan="2">
                            <PhoneVerify
                                key={'1'}
                                id={PHONE_VERIFY}
                                classes={'mt-4'}
                                placeholder={t('verification_code')}
                                errorMessage={errorPhone.message}
                                handleChange={codeCheckingResult}
                                handleBlur={null}
                                validations={[]}
                                closeButton={true}
                                value={phone}
                                customMask={null}
                                required={true}
                                isRestorePassword={false}
                                contactIsPhone={true}
                            />
                        </td>
                    </tr>
                    : null
                }
                <tr>
                    <td className={'d-flex align-items-start mt-4'}>{t('ic_crd_num')}:</td>
                    <td className={''} width={''}>
                        <InputIdCardNumber
                            classes={'mt-4'}
                            id={IC_CRD_NUM}
                            required={!!fieldsChecked?.idCardNumber}
                            handleChange={handleValues}
                            value={idCardNum}
                            errorMessage={errorIDCardNumber.message}
                            validations={validations[IC_CRD_NUM]}
                            closeButton
                            errorStyles={{
                            }}
                        />
                    </td>
                </tr>
                <tr>
                    <td className={'d-flex align-items-start mt-4'}>{t('wechatID')}:</td>
                    <td className={''} width={''}>
                        <InputText
                            classes={'mt-4'}
                            id={WECHAT_ID}
                            required={!!fieldsChecked?.wechatId}
                            handleChange={handleValues}
                            value={weChat}
                            errorMessage={errorWeChat.message}
                            validations={validations[WECHAT_ID]}
                            closeButton
                            errorStyles={{
                            }}
                            placeholder={t('wechatID')}
                        />
                    </td>
                </tr>
                <tr>
                    <td className={'d-flex align-items-start mt-4'}>{t('email')}:</td>
                    <td className={''} width={''}>
                        <InputEmail
                            classes={'mt-4'}
                            id={EMAIL}
                            required={!!fieldsChecked?.email}
                            handleChange={handleValues}
                            value={email}
                            errorMessage={errorEmail.message}
                            validations={validations[EMAIL]}
                            closeButton
                            errorStyles={{
                            }}
                            placeholder={t(EMAIL)}
                        />
                    </td>
                </tr>
                <tr>
                    <td className={'d-flex align-items-start mt-4'}>{t('Date_of_birth')}:</td>
                    <td className={''} width={''}>
                        <DatePicker
                            id={BIRTHDAY}
                            className="mt-4"
                            disabled={!!profileInfo?.birthday}
                            maxDate={birthdayMin}
                            selected={birthday}
                            onChange={(date: Date) => handleValues(date, BIRTHDAY)}
                            dateFormat={formatDate}
                            placeholderText={t('Date_of_birth')}
                            showYearDropdown
                            yearDropdownItemNumber={20}
                        />
                    </td>
                </tr>
                </tbody>
            </table>
            <ModalButtons className={'mt-4'} buttons={buttonsForm}/>
        </Form>
        </>
    );
};

export default ProfileForm;

export function getValidationsSet(t) {
    const validationsSet = {};
    validationsSet[COMPANY_NAME] = [
        validateLength(2, 0, t('validLength_2').replace(/(\$\{1\$\})/, 2)),
        letterNumberSpaceHyphenValidate(t('IncorrectYourName'))
    ]
    validationsSet[CONTACT_NAME] = [
        validateLength(2, 0, t('validLength_2').replace(/(\$\{1\$\})/, 2)),
        letterSpaceHyphenValidate2(t('IncorrectYourName'))
    ]
    validationsSet[PHONE] = [
        numberValidate(t('validNumber')), validateLength(5, 0, t('IncorrectPhoneNumber'))
    ]
    validationsSet[WECHAT_ID] = []
    validationsSet[EMAIL] = [
        noEmptyValidate(t('validEmpty')),
        emailValidate(t('validEmail')),
        emailSpecialCharactersValidate(t('validEmail')),
    ]
    validationsSet[IC_CRD_NUM] = []
    validationsSet[BIRTHDAY] = []
    return validationsSet;
}
