import React, { useState } from 'react';
import ReactTooltip        from "react-tooltip";

import ButtonSmallNoBorder          from "../../components/shared/ButtonSmallNoBorder";

const InfoWithIcon = ({className, text, info}) => {

    const [ref, setRef] = useState({});

    function handleHover(event, ref, isHover = true) {
        if (isHover)
            ReactTooltip.show(ref)
        else
            ReactTooltip.hide(ref)
    }
    return (
        <div ref={ref => setRef(ref)}
            data-tip={info}
            data-html="true"
            data-class='my-tooltip unidentified-parcel'
            onClick={null}
            className={`btn-disable ${className ? className : ''}`}
            onMouseOver={event =>  handleHover(event, ref)}
            onMouseOut={event => handleHover(event, ref, false)}
        >
            <ButtonSmallNoBorder classNames={'disablehover'}>
                <span className={`p-2 cursor-pointer`}>
                    <span className={''}>
                        <img width='20px' height='20px' className={''} src={'/assets/img/question_blue.svg'}/>
                    </span>
                </span>
                <span>{text}</span>
            </ButtonSmallNoBorder>
        </div>
    );
};

export default InfoWithIcon;
