import React, { useEffect, useState, useMemo }          from 'react';
import { useDispatch }                                  from "react-redux";
import { Button }                                       from "react-bootstrap";
import { useTranslation }                               from "react-i18next";
import moment                                           from "moment/moment";

import {
    REQ_URI,
    AVERAGE_REQUEST_TIME,
    WARNING_NOTIF_MAX_VALUE,
}                                                       from "../../../constants";
import axios                                            from "../../../lib/axios";
import { setLoad, setLoadSpinner }                      from "../../../storage/global";
import {
    getRequestsUris,
    getQueryStatisticsListAvg,
    saveWarningNotificationLimits,
    getWarningNotificationLimits,
    makePostRequest
}                                                       from "../../../utils/api";
import { viewAlert }                                    from "../../../utils/misc";
import PagePollListTemplate                             from "../Polls/PagePollListTemplate";
import Select                                           from "../../input/Select";
import MyDatePicker                                     from "../datepicker/MyDatePicker";
import ButtonAdmin                                      from "../pages_buttons/ButtonAdmin";
import RequestStatistic                                 from "./RequestStatistic";



const StatisticList = () => {

    const dispatch = useDispatch();
    const {t} = useTranslation();

    const defStorage = {
        items: [],
    };

    const nothingSelected = t('nothingSelected');
    const [urls, setUrls] = useState([nothingSelected]);
    const [urlForGetResult, setUrlForGetResult] = useState('');
    const [dateForGetResult, setDateForGetResult] = useState({ date_from: Date.now(), date_to: Date.now()});

    const [storage, setStorage] = useState(defStorage);

    const {items} = storage;

    useEffect(() => {
        dispatch(setLoad(false))
        getQueryStatisticsAvg()
        getRequestsUriList()
    }, [])

    function getQueryStatisticsAvg(params) {
        dispatch(setLoadSpinner(true))
        getQueryStatisticsListAvg(params).then(responseListAvg => {
            let itemsWithLimits = items;
            if(responseListAvg?.data?.queryStatistics?.length || (responseListAvg?.data?.queryStatistics && params)) {
                itemsWithLimits = responseListAvg?.data?.queryStatistics;
            }
            setStorage({
                items: itemsWithLimits.sort(function(a, b) {return b.requestAvgTime - a.requestAvgTime}),
            });
        })
        .catch(({response}) => {viewAlert(dispatch, response)})
        .finally(() => dispatch(setLoadSpinner(false)))
    }

    function getRequestsUriList() {
        dispatch(setLoadSpinner(true))
        getRequestsUris().then(response => {
                setUrls([nothingSelected, ...Object.values(response.data.uris).map(row=>row.req_uri)]);
            })
            .catch(({response}) => {viewAlert(dispatch, response)})
            .finally(() => dispatch(setLoadSpinner(false)))
    }

    function handleDatePicker(dateObject) {
        const date = {
            date_from: dateObject.firstDate.format('YYYY.MM.DD'),
            date_to: dateObject.secondDate.format('YYYY.MM.DD')
        }
        setDateForGetResult(date)
    }

    function loadData() {
        getQueryStatisticsAvg(dateForGetResult.date_from === dateForGetResult.date_to ? {query_uri: +urlForGetResult ? urls[urlForGetResult] : null} : {
            from_time: toTimestamp(dateForGetResult.date_from),
            to_time: toTimestamp(dateForGetResult.date_to),
            query_uri: +urlForGetResult ? urls[urlForGetResult] : null
        })
    }
    function toTimestamp(strDate){
        var datum = Date.parse(strDate);
        return datum/1000;
    }

    function loadExcel() {
        const params = dateForGetResult.date_from === dateForGetResult.date_to ?
        {
            client_id: false,
            query_uri: +urlForGetResult ? urls[urlForGetResult] : null
        }
        : {
            from_time: toTimestamp(dateForGetResult.date_from),
            to_time: toTimestamp(dateForGetResult.date_to),
            client_id: false,
            query_uri: +urlForGetResult ? urls[urlForGetResult] : null
        };
        makePostRequest('/api/admin/loadListAverageQueryValuesExcel', params, {responseType: 'blob'})
        .then(({data}) => {
            const date = moment();
            const filename = `speed_request_statistic_${date.format('YYYY-MM-DD_HH_mm')}.xlsx`;
            const blob = new Blob([data], {type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'});
            const url = window.URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', filename ? filename : 'file.xlsx'); //or any other extension
            document.body.appendChild(link);
            link.click();
        })
        .catch(({response}) => {
            viewAlert(dispatch, response)
        })
        .finally(() => {
            dispatch(setLoadSpinner(false))
        })
    }

    const listHead = [
        {name: REQ_URI, col: 15, text: t('list_head_req_uri'), sortArrow: false, classNames: 'wfc minw-100'},
        {name: AVERAGE_REQUEST_TIME, col: 5, text: t('average_request_time'), sortArrow: false, classNames: 'wfc minw-100'},
    ]
    const listItem = [
        {name: REQ_URI, col: 17, classNames: 'wfc minw-100 py-1'},
        {name: AVERAGE_REQUEST_TIME, col: 5, classNames: 'wfc minw-100 py-1'},
    ]

    return (
        <>
            <div className={'v-card v-sheet theme--light p-3 d-flex flex-column '}>
                <div className="row w-100 m-0 pt-10">
                    <MyDatePicker
                        handle={handleDatePicker}
                        startEnd
                        firstDate={new Date(dateForGetResult.date_from ?? Date.now()-1000*60*60)}
                        secondDate={new Date(dateForGetResult.date_to ?? Date.now())}
                        className={'d-flex col-12 px-0'}
                        stylesFirstDate={{marginRight: '15px'}}
                        classNameFirstDate={'mr-3'}
                        textFirst={"З дати"}
                        textSecond={"До дати"}
                        colFirst={['auto', 'auto']}
                        colSecond={['auto', 'auto']}
                        TitleCClassNames={"pl-15 "}
                        TitleClassNames={"lh-0"}
                        DPCClassNames={"mtb-9 minw-100"}
                    />
                    <Select
                        id={'Select_Requests_Uris'}
                        classNameContainer={"col-4 align-self-center pl-0"}
                        options={urls.map( (req_uri, i) => ({value: i.toString(), name: req_uri}))}
                        selectValue={urlForGetResult && urls[urlForGetResult] ? {value: urlForGetResult, name: urls[urlForGetResult]} : {}}
                        nameValue={'name'}
                        keyName={'value'}
                        handleChange={ (el, id) => {
                            setUrlForGetResult(el.value)
                        }}
                        errorMessage={''}
                        validations={{}}
                        loaded={false}
                    />
                    <div className="col-4 align-self-center">
                        <Button onClick={loadData} className={'hmc mr-1 w-100'}>Показати</Button>
                    </div>
                    <div className="col-4 align-self-center">
                        <Button onClick={loadExcel} className={'hmc w-100'}>Скачати</Button>
                    </div>
                </div>
            </div>
            <div className='height-list-filter'>
                <div className='list-of-poll mb-2 d-flex flex-column h-100'>
                    <RequestStatistic
                        uniqueRowId={'uid'}
                        emptyListText={'Пусто'}
                        disableSelect
                        items={items}
                        listHead={listHead}
                        listItem={listItem}
                    />
                </div>
            </div>
        </>
    );
};


export default StatisticList;
